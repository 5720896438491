import React from 'react';
import styled from 'styled-components';

import {
  Typography, Button,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const StateResults = ({
  searchResults,
  searchState,
}) => {
  const nbHits = searchResults?.nbHits || 0;
  const { query } = searchState;
  const { displayName } = useSelector((store) => store.company.data);

  if (nbHits > 0) return <div />;

  return (
    <NoResultsContainer>
      <Typography variant="h5" gutterBottom>
        {`We couldn't find anything for "${query}".`}
      </Typography>
      <Typography variant="body1">
        {`If this App exists at ${displayName}, you can add it to Brodi. It's easy, and only takes a minute.`}
      </Typography>
      <ButtonContainer>
        <Button component={Link} to="/add" variant="outlined" color="secondary">
          Add a New App
        </Button>
      </ButtonContainer>
    </NoResultsContainer>
  );
};

export default StateResults;

const NoResultsContainer = styled.div`
  
`;

const ButtonContainer = styled.div`
  margin: 1rem 0;
`;
