import React from 'react';
import styled from 'styled-components';
import {
  Button,
  Dialog,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import Wave from '../../images/wave.svg';
import { updateOnboarding } from '../../actions/users';
import { getFirstName } from '../../helpers/strings';

const InitialSigninFromFreeTrial = ({ open }) => {
  const dispatch = useDispatch();
  const { name } = useSelector((store) => store.users.currentUser.data);
  const { currentPlan } = useSelector((store) => store.company.data);
  const { endDate = {} } = currentPlan;

  const end = DateTime.fromSeconds(endDate._seconds || 0);

  const completeOnboarding = async () => {
    try {
      await dispatch(updateOnboarding({ fromFreeTrial: false }));
    } catch (err) {
      // error
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <InsetContainer>
        <ContentRow>
          <Left>
            <div>
              <img alt="waving hand" style={{ opacity: 0.25 }} src={Wave} />
            </div>
          </Left>
          <Right>
            <Body>
              <Headers>
                <Typography variant="h4">
                  Welcome to Brodi,
                  {' '}
                  {getFirstName(name)}
                  !
                </Typography>
                <Typography variant="h6">
                  You made the first step toward an organized stack.
                </Typography>
              </Headers>
              <div>
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  Some things you should know:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <ul style={{ lineHeight: 2 }}>
                    <li>
                      Your free trial expires on {end.toLocaleString(DateTime.DATE_HUGE)}
                    </li>
                    <li>
                      We'll check in with you in a few days, but you can contact us for help at any time
                    </li>
                    <li>
                      We've added some example apps for you to try out
                    </li>
                    <li>
                      You can add as many of your own apps and users as you like
                    </li>
                  </ul>
                </Typography>
              </div>
            </Body>
            <ActionRow>
              <Typography variant="body1" component="div">
                Ready to go?
              </Typography>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                size="large"
                onClick={completeOnboarding}
              >
                Get Started
              </Button>
            </ActionRow>
          </Right>
        </ContentRow>
      </InsetContainer>
    </Dialog>
  );
};
export default InitialSigninFromFreeTrial;

const InsetContainer = styled.div`

`;

const ContentRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > div {
    :not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

const Left = styled.div`
  flex-basis: 35%;
  background-color: #3A4ADE;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8rem 0;
`;

const Right = styled.div`
  flex-grow: 1;
  background-color: #FAFAFA;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Body = styled.div``;

const Headers = styled.div`
  margin-bottom: 1rem;
`;
