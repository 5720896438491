import React, { useState } from 'react';
import {
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  Grid,
  LinearProgress,
  Typography,
  Box,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { requestEmailChange } from '../../actions/users';
import { showSnackbar } from '../../actions/snackbar';
import { validateEmail } from '../../helpers/strings';

const EmailChangeDialog = ({ emailDialogOpen, toggleEmailChangeDialog }) => {
  const [manualError, setManualError] = useState(false);
  const [saving, setSaving] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    reset,
  } = useForm();
  const dispatch = useDispatch();

  const handleChangeRequest = (data) => {
    setSaving(true);
    setManualError(false);
    const newEmail = data.email.toLowerCase();
    dispatch(requestEmailChange(newEmail, data.password))
      .then(() => {
        setSaving(false);
        dispatch(showSnackbar(`A verification email has been sent to ${newEmail}`));
        toggleEmailChangeDialog();
      })
      .catch(() => {
        setSaving(false);
        setManualError(true);
      });
  };

  const handleClose = () => {
    toggleEmailChangeDialog();
    reset();
    setManualError(false);
  };

  return (
    <Dialog
      open={emailDialogOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(handleChangeRequest)}>
        {
          (saving) && (
            <LinearProgress />
          )
        }
        <DialogTitle id="form-dialog-title">Change Email Address</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TextField
                label="Current Password"
                name="password"
                type="password"
                inputRef={register({ required: true })}
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item>
              <TextField
                label="New Email Address"
                name="email"
                type="email"
                error={Boolean(errors.email)}
                helperText={errors.email ? "That's not a valid email address" : ''}
                inputRef={register({ required: true, validate: (value) => validateEmail(value) })}
                fullWidth
              />
            </Grid>
          </Grid>
          {manualError && (
            <Box my={2}>
              <Typography color="error" variant="body2">
                Sorry, there was a problem.
                {' '}
                Check your password and that the new email address is a valid email.
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={saving} type="submit" color="primary">
            Update Email
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EmailChangeDialog;
