import User from '../models/users';
import {
  GET_SINGLE_USER_INFO_SUCCESS,
  GET_CURRENT_USER_INFO_SUCCESS,
} from '../actions/users';

const defaultUser = new User();

const initialState = {
  cachedUsers: {
    data: {
      unknownUser: {
        ...defaultUser,
        name: 'Unknown User',
      },
    },
  },
  currentUser: {
    data: {
      ...defaultUser,
    },
  },
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_USER_INFO_SUCCESS:
      return {
        ...state,
        cachedUsers: {
          data: {
            ...state.cachedUsers.data,
            ...action.payload,
          },
        },
      };

    case GET_CURRENT_USER_INFO_SUCCESS:
      return {
        ...state,
        currentUser: {
          data: {
            ...action.payload,
          },
        },
      };

    default:
      return state;
  }
};

export default users;
