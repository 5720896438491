import React, { useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  TextField,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import ContainedLayout from '../../templates/ContainedLayout';
import { inviteUserAdmin } from '../../../actions/admin';
import ProgressButton from '../../atoms/ProgressButton';
import { showErrorSnackbar, showSnackbar } from '../../../actions/snackbar';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
  },
}));

const UserInvite = () => {
  const { userId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const displayName = useSelector((store) => store.company.data.displayName);
  const selectedUser = useSelector((store) => store.users.cachedUsers.data[userId]);

  const [saving, setSaving] = useState(false);

  const {
    handleSubmit,
    register,
    formState,
    errors,
    control,
  } = useForm({ defaultValues: selectedUser });

  // TODO: this is currently allowing any email domain to be invited.
  // Are we catching that on login when we check the email domain?
  const updateUser = (fields) => {
    setSaving(true);
    inviteUserAdmin(fields.email, fields.name, fields.role)
      .then(() => {
        setSaving(false);
        dispatch(showSnackbar(`An invite was successfully sent to ${fields.email}`));
        setTimeout(() => { history.push('/manage/users/search'); }, 1500);
      })
      .catch(() => {
        setSaving(false);
        dispatch(showErrorSnackbar('There was a problem inviting user.'));
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit(updateUser)}>

        {/* Header Name and Avatar */}
        <ContainedLayout tinted>
          <Box py={8}>

            <Grid container alignItems="center" spacing={3}>
              <Grid item>
                <Typography variant="h3">Invite a New User</Typography>
                <Typography variant="h5" style={{ fontWeight: 400 }} gutterBottom>{displayName}</Typography>
              </Grid>
            </Grid>
          </Box>
        </ContainedLayout>

        <ContainedLayout>
          <Box py={5}>
            <Grid container item direction="column" spacing={4} xs={12} sm={6}>

              <Grid container item direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6">New User Info</Typography>
                </Grid>
                <Grid container item direction="column" spacing={3}>
                  <Grid item>
                    <TextField fullWidth inputRef={register({ required: true })} name="name" variant="outlined" label="Full Name" />
                  </Grid>
                  <Grid item>
                    <TextField fullWidth inputRef={register({ required: true })} name="email" variant="outlined" label="Email Address" />
                  </Grid>
                  <Grid item>
                    <FormControl variant="outlined" className={classes.formControl} fullWidth error={Boolean(errors.category)}>
                      <InputLabel id="site-role-label">Site Role</InputLabel>
                      <Controller
                        as={(
                          <Select
                            labelId="site-role-label"
                            id="site-role"
                            label="Category"
                          >
                            {/* TODO: talk to ronan about this */}
                            <MenuItem disabled value="">Select a Role</MenuItem>
                            <MenuItem value={10}>Administrator</MenuItem>
                            <MenuItem value={1}>Contributor</MenuItem>
                            {/*<MenuItem value={0}>Viewer</MenuItem>*/}
                          </Select>
                        )}
                        name="role"
                        rules={{ required: 'Please select a valid role for this user' }}
                        control={control}
                        defaultValue={0}
                      />
                      <FormHelperText>
                        {/* TODO: Change this to be a description of the roles and read from FB */}
                        {errors.role ? errors.role.message : ''}
                      </FormHelperText>
                    </FormControl>

                  </Grid>
                </Grid>
              </Grid>

              <Grid container item alignItems="center">
                <ProgressButton
                  cta="Invite"
                  disabled={!formState.dirty}
                  endIcon={<> </>}
                  loading={saving}
                />
              </Grid>
            </Grid>
          </Box>
        </ContainedLayout>
      </form>
    </>
  );
};

export default UserInvite;
