import Client from '../models/client';
import {
  SET_CLIENT_NOT_READY,
  SET_CLIENT_READY,
  GET_GLOBAL_CONSTS_SUCCESS,
} from '../actions/client';

const defaultClient = new Client();

const initialState = {
  data: {
    ...defaultClient,
  },
};

const client = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENT_NOT_READY:
      return {
        data: {
          ...state.data,
          isReady: false,
        },
      };
    case SET_CLIENT_READY:
      return {
        data: {
          ...state.data,
          isReady: true,
        },
      };

    case GET_GLOBAL_CONSTS_SUCCESS:
      return {
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default client;
