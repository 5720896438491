import { v4 as uuidv4 } from 'uuid';
import firebase from '../config/firebase';

import {
  FB_COMPANIES_COLLECTION,
  FB_APP_ICONS_STORAGE,
  PATCH,
  PUT, GET,APP_ACTIVE_STATUS, APP_ARCHIVED_STATUS,
} from '../config/constants';
import { brodiAuthedRequest } from '../helpers/backend';

export const GET_APP_SUCCESS = 'GET_APP_SUCCESS';
export const UPDATE_ADD_APP_SUCCESS = 'UPDATE_ADD_APP_SUCCESS';
export const SUBMIT_ADD_APP_SUCCESS = 'SUBMIT_ADD_APP_SUCCESS';

export const getApp = (appId, forceRefresh = false) => async (
  dispatch,
  getState,
) => {
  const app = getState().apps.cachedApps.data[appId];
  if (app && !forceRefresh) return;

  const data = await brodiAuthedRequest(GET, `/app/${appId}`);
  const payload = { [appId]: data };
  dispatch({ type: GET_APP_SUCCESS, payload });
};

export const setAppStatus = (appId, data) => async (dispatch) => {
  await brodiAuthedRequest(PATCH, `/app/${appId}/set-status`, {
    availability: {
      status: data.status,
      notify: data.notify,
      message: data?.message || '',
    },
  });
  await dispatch(getApp(appId, true));
};

export const getUnhealthyApps = async () => brodiAuthedRequest('GET', '/app/unhealthy/apps');

export const incrementAppOpenCount = async (appId) => {
  await brodiAuthedRequest(PATCH, `/app/${appId}/increment-open-count`);
};

export const searchTemplateApps = async (queryString = '') => {
  let sanitized = queryString;
  sanitized = queryString.trim();
  if (!sanitized || sanitized === '') return [];
  return brodiAuthedRequest(GET, `/app/templates/${sanitized}`);
};

export const addFollower = (appId) => async (dispatch) => {
  await brodiAuthedRequest(PATCH, `/app/${appId}/follower/add`);
  await dispatch(getApp(appId, true));
};

export const removeFollower = (appId) => async (dispatch) => {
  await brodiAuthedRequest(PATCH, `/app/${appId}/follower/remove`);
  await dispatch(getApp(appId, true));
};

export const getPinnedApps = async () => brodiAuthedRequest(GET, '/app/pinned/apps');

export const pinApp = async (appId) => brodiAuthedRequest(PATCH, `/app/${appId}/set-pinned`);
export const unpinApp = async (appId) => brodiAuthedRequest(PATCH, `/app/${appId}/set-unpinned`);

export const uploadAppIcon = (file) => async (dispatch, getState) => {
  const { companyId } = getState().users.currentUser.data;
  const id = uuidv4();
  const extension = file.type.split('/')[1];
  const storageRef = firebase
    .storage()
    .ref(
      `${FB_COMPANIES_COLLECTION}/${companyId}/${FB_APP_ICONS_STORAGE}/${id}.${extension}`,
    );
  await storageRef.put(file);
  const icon = await storageRef.getDownloadURL();
  return icon;
};

export const editApp = (payload, appId) => async (dispatch) => {
  await brodiAuthedRequest(PATCH, `/app/${appId}`, { payload });
  await dispatch(getApp(appId, true));
};

export const editContract = (payload, appId) => async (dispatch) => {
  await brodiAuthedRequest(PATCH, `/app/${appId}/contract`, payload);
  await dispatch(getApp(appId, true));
};

export const editExternalContacts = (payload, appId) => async (dispatch) => {
  await brodiAuthedRequest(PATCH, `/app/${appId}/external-contacts`, payload);
  await dispatch(getApp(appId, true));
};

export const updateAddApp = (payload, signature = null) => async (dispatch) => {
  dispatch({ type: UPDATE_ADD_APP_SUCCESS, payload, signature });
};

export const addAppFromState = () => async (dispatch, getState) => {
  const { data } = getState().apps.addApp;
  const newData = { ...data };
  delete newData.signatures;
  const res = await brodiAuthedRequest(PUT, '/app', { payload: newData });

  dispatch({ type: SUBMIT_ADD_APP_SUCCESS });

  return {
    name: res.name,
    icon: res.icon,
    id: res.id,
  };
};

export const toggleArchiveApp = (appId, isAlreadyArchived) => async (
  dispatch,
) => {
  let status;
  const app = await brodiAuthedRequest(GET, `/app/${appId}`);

  // These 'isAlreadyArchived' errors are here to prevent some other user archiving the app
  // before them and their archive message sends an unarchive signal to Firestore.
  if (app.presence.status === APP_ACTIVE_STATUS) {
    if (isAlreadyArchived) throw new Error('App not archived');
    status = APP_ARCHIVED_STATUS;
  } else {
    if (!isAlreadyArchived) throw new Error('App already archived');
    status = APP_ACTIVE_STATUS;
  }

  await brodiAuthedRequest(PATCH, `/app/${appId}/presence`, {
    status,
  });
  await dispatch(getApp(appId, true));
};

export const getSameCategoryApps = async (category, appId) => {
  const data = await brodiAuthedRequest(GET, `/app/${appId}/same-category/${category}`);
  return data;
};

