import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, openSlack } from '../../actions/users';
import SimpleAvatar from '../atoms/SimpleAvatar';
import { showErrorSnackbar } from '../../actions/snackbar';

const ContactListDialog = ({
  handleListDialog,
  openList,
  contactIdsArray = [],
  groupText,
}) => {
  const dispatch = useDispatch();
  const cachedUsers = useSelector((store) => store.users.cachedUsers.data);
  const { slackEnabled } = useSelector((state) => state.company.data);

  const handleEmailClick = (email) => {
    window.location = `mailto:${email}`;
  };

  const handleSlackClick = (id) => {
    openSlack(id)
      .then(({ url }) => {
        window.location.href = url;
      })
      .catch(() => {
        dispatch(showErrorSnackbar('There was a problem opening Slack.'));
      });
  };

  useEffect(() => {
    dispatch(getUsers(contactIdsArray));
  });

  if (!contactIdsArray.length) return <div />;

  return (
    <Dialog
      open={openList}
      onClose={handleListDialog}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">{ groupText ? `${groupText} (${contactIdsArray.length})` : `Contacts (${contactIdsArray.length})`}</DialogTitle>
      <DialogContent>
        {/* <SearchBox>
          <TextField
            name="search"
            placeholder={groupText ? `Search ${groupText}...` : 'Search these contacts...'}
            variant="outlined"
            fullWidth
          />
        </SearchBox> */}
        <ListContainer>
          {contactIdsArray.map((id) => (
            <ListItem key={id}>
              <AvatarCol>
                <SimpleAvatar userId={id} />
              </AvatarCol>
              <TextCol>
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  {cachedUsers[id]?.name}
                </Typography>
                <Typography variant="body2">
                  {cachedUsers[id]?.title}
                </Typography>
              </TextCol>
              <ActionCol>
                {slackEnabled && (
                <Button onClick={() => handleSlackClick(id)}>Slack</Button>
                )}
                <Button onClick={() => handleEmailClick(cachedUsers[id]?.email)}>Email</Button>
              </ActionCol>
            </ListItem>
          ))}
        </ListContainer>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={handleListDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactListDialog;

const ListContainer = styled.div`
  width: 100%;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  max-height: 400px;
  overflow-y: auto;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  &:hover {
    background-color: #FAFAFA;
    border-radius: 5px;
  };
`;

const AvatarCol = styled.div`
  margin-right: 1rem;
`;
const TextCol = styled.div`
  flex-grow: 1;
`;
const ActionCol = styled.div``;
