import React, { useState } from 'react';

import {
  Fade,
  Grid,
  TextField,
  makeStyles,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Typography, Button, FormControlLabel, Checkbox,
} from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { resolveCategoryId } from '../../../helpers/resolvers';
import { getNextFormStep } from '../../../helpers/form';
import { updateAddApp, uploadAppIcon } from '../../../actions/apps';

import { urlRegex } from '../../../helpers/url';
import ProgressButton from '../../atoms/ProgressButton';
import { ICON_SIZE_LIMIT, ICON_TYPE_REGEX } from '../../../config/constants';
import GetNameAutocomplete from '../../molecules/GetNameAutocomplete';
import AppLogo from '../../atoms/AppLogo';
import SingleFormWrapper from '../../AddApp/components/SingleFormWrapper';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const GetBasics = ({ formStep }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [imageUploading, setImageUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [iconPreview, setIconPreview] = useState('');

  // Custom Name Form
  const appCategories = useSelector((store) => store.client.data.categories);
  const appInProgress = useSelector((store) => store.apps.addApp.data);
  const { displayName } = useSelector((store) => store.company.data);

  const {
    register, handleSubmit, control, errors, setValue, watch,
  } = useForm({
    defaultValues: appInProgress,
  });

  const nameWatch = watch('name');

  const handleSuggestionsChange = (d) => {
    Object.keys(d).forEach((fieldName) => {
      if (fieldName === 'defaultUrl') setValue('url', d[fieldName]);
      if (fieldName === 'icon') {
        setIconPreview(d[fieldName]);
        setValue(fieldName, d[fieldName]);
        dispatch(updateAddApp({ icon: d[fieldName] }));
      } else setValue(fieldName, d[fieldName]);
    });
  };

  const removeIcon = () => {
    dispatch(updateAddApp({ icon: null }));
    setValue('icon', null);
    setIconPreview(null);
  };

  const uploadIcon = async (e) => {
    setErrorMessage('');
    const file = e.target.files[0];

    if (file.size > ICON_SIZE_LIMIT) {
      setErrorMessage('That image is too big. Choose an image smaller than 3MB.');
      return;
    }

    if (!ICON_TYPE_REGEX.test(file.type)) {
      setErrorMessage('Only GIF, JPG, JPEG, TIFF, and PNG files are allowed.');
      return;
    }
    setImageUploading(true);
    dispatch(uploadAppIcon(e.target.files[0]))
      .then((downloadUrl) => {
        dispatch(updateAddApp({ icon: downloadUrl }));
        setValue('icon', downloadUrl);
        setImageUploading(false);
      })
      .catch(() => {
        setImageUploading(false);
        setErrorMessage('There was a problem uploading. Try again.');
      });
  };

  const advanceForm = (data) => {
    // Sign the Form
    const signature = { [formStep]: new Date() };
    dispatch(updateAddApp(data, signature));
    history.push(`/add/${getNextFormStep(formStep)}`);
  };

  return (
    <Fade in>
      <form onSubmit={handleSubmit(advanceForm)} autoComplete="off">
        <SingleFormWrapper subheading="STEP ONE" heading="About this App">

          <Grid container item direction="column" xs={12} lg={6} spacing={5}>

            {/* Basic Fields */}
            <Grid container item spacing={3} direction="column">

              {/* App Name */}
              <Grid item>
                <Grid item>
                  <GetNameAutocomplete
                    categories={appCategories}
                    handleOnChange={handleSuggestionsChange}
                    errors={errors?.name}
                    control={control}
                  />
                </Grid>
              </Grid>

              {/* App URL */}
              <Grid item>
                <Controller
                  as={TextField}
                  control={control}
                  variant="outlined"
                  label="App URL (Optional)"
                  size="medium"
                  name="url"
                  defaultValue={appInProgress.url}
                  error={Boolean(errors.url)}
                  rules={{ required: false, pattern: urlRegex }}
                  helperText={errors.url ? 'Please provide a valid App URL' : 'e.g: www.myapp.com'}
                  fullWidth
                />
              </Grid>

              {/* App Category */}
              <Grid item>
                <FormControl variant="outlined" className={classes.formControl} fullWidth error={Boolean(errors.category)}>
                  <InputLabel id="category-outlined-label">Category</InputLabel>
                  <Controller
                    as={(
                      <Select
                        value={appInProgress.category}
                        labelId="category-outlined-label"
                        id="category-outlined"
                        label="Category"
                      >
                        <MenuItem disabled value="">Select a Category</MenuItem>
                        {Object.keys(appCategories).sort().map((item) => (
                          <MenuItem
                            key={item}
                            value={item}
                          >
                            {resolveCategoryId(item, appCategories)}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    name="category"
                    rules={{ required: 'Please select the category that best describes this App' }}
                    control={control}
                    defaultValue=""
                  />
                  <FormHelperText>
                    {errors.category ? errors.category.message : 'Select a Category'}
                  </FormHelperText>
                </FormControl>
              </Grid>

              {/* App Description */}
              <Grid item>
                <Controller
                  as={TextField}
                  control={control}
                  variant="outlined"
                  label="Description"
                  size="medium"
                  multiline
                  rows="3"
                  name="description"
                  error={Boolean(errors.description)}
                  placeholder={`Write a clear description about what ${nameWatch ? `"${nameWatch}"` : 'this app'} does for ${displayName || 'your organization'}`}
                  defaultValue={appInProgress.description}
                  rules={{ required: 'Please provide a valid description for this App' }}
                  helperText={errors.description && errors.description.message}
                  fullWidth
                />
              </Grid>

              <Grid container item direction="column" spacing={3}>
                <Grid container item alignItems="center" spacing={3}>
                  <Grid item>
                    <AppLogo name="App" icon={appInProgress.icon || iconPreview} width="100px" height="100px" />
                    <input type="hidden" name="icon" ref={register()} />
                  </Grid>
                  <Grid item>
                    <>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        onChange={uploadIcon}
                        disabled={imageUploading}
                        style={{ display: 'none' }}
                      />
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="contained-button-file">
                        <ProgressButton
                          color="default"
                          component="span"
                          cta={appInProgress.icon ? 'Change Icon' : 'Choose an Icon'}
                          endIcon={<> </>}
                          loading={imageUploading}
                          size="medium"
                          type=""
                          variant="outlined"
                        />
                      </label>
                      {appInProgress.icon && (
                        <div style={{ marginTop: 10 }}>
                          <ProgressButton
                            color="default"
                            component="div"
                            cta="Remove Icon"
                            endIcon={<> </>}
                            size="medium"
                            variant="outlined"
                            onClick={() => removeIcon()}
                          />
                        </div>
                      )}
                      <Typography variant="body2" color="error">{errorMessage}</Typography>
                    </>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={(
                      <Controller
                        as={<Checkbox />}
                        control={control}
                        name="firstParty"
                        defaultValue={false}
                      />
                      )}
                    label={`This App was created by ${displayName}`}
                  />
                  <FormHelperText>
                    Check this box if
                    {' '}
                    {nameWatch ? `"${nameWatch}"` : 'this'}
                    {' '}
                    is a proprietary App (like an internal app or service)
                  </FormHelperText>
                </FormControl>
              </Grid>

            </Grid>

            <Grid item>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
                size="large"
                onClick={handleSubmit(advanceForm)}
                endIcon={<ChevronRightIcon />}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </SingleFormWrapper>
      </form>
    </Fade>
  );
};

export default GetBasics;
