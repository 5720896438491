export const ScreenSizes = {
  mobileS: '0px',
  mobileM: '300px',
  mobileL: '480px',
  tablet: '600px',
  desktopS: '960px',
  desktopM: '1280px',
  desktopL: '1500px',
  desktopXL: '1920px',
};

export const BreakPoints = {
  mobileS: '(min-width: 0px)',
  mobileM: '(min-width: 300px)',
  mobileL: '(min-width: 480px)',
  tablet: '(min-width: 600px)',
  desktopS: '(min-width: 960px)',
  desktopM: '(min-width: 1280px)',
  desktopL: '(min-width: 1500px)',
  desktopXL: '(min-width: 1920px)',
};

export const Colors = {
  purple: '#3A4ADE',
  offBlack: '#000',
};
