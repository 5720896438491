import React, { useState } from 'react';
import {
  Box, Typography,
} from '@material-ui/core';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import ContainedLayout from '../../templates/ContainedLayout';
import { BreakPoints } from '../../../config/theme';

import { ManagementTabs } from './rules';

const Company = () => {
  const [manualError] = useState(false);
  const [activeTab, setActiveTab] = useState(ManagementTabs[0]);

  const { displayName } = useSelector((state) => state.company.data);

  if (manualError) {
    // TODO: STYLING need to show something here
    return <div>Failed to load...</div>;
  }

  return (
    <>
      {/* Header Name and Avatar */}
      <ContainedLayout tinted>
        <Box py={8}>
          <Typography variant="h3">
            {displayName}
          </Typography>
        </Box>
      </ContainedLayout>

      <Container>
        {/* Tabs */}
        <TabsContainer>
          {ManagementTabs.map((tab) => (
            <Tab
              active={activeTab.id === tab.id}
              onClick={() => setActiveTab(tab)}
            >
              <Typography variant="body1" style={{ fontWeight: 600 }}>
                {tab.tabName}
              </Typography>
            </Tab>
          ))}
        </TabsContainer>

        {/* Content */}
        <TabsContentContainer>
          <TabHeading>
            <Typography variant="h5" style={{ fontWeight: 800 }}>
              {activeTab.title}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              {activeTab.subtitle}
            </Typography>
          </TabHeading>

          {/* Tab Content */}
          {activeTab.component}
        </TabsContentContainer>
      </Container>
    </>
  );
};

export default Company;

const Container = styled.div`
  display: flex;
  @media ${BreakPoints.desktopS} {
    max-width: 1000px;
    margin: 0 auto;
  }   
  @media ${BreakPoints.desktopM} {
    max-width: 1200px;
    margin: 0 auto;
  }   
  @media ${BreakPoints.desktopL} {
    max-width: 1400px;
    margin: 0 auto;
  } 
`;

const Tab = styled.div`
  border-bottom: 1px solid #eee;
  cursor: pointer;
  padding: 1rem;
  ${({ active }) => active && `
    background-color: #eee;
  `}
  &:hover {
    background-color: #fafafa;
  }
`;

const TabHeading = styled.div`
  margin: 1rem 0;
`;

const TabsContainer = styled.div`
  flex-basis: 30%;
  border-right: 1px solid #eee;
`;

const TabsContentContainer = styled.div`
  padding: 1rem 3rem;
`;
