import React from 'react';
import {
  Link, Tooltip, Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { truncate } from '../../helpers/strings';

const LabeledLink = ({ url, label, disabled }) => (
  <LabelLinkContainer>
    {(!disabled) && (
      <Link href={url} target="_blank">
        <Typography style={{ fontWeight: 500 }} variant="body2">{label || 'Unnamed Link'}</Typography>
      </Link>
    )}
    {(disabled) && (
      <Typography color="primary" variant="body2">{label || 'Unnamed Link'}</Typography>
    )}

    <Tooltip title={!disabled && url} interactive>
      <Typography variant="body2" color="textSecondary">
        {truncate(url, 100)}
      </Typography>
    </Tooltip>
  </LabelLinkContainer>
);

export default LabeledLink;

const LabelLinkContainer = styled.div`
  margin-bottom: 1.0rem;
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`;
