import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  DialogContentText,
  LinearProgress,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import LuxonUtils from '@date-io/luxon';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { editContract } from '../../../actions/apps';
import { showSnackbar } from '../../../actions/snackbar';
import CURRENCY_CODES from '../../../config/currencies';
import { BILLING_CYCLE_TYPES } from '../../../config/constants';

const EditContract = ({
  appId,
  openContract,
  handleContractDialog,
}) => {
  const dispatch = useDispatch();
  const { contracts } = useSelector((store) => store.apps.cachedApps.data[appId]);
  const [manualError, setManualError] = useState(false);
  const [saving, setSaving] = useState(
    false,
  );

  const {
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    control,
  } = useForm({
    defaultValues: { contracts },
  });

  const handleCancel = () => {
    setManualError(false);
    handleContractDialog();
    reset();
  };

  useEffect(() => {
    reset({ contracts });
  }, [contracts, reset]);

  const updateContracts = ({ contracts: formContracts }) => {
    setSaving(true);
    setManualError(false);

    const payload = formContracts.map((c) => {
      let sanitizedDate;

      if (DateTime.isDateTime((c.renewalDate))) {
        sanitizedDate = c.renewalDate.toUTC().toISO();
      } else if (c.renewalDate._seconds) {
        sanitizedDate = DateTime.fromSeconds(c.renewalDate._seconds).toISO();
      } else {
        sanitizedDate = c.renewalDate;
      }

      return {
        currencyCode: c.currencyCode,
        cycleType: c.cycleType,
        notes: c.notes,
        amount: parseFloat(c.amount),
        renewalDate: sanitizedDate,
      };
    });

    dispatch(editContract({ contracts: payload }, appId))
      .then(() => {
        dispatch(showSnackbar('Changes Saved'));
        setSaving(false);
        handleContractDialog();
      })
      .catch(() => {
        setSaving(false);
        setManualError(true);
      });
  };

  return (
    <Dialog
      open={openContract}
      onClose={handleContractDialog}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(updateContracts)}>
        {
          (saving) && (
            <LinearProgress />
          )
        }
        <DialogContent>
          <ModalContainer>
            <ModalSectionContainer>
              <ModalSectionTitle variant="h6">
                Edit Contract
              </ModalSectionTitle>
              <>
                <ModalSectionContent>
                  <ModalSectionContentRow row>
                    <TextField
                      variant="outlined"
                      label="App Cost"
                      size="medium"
                      name="contracts[0].amount"
                      type="number"
                      error={Boolean(!!errors?.contracts?.length && errors.contracts[0].amount)}
                      inputRef={register({
                        required: 'Please provide a cost for this App',
                        min: 0,
                      })}
                    />

                    <FormControl
                      variant="outlined"
                      fullWidth

                      error={Boolean(!!errors?.contracts?.length && errors.contracts[0].cycleType)}

                    >
                      <InputLabel id="cycle-outlined-label">Billing Cycle</InputLabel>
                      <Controller
                        as={(
                          <Select variant="outlined" fullWidth label="Billing Cycle">
                            <MenuItem disabled value="">Select a Billing Cycle</MenuItem>
                            {BILLING_CYCLE_TYPES.map((type) => (
                              <MenuItem key={type.key} value={type.key}>
                                {type.display}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                        name="contracts[0].cycleType"
                        rules={{ required: 'Please select how often this app is billed' }}
                        control={control}
                      />
                    </FormControl>
                  </ModalSectionContentRow>

                  <ModalSectionContentRow mt>
                    <Controller
                      as={(
                        <Autocomplete
                          autoSelect
                          disableClearable
                          options={Object.keys(CURRENCY_CODES) || []}
                          getOptionLabel={(opt) => `${CURRENCY_CODES[opt]} (${opt})`}
                          noOptionsText="No Currency Found"
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              size="medium"
                              variant="outlined"
                              label="Billing Currency"
                              error={Boolean(!!errors?.contracts?.length && errors.contracts[0].currencyCode)}
                              helperText={errors.currencyCode && 'Please provide a billing currency'}
                            />
                          )}
                        />
                            )}
                      onChange={([, d]) => d}
                      name="contracts[0].currencyCode"
                      control={control}
                      rules={{ required: true }}
                    />
                  </ModalSectionContentRow>

                  <ModalSectionContentRow mt>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                      <Controller
                        as={(
                          <KeyboardDatePicker
                            disableToolbar
                            autoOk
                            variant="inline"
                            format="MM/dd/yyyy"
                            maxDateMessage="Invalid date"
                            minDateMessage="Invalid date"
                            fullWidth
                            error={Boolean(!!errors?.contracts?.length && errors.contracts[0].renewalDate)}
                            margin="normal"
                            inputVariant="outlined"
                            id="date-picker-inline"
                            label="Next Contract Renewal Date"
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                          )}
                        name="contracts[0].renewalDate"
                        rules={{ required: true }}
                        defaultValue={
                          contracts.length ? DateTime.fromSeconds(contracts[0].renewalDate?._seconds || 0)?.toISO() : null
                        }
                        control={control}
                      />
                    </MuiPickersUtilsProvider>
                  </ModalSectionContentRow>

                  <ModalSectionContentRow mt>
                    <TextField
                      variant="outlined"
                      label="Note"
                      size="medium"
                      name="contracts[0].notes"
                      rows={4}
                      fullWidth
                      multiline
                      error={Boolean(errors.notes)}
                        // defaultValue={c.notes}
                      inputRef={register()}
                      helperText={errors.notes && 'Something\'s not quite right here'}
                    />
                  </ModalSectionContentRow>
                </ModalSectionContent>
              </>
            </ModalSectionContainer>

            {manualError ? (
            // TODO: STYLING: This needs better styling
              <DialogContentText color="error">
                There was a problem changing the app&apos;s info.
              </DialogContentText>
            ) : null}
          </ModalContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} disabled={saving}>
            <span>Cancel</span>
          </Button>
          <Button color="primary" type="submit" disabled={!formState.dirty || saving}>
            <span>Save Changes</span>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditContract;

const ModalContainer = styled.div``;
const ModalSectionContainer = styled.div`
  margin-bottom: 40px;
`;
const ModalSectionTitle = styled(Typography)`
  margin-bottom: 10px;
`;
const ModalSectionContent = styled.div``;

const ModalSectionContentRow = styled.div`
  margin: 15px 0;
  width: 100%;
  ${({ mt }) => mt && `
    margin-top: 2rem;
  `}
  ${({ row }) => row && `
    display: flex;
    align-items: center;
    > div {
      :not(:last-child) {
        margin-right: 1rem;
      }
    }
  `}
`;
