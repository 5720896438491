import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import GetStructureAutocomplete from '../GetStructureAutocomplete';
import { editApp } from '../../../actions/apps';
import { addStructureChild, getCompany } from '../../../actions/company';
import { showSnackbar } from '../../../actions/snackbar';

const EditResponsibleTeam = ({
  appId,
  handleOwnerDialog,
  open,
  data,
}) => {
  const dispatch = useDispatch();

  const [manualError, setManualError] = useState(false);
  const [saving, setSaving] = useState(false);

  const { structure, structureChildren } = useSelector((store) => store.company.data);
  const { companyId } = useSelector((store) => store.users.currentUser.data);

  const {
    errors,
    handleSubmit,
    control,
    formState,
  } = useForm();

  const handleFormCancel = () => {
    setManualError(false);
    handleOwnerDialog();
  };

  const updateResponsibleTeam = async ({ owner }) => {
    setSaving(true);
    setManualError(false);

    const newChildren = [];
    const existingChildren = [];

    // Check for IDs that need to be created (new structures)
    Object.keys(owner).map((k) => {
      const { name, parentId, id } = owner[k];
      if (name && parentId && !id) {
        // ID Must Be Created
        newChildren.push(addStructureChild(companyId, { name, parentId }));
      } else {
        // Already Exists
        existingChildren.push(id);
      }
      return owner;
    });

    const newIds = await Promise.all(newChildren);
    dispatch(getCompany());

    dispatch(editApp({ owner: [...newIds, ...existingChildren] }, appId))
      .then(() => {
        dispatch(showSnackbar('Changes Saved'));
        setSaving(false);
        handleOwnerDialog();
      })
      .catch(() => {
        setSaving(false);
        setManualError(true);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleOwnerDialog}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(updateResponsibleTeam)}>
        {(saving) && <LinearProgress />}
        <DialogTitle id="form-dialog-title">Edit Responsible Team</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={3}>
            {structure.filter(({ status }) => status !== 'DELETED').map((s) => (
              <Grid item key={s.position}>
                <GetStructureAutocomplete
                  errors={errors}
                  structureItem={s}
                  fieldKey="owner"
                  control={control}
                  defaultValue={
                    data.map((c) => structureChildren
                      .find(({ id }) => c === id))
                      .find(({ parentId }) => parentId === s.id)
                  }
                />
              </Grid>
            ))}
          </Grid>

          {manualError && (
          <Typography variant="body2" color="error">
            There was a problem changing the app&apos;s responsible team.
          </Typography>
          )}

        </DialogContent>

        <DialogActions>
          <Button disabled={saving} onClick={handleFormCancel}>
            <span>Cancel</span>
          </Button>
          <Button color="primary" type="submit" disabled={!formState.dirty || saving}>
            <span>Save Changes</span>
          </Button>
        </DialogActions>
      </form>

    </Dialog>
  );
};

export default EditResponsibleTeam;
