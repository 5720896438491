import React, { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  LinearProgress,
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { redirectToSAML, auth, getSlugInfo } from '../../actions/auth';
import firebase from '../../config/firebase';
import { SAML, EMAIL } from '../../config/constants';

import GetEmail from '../organisms/LoginFormSteps/GetEmail';
import GetPassword from '../organisms/LoginFormSteps/GetPassword';
import Processing from '../organisms/LoginFormSteps/Processing';
import PasswordResetDialog from '../organisms/PasswordResetDialog';

const EMAIL_STATE = 'EMAIL_STATE';
const PASSWORD_STATE = 'PASSWORD_STATE';

const Login = () => {
  const history = useHistory();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: '/' } };

  const useQuery = () => new URLSearchParams(useLocation().search);

  const { register, handleSubmit } = useForm();

  const isAuthenticated = useSelector((state) => state.auth.data.isAuthenticated);

  const [switchState, setSwitchState] = useState(null);
  const [formError, setFormError] = useState(null);
  const [processing, setProcessing] = useState(true);
  const [email, setEmail] = useState('');
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [authMethod, setAuthMethod] = useState(null);
  const [tenantId, setTenantId] = useState(null);
  const [providerId, setProviderId] = useState(null);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();


  const defaultEmailValue = useQuery().get('email');
  const onboardingSequence = useQuery().get('ob');


  // Determine Which Form To Show
  useEffect(() => {
    const determineFormSteps = () => {
      if (authMethod === EMAIL) {
        setSwitchState(PASSWORD_STATE);
        setProcessing(false);
      } else if (authMethod === SAML) {
        setProcessing(false);
        setError(null);
        redirectToSAML(tenantId, providerId)
          .catch(() => {
            const errorObject = {
              code: 'custom/sso-failure',
            };
            setError(errorObject);
          });
      } else if (authMethod === null && onboardingSequence === 'internal-invite') {
        setSwitchState(PASSWORD_STATE);
        setProcessing(false);
      } else if (authMethod === null) {
        setSwitchState(EMAIL_STATE);
        setProcessing(false);
      }
    };

    firebase.auth().getRedirectResult().then((redirect) => {
      if (!redirect.user) {
        determineFormSteps();
      } else {
        // A Redirect Occurred
        // TODO: Check for Need to Onboard
      }
    });
  }, [authMethod, dispatch, providerId, tenantId, onboardingSequence]);

  const handleEmailCheckSubmit = async (data) => {
    setFormError(null);
    setProcessing(true);

    // Prepare Email Address for Lookup
    setEmail(data.username.toLowerCase());
    setError(null);
    getSlugInfo(data.username.toLowerCase())
      .then((slugInfo) => {
        setAuthMethod(slugInfo.authMethod);
        setTenantId(slugInfo.tenantId);
        setProviderId(slugInfo.providerId);
      })
      .catch(() => {
        const errorObject = {
          code: 'custom/no-slug-found',
        };
        setError(errorObject);
      });
  };

  const handlePasswordSubmit = async (data) => {
    setFormError(null);
    setProcessing(true);
    dispatch(auth({ email, password: data.password }, tenantId))
      .catch((err) => {
        setError(err);
      });
  };

  const toggleResetDialogDisplay = () => setResetDialogOpen(!resetDialogOpen);

  // Auth Failure Management
  useEffect(() => {
    if (
      isAuthenticated === false && error !== null
    ) {
      if (error.code === 'auth/user-not-found') {
        setSwitchState(EMAIL_STATE);
        setProcessing(false);
        setFormError('We couldn\'t find an account with that email address.');
      } else if (error.code === 'auth/wrong-password') {
        setSwitchState(PASSWORD_STATE);
        setProcessing(false);
        setFormError('The password you entered was incorrect.');
      } else if (error.code === 'auth/too-many-requests') {
        setSwitchState(EMAIL_STATE);
        setProcessing(false);
        setFormError('You have tried to login incorrectly too many times. Wait a minute and try again.');
      } else if (error.code === 'auth/invalid-email') {
        setSwitchState(EMAIL_STATE);
        setProcessing(false);
        setFormError('The email address you entered was invalid. Please try again.');
      } else if (error.code === 'custom/no-slug-found') {
        setSwitchState(EMAIL_STATE);
        setProcessing(false);
        setFormError('We couldn\'t find that organization. Please check the email address and try again.');
      } else if (error.code === 'custom/sso-failure') {
        setSwitchState(EMAIL_STATE);
        setProcessing(false);
        setFormError('There was a problem with redirecting to Single Sign-On.');
      } else {
        setFormError('Something wen\'t wrong. Please try again.');
        setSwitchState(EMAIL_STATE);
        setProcessing(false);
      }
    } else if (isAuthenticated) {
      history.replace(from);
    }
  }, [history, from, isAuthenticated, error]);

  const renderSwitch = (param) => {
    switch (param) {
      case EMAIL_STATE:
        return (
          <GetEmail
            register={register}
            toggleResetDialogDisplay={toggleResetDialogDisplay}
            handleSubmit={handleSubmit}
            handleEmailCheckSubmit={handleEmailCheckSubmit}
            formError={formError}
            processing={processing}
            defaultValue={defaultEmailValue}
          />
        );
      case PASSWORD_STATE:
        return (
          <GetPassword
            register={register}
            toggleResetDialogDisplay={toggleResetDialogDisplay}
            handleSubmit={handleSubmit}
            handlePasswordSubmit={handlePasswordSubmit}
            formError={formError}
            processing={processing}
            email={email}
          />
        );
      default:
        return <Processing />;
    }
  };

  if (isAuthenticated) {
    return <div />;
  }

  return (
    <Paper>
      {
        (processing) && (
          <LinearProgress />
        )
      }
      <Box p={8}>
        {renderSwitch(switchState)}
      </Box>

      {/* Reset Dialog */}
      <PasswordResetDialog
        resetDialogOpen={resetDialogOpen}
        email={email}
        toggleResetDialogDisplay={toggleResetDialogDisplay}
      />
    </Paper>
  );
};

export default Login;
