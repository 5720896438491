import React, { useState } from 'react';
import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Redirect } from 'react-router-dom';

import { showSnackbar, showErrorSnackbar } from '../../actions/snackbar';
import { incrementAppOpenCount, toggleArchiveApp } from '../../actions/apps';

import BasicInfo from '../molecules/AppPageSections/BasicInfo';
import Icon from '../molecules/AppPageSections/Icon';
import { APP_ARCHIVED_STATUS } from '../../config/constants';
import ConfirmAppArchive from './ConfirmAppArchive';
import ConfirmAppDelete from './ConfirmAppDelete';
import PinAppDialog from './PinAppDialog'

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  largeAvatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const AppHeaderWithActions = ({
  category,
  url,
  icon,
  availabilityStatus,
  appId,
  name,
  editMode,
  presenceStatus,
  appFollowers,
  firstParty,
  isPinned,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [
    confirmAppArchiveDialogOpen,
    setConfirmAppArchiveDialogOpen,
  ] = useState(false);
  const [
    pinnedAppDialogOpen,
    setPinnedAppDialogOpen,
  ] = useState(false);
  const [confirmAppDeleteDialogOpen, setConfirmAppDeleteDialogOpen] = useState(
    false,
  );
  const [redirect, setRedirect] = useState(false);

  const userRole = useSelector((state) => state.users.currentUser.data.role);
  const appCategories = useSelector((store) => store.client.data.categories);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleExtendedMenuClose = () => setAnchorEl(null);

  const handleDelete = () => {
    setConfirmAppDeleteDialogOpen(!confirmAppDeleteDialogOpen);
    handleExtendedMenuClose();
  };

  const handlePinToggle = () => {
    setPinnedAppDialogOpen(!pinnedAppDialogOpen);
    handleExtendedMenuClose();
  };

  const handleArchiveToggle = () => {
    if (presenceStatus === APP_ARCHIVED_STATUS) {
      dispatch(toggleArchiveApp(appId, true))
        .then(() => {
          dispatch(showSnackbar(`Restored ${name}`));
          handleExtendedMenuClose();
        })
        .catch(() => {
          dispatch(showErrorSnackbar(`There was an error restoring ${name}`));
        });
      return;
    }
    setConfirmAppArchiveDialogOpen(!confirmAppArchiveDialogOpen);
    handleExtendedMenuClose();
  };

  if (redirect) return <Redirect to={{ pathname: '/' }} />;

  return (
    <ContentContainer>
      <HeaderContainer>
        {/* Text, Logo and Category block */}
        <HeaderLeftItem>
          <HeaderLogo>
            <Icon
              appId={appId}
              editMode={editMode}
              name={name}
              icon={icon}
              classes={classes}
            />
          </HeaderLogo>

          <HeaderTextBlock>
            <BasicInfo
              appId={appId}
              editMode={editMode}
              name={name}
              availabilityStatus={availabilityStatus}
              url={url}
              category={category}
              appCategories={appCategories}
              classes={classes}
              presenceStatus={presenceStatus}
              firstParty={firstParty}
              appFollowers={appFollowers}
            />
          </HeaderTextBlock>
        </HeaderLeftItem>
        <HeaderRightItem>
          <HeaderButtonRight id="jr-app-launcher">
            <Button
              title={url}
              variant="contained"
              color="primary"
              size="large"
              href={url}
              onClick={() => incrementAppOpenCount(appId)}
              disabled={!url}
              target="_blank"
              startIcon={<OpenInNewIcon />}
            >
              OPEN APP
            </Button>
          </HeaderButtonRight>

          <HeaderButtonRight>
            <Button
              variant="contained"
              size="large"
              onClick={handleClick}
              startIcon={<ExpandMoreIcon />}
            >
              Advanced
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleExtendedMenuClose}
            >

              {userRole >= 10 && (
                <Section borderBottom>
                  <MenuItem onClick={handlePinToggle}>
                    {!isPinned
                      ? 'Pin for Everyone...'
                      : 'Unpin for Everyone...'}
                  </MenuItem>
                </Section>
              )}

              <Section>
                <MenuItem disabled>Danger Zone</MenuItem>
                <MenuItem onClick={handleArchiveToggle}>
                  {presenceStatus !== APP_ARCHIVED_STATUS
                    ? 'Archive App...'
                    : 'Restore App'}
                </MenuItem>
                {userRole < 10 ? null : (
                  <MenuItem onClick={handleDelete}>Delete App</MenuItem>
                )}
              </Section>
            </Menu>
          </HeaderButtonRight>
        </HeaderRightItem>
      </HeaderContainer>

      <PinAppDialog
        appId={appId}
        pinAppDialogOpen={pinnedAppDialogOpen}
        setPinAppDialogOpen={setPinnedAppDialogOpen}
        isPinned={isPinned}
        name={name}
      />

      <ConfirmAppArchive
        appId={appId}
        confirmAppArchiveDialogOpen={confirmAppArchiveDialogOpen}
        setConfirmAppArchiveDialogOpen={setConfirmAppArchiveDialogOpen}
        name={name}
      />

      <ConfirmAppDelete
        appId={appId}
        confirmAppDeleteDialogOpen={confirmAppDeleteDialogOpen}
        setConfirmAppDeleteDialogOpen={setConfirmAppDeleteDialogOpen}
        setRedirect={setRedirect}
        name={name}
      />
    </ContentContainer>
  );
};

export default AppHeaderWithActions;

const ContentContainer = styled.div`
  z-index: 5;
  width: 100%;
`;

const HeaderContainer = styled.div`
  padding-top: 5rem;
  padding-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderLeftItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const HeaderLogo = styled.div`
  margin-right: 2rem;
`;

const HeaderTextBlock = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderRightItem = styled.div`
  dispaly: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
`;

const HeaderButtonRight = styled.div`
  margin-left: 1rem;
  display: inline-block;
`;

const Section = styled.div`
  padding: .5rem 0.5rem .5rem .5rem;
  ${({ borderTop }) => borderTop && 'border-top: 1px solid #eee;'}
  ${({ borderBottom }) => borderBottom && 'border-bottom: 1px solid #eee;'}
`;
