import firebase from '../config/firebase';
import { getSingleUser, getCurrentUser } from './users';
import {
  EMAIL,
  DOMAIN,
  PATCH,
  PUT,
  DELETE,
} from '../config/constants';
import { getSlugInfo } from './auth';
import { getCompany } from './company';
import { brodiAuthedRequest } from '../helpers/backend';

export const inviteUserAdmin = async (email, name, role) => {
  const { tenantId } = firebase.auth().currentUser;
  await brodiAuthedRequest(PUT, '/user/create', {
    email,
    name,
    role,
    tenantId,
  });
};

export const requestEmailChange = (uid, email, newEmail) => async (
  dispatch,
) => {
  const { tenantId, authMethod } = await getSlugInfo(email);
  const returnUrl = DOMAIN;
  if (authMethod !== EMAIL) throw new Error('Auth Method not email/password');
  await brodiAuthedRequest(PATCH, `/user/${uid}/change-email`, {
    email,
    newEmail,
    tenantId,
    returnUrl,
  });
  await dispatch(getSingleUser(uid, true));
};

export const enableUserAdmin = (uid) => async (dispatch) => {
  const { tenantId } = firebase.auth().currentUser;
  await brodiAuthedRequest(PATCH, `/user/${uid}/enable`, { tenantId });
  await dispatch(getSingleUser(uid, true));
};

export const disableUserAdmin = (uid) => async (dispatch) => {
  const { tenantId } = firebase.auth().currentUser;
  await brodiAuthedRequest(PATCH, `/user/${uid}/disable`, { tenantId });
  await dispatch(getSingleUser(uid, true));
};

export const updateUserDetailsAdmin = (uid, preferences) => async (
  dispatch,
) => {
  const { tenantId } = firebase.auth().currentUser;
  await brodiAuthedRequest(PATCH, `/user/${uid}?adminEdit=1`, {
    tenantId,
    preferences,
  });
  await dispatch(getSingleUser(uid, true));
};

export const deleteApp = (appId) => async (dispatch) => {
  await brodiAuthedRequest(DELETE, `/app/${appId}`);
  await dispatch(getCurrentUser());
};

export const editSlackToken = (apiKey = '', enabled = false) => async (
  dispatch,
  getState,
) => {
  const { companyId } = getState().users.currentUser.data;
  await brodiAuthedRequest(PATCH, `/company/${companyId}/slack-api-key`, { enabled, apiKey });
  await dispatch(getCompany());
};
