import React, { useState, useEffect } from 'react';

import {
  Typography, Tooltip,
} from '@material-ui/core';
import styled from 'styled-components';
import { DateTime } from 'luxon';

import { useSelector, useDispatch } from 'react-redux';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactMarkdown from 'react-markdown';
import CollapsableItemPanel from '../../organisms/CollapsableItemPanel';
import LeadingTitleText from '../../atoms/LeadingTitleText';
import AppAvailabilityStatusIcon from '../../atoms/AppAvailabilityStatusIcon';

import {
  APP_AVAILABILITY_STATUS_MAPS,
  APP_AVAILABLE_STATUS,
  APP_PARTIALLY_UNAVAILABLE_STATUS, APP_UNAVAILABLE_STATUS
} from '../../../config/constants'
import { getSingleUser } from '../../../actions/users';
import ChangeAppAvailabilityStatusDialog from '../../organisms/ChangeAppAvailabilityStatusDialog';
import EditBasicInfo from '../AppPageEditDialogs/EditBasicInfo';
import { resolveCategoryId } from '../../../helpers/resolvers';
import { showSnackbar } from '../../../actions/snackbar';
import { truncate } from '../../../helpers/strings';
import { getApp } from '../../../actions/apps';

const About = ({
  description, customFields, appId, name, availability, appFollowers, category, url, firstParty,
}) => {
  const dispatch = useDispatch();
  const {
    status, message, updatedBy, updated,
  } = useSelector((store) => store.apps.cachedApps.data[appId].availability);
  const user = useSelector((store) => store.users.cachedUsers.data[updatedBy]);
  const { categories } = useSelector((store) => store.client.data);

  const [openStatus, setOpenStatus] = useState(false);
  const [openBasicInfo, setOpenBasicInfo] = useState(false);

  const handleBasicInfoDialog = () => {
    dispatch(getApp(appId, true)).then(() => {
      setOpenBasicInfo(!openBasicInfo);
    });
  };
  const handleStatusDialog = () => {
    dispatch(getApp(appId, true)).then(() => {
      setOpenStatus(!openStatus);
    });
  };

  useEffect(() => {
    if (!user && updatedBy) dispatch(getSingleUser(updatedBy));
  }, [updatedBy, dispatch, user]);

  const menuItems = [
    {
      label: 'Edit About',
      action: handleBasicInfoDialog,
    },
    {
      label: 'Edit Service Status',
      action: handleStatusDialog,
    },
  ];

  return (
    <CollapsableItemPanel heading="About" editLabel="Edit About" menuItems={menuItems} key="About">
      <Item status={status}>
        <LeadingTitleText
          heading="Service Status"
        />
        <AvailabilityContainer>
          <AvailabilityStatusSummary>
            <Typography variant="body2" style={{ fontWeight: 600 }} gutterBottom>
              <AppAvailabilityStatusIcon status={status} />
              <span>
                {APP_AVAILABILITY_STATUS_MAPS[status].title}
              </span>
            </Typography>
            {(message || status !== APP_AVAILABLE_STATUS) && (
              <>
                <Typography variant="body2" gutterBottom>
                  {message}
                </Typography>
                <Tooltip
                  title={DateTime.fromSeconds(updated._seconds).toLocaleString(DateTime.DATETIME_FULL)}
                >
                  <Typography variant="body2" color="textSecondary">
                    Last updated
                    {' '}
                    {DateTime.fromSeconds(updated._seconds - 2).toRelative()}
                    {' '}
                    by
                    {' '}
                    {(user) ? user.name : 'Unknown User'}
                  </Typography>
                </Tooltip>
              </>
            )}
          </AvailabilityStatusSummary>
        </AvailabilityContainer>
      </Item>

      <LeadingTitleText heading="Description">
        <Typography variant="body2" component="div">
          <ReactMarkdown source={description} />
        </Typography>
      </LeadingTitleText>

      <AppUrlCategoryRow>
        <LeadingTitleText heading="URL">
          {(!url) && (
          <UrlContainer>
            <UrlText>
              <Typography variant="body2" color="textSecondary">No URL Added</Typography>
            </UrlText>
          </UrlContainer>
          )}
          {(url) && (
          <UrlContainer>
            <UrlText>
              <Tooltip title={url}>
                <Typography variant="body2">{truncate(url, 30)}</Typography>
              </Tooltip>
            </UrlText>
            <CopyIcon title="Copy URL">
              <CopyToClipboard
                text={url}
                onCopy={() => dispatch(showSnackbar('Copied URL'))}
              >
                <FileCopyIcon color="disabled" fontSize="small" />
              </CopyToClipboard>
            </CopyIcon>
          </UrlContainer>
          )}
        </LeadingTitleText>

        <LeadingTitleText heading="Category">
          <Typography variant="body2">{resolveCategoryId(category, categories)}</Typography>
        </LeadingTitleText>

        <LeadingTitleText heading="Source">
          <Typography variant="body2">
            {firstParty ? 'First Party' : 'Third Party'}
          </Typography>
        </LeadingTitleText>
      </AppUrlCategoryRow>

      <CustomFieldsContainer>
        {customFields && customFields.map((value) => (
          <CustomFieldContainer key={`${value.key}-${value.value}`}>
            <LeadingTitleText heading={value.key}>
              <Typography variant="body2">{value.value}</Typography>
            </LeadingTitleText>
          </CustomFieldContainer>
        ))}
      </CustomFieldsContainer>

      <EditBasicInfo
        appId={appId}
        handleBasicInfoDialog={handleBasicInfoDialog}
        openBasicInfo={openBasicInfo}
        appCategories={categories}
        category={category}
        url={url}
        description={description}
        name={name}
        customFields={customFields}
        firstParty={firstParty}
      />

      <ChangeAppAvailabilityStatusDialog
        statusDialogOpen={openStatus}
        setStatusDialogOpen={setOpenStatus}
        availability={availability}
        appId={appId}
        name={name}
        followers={appFollowers}
      />

    </CollapsableItemPanel>
  );
};

export default About;

const CustomFieldsContainer = styled.div`
  display: flex;
  margin-top: 2.0rem;
`;

const CustomFieldContainer = styled.div`
  margin-right: 1.5rem;
`;

const Item = styled.div`
  margin-bottom: 2.0rem;
  border-radius: 10px;
  ${({ status }) => {
    if (status === APP_PARTIALLY_UNAVAILABLE_STATUS) {
      return `
      padding: 1rem;
      background-color: rgb(255,133,27, 0.1);
      `;
    }
    if (status === APP_UNAVAILABLE_STATUS) {
      return `
      padding: 1rem;
      background-color: rgb(255,65,54, 0.1);
      `;
    }
    return '';
  }}  
`;

const AppUrlCategoryRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  margin-top: 1rem;
  justify-content: space-between;
  width: 90%;
`;

const AvailabilityContainer = styled.div`
`;

const AvailabilityStatusSummary = styled.div``;

const UrlContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UrlText = styled.div`
  margin-right: .5rem;
`;

const CopyIcon = styled.div`
  cursor: pointer;
  hover: {
    color: #000;
  };
`;
