import React, {
  useEffect,
  // useState
} from 'react';
import {
  // useSelector,
  useDispatch,
} from 'react-redux';
// import styled from 'styled-components';
import { getSingleUser } from '../../actions/users';
// import ContactDialog from '../organisms/ContactDialog';

const AvatarWithLabelLink = ({
  type, data,
}) => {
  // const user = useSelector((store) => store.users.cachedUsers.data[data]);
  // const slackEnabled = useSelector((state) => state.company.data.slackEnabled);
  // const [manualError, setManualError] = useState(false);
  // const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) dispatch(getSingleUser(data));
  }, [data, dispatch, type]);

  // const toggleContactDialog = () => setContactDialogOpen(!contactDialogOpen);

  return (
    <>
      {/* <ContactDialog
        slackEnabled={slackEnabled}
        userId={data}
        contactDialogOpen={contactDialogOpen}
        toggleContactDialog={toggleContactDialog}
      /> */}
    </>
  );
};

export default AvatarWithLabelLink;

// const AvatarContainer = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   margin-right: 0rem;
//   margin-bottom: 0.5rem;
//   border-radius: 5px;
//   &:hover {
//     cursor: pointer;
//     background-color: #FAFAFA;
//     font-weight: 500;
//     div {
//       display: block;
//     }
//   };
// `;

// const Avatar = styled.img`
//   display: relative;
//   border-radius: 50%;
//   background-color: #FFF;
//   overflow: hidden;
//   object-fit: cover;
//   width: 50px;
//   height: 50px;
//   box-shadow: 0px 2px 3px #AAA;
// `;
