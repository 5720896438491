import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Typography, Button } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { DateTime } from 'luxon';
import CollapsibleItemPanel from '../../organisms/CollapsableItemPanel';
import EditContract from '../AppPageEditDialogs/EditContract';
import LeadingTitleText from '../../atoms/LeadingTitleText';
import { currencyFormat } from '../../../helpers/numbers';
import { getApp } from '../../../actions/apps';
import ErrorIcon from '@material-ui/icons/Error';
const Contract = ({
  appId,
}) => {
  const dispatch = useDispatch();
  const { contracts } = useSelector((store) => store.apps.cachedApps.data[appId]);
  const [openContract, setOpenContract] = useState(false);
  const handleContractDialog = () => {
    dispatch(getApp(appId, true)).then(() => {
      setOpenContract(!openContract);
    });
  };

  const menuItems = [
    {
      label: 'Edit Contract',
      action: handleContractDialog,
    },
  ];

  return (
    <CollapsibleItemPanel heading="Contract" menuItems={menuItems} key="contract">
      {!contracts.length && (
        <EmptyStateContainer>
          <Typography align="center" variant="body1" color="textSecondary">
            No Cost or Contract Information
          </Typography>
          <Button size="small" onClick={handleContractDialog} color="primary">Add Contract Info</Button>
        </EmptyStateContainer>
      )}

      {!!contracts.length && contracts.map((c) => (
        <React.Fragment key={c.amount}>
          <StatsContainer>
            <LeadingTitleText heading="Cost Per Billing Cycle">
              <Typography variant="body2" component="div">
                {currencyFormat(c.amount, { currency: c?.currencyCode })}
              </Typography>
            </LeadingTitleText>

            <LeadingTitleText heading="Billing Cycle">
              <Typography variant="body2" component="div">
                {c.cycleType === 'ANNUALLY' && 'Every Year'}
                {c.cycleType === 'MONTHLY' && 'Every Month'}
              </Typography>
            </LeadingTitleText>

            <LeadingTitleText heading="Renewal Date">
              {c.renewalDate?._seconds ? (
                <div style={{ display: 'flex', alignItems: 'flex-start'}}>
                  <Typography variant="body2" component="div" >
                    {DateTime.fromSeconds(c.renewalDate._seconds).toLocaleString(DateTime.DATE_FULL)}
                    {` (${DateTime.fromSeconds(c.renewalDate._seconds).toRelative()})`}
                  </Typography>
                  {DateTime.fromSeconds(c.renewalDate._seconds)
                    .diff(DateTime.local(), ['days'])
                    .toObject().days < 30 && (
                    <div style={{ marginLeft: '5px' }} title='Expired or Expiring Soon'>
                      <ErrorIcon fontSize='small' htmlColor="red" />
                    </div>
                  )}
                </div>
              ) : (
                <Typography variant="body2" component="div" color="textSecondary">
                  No Renewal Date Added
                </Typography>
              )}
            </LeadingTitleText>
          </StatsContainer>
          <NotesContainer>
            <LeadingTitleText heading="Notes">
              {c.notes ? (
                <Typography variant="body2" component="div">
                  <ReactMarkdown
                    source={c.notes}
                    disallowedTypes={['paragraph']}
                    unwrapDisallowed
                  />
                </Typography>
              ) : (
                <Typography variant="body2" component="div" color="textSecondary">
                  No Notes Added
                </Typography>
              )}
            </LeadingTitleText>
          </NotesContainer>
        </React.Fragment>
      ))}

      <EditContract
        appId={appId}
        handleContractDialog={handleContractDialog}
        openContract={openContract}
      />
    </CollapsibleItemPanel>
  );
};

export default Contract;

const EmptyStateContainer = styled.div`
  text-align: center;
  margin: 2rem auto;
`;

const NotesContainer = styled.div`
  margin-top: 1rem;
`;

const StatsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  > div {
    margin-right: 3rem;
    margin-bottom: 1rem;
  }
`;
