import { addStructureChild } from "../actions/company";

// TODO fix this from a hacky solution
const stripAddPrefix = (name) => {
  if (name.startsWith('Add "', 0)) {
    let stripped = name.replace('Add "', '');
    stripped = stripped.substring(0, stripped.length - 1);
    return stripped;
  }
  return name;
};

export const getNextFormStep = (formStep) => {
  return parseInt(formStep, 10) + 1
};

export const getPrevFormStep = (formStep) => {
  const newStep = parseInt(formStep, 10) - 1;
  if (newStep < 0) return 0;
  return newStep;
};

export const stripKeys = (data, keyToGet) => {
  const stripped = {};
  Object.keys(data).map((key) => {
    stripped[key] = stripAddPrefix(data[key][keyToGet] || data[key]);
    return stripped;
  });
  return stripped;
};

export const structurePassThru = async (structureItems, companyId) => {
  const newChildren = [];
  const existingChildren = [];

  // Check for IDs that need to be created (new structures)
  Object.keys(structureItems).map((k) => {
    const { name, parentId, id } = structureItems[k];
    if (name && parentId && !id) {
    // ID Must Be Created
      newChildren.push(addStructureChild(companyId, { name, parentId }));
    } else {
    // Already Exists
      existingChildren.push(id);
    }
    return structureItems;
  });

  const newIds = await Promise.all(newChildren);
  return [...newIds, ...existingChildren];
};
