import React, { useState } from 'react';
import styled from 'styled-components';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import {
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { resolveCategoryId } from '../../../helpers/resolvers';
import { toggleFollowApp } from '../../../actions/users';
import { APP_ARCHIVED_STATUS } from '../../../config/constants';
import FollowAppDialog from '../../organisms/FollowAppDialog';
import ContactListDialog from '../ContactsListDialog';

export default ({
  name,
  category,
  appCategories,
  appId,
  presenceStatus,
  appFollowers,
}) => {
  const dispatch = useDispatch();
  const { followingPreferences } = useSelector((state) => state.users.currentUser.data);

  const [followAppDialogOpen, setFollowAppDialogOpen] = useState(false);
  const [followersDialog, setFollowersDialog] = useState(false);

  const isFavorite = followingPreferences.find(({ appId: rawAppId }) => rawAppId === appId);
  const handleFollowersListDialog = () => setFollowersDialog(!followersDialog);

  const handleFollowClick = () => {
    if (!isFavorite) setFollowAppDialogOpen(true);
    else dispatch(toggleFollowApp(isFavorite, appId, null));
  };

  return (
    <Container>
      <TopRow>
        <Typography variant="h3" component="div">
          {name}
        </Typography>
        <Tooltip title={isFavorite ? 'Remove from your favorites' : 'Favorite this app to be notified about important updates'}>
          <FavoriteIconHolder onClick={handleFollowClick} id="jr-app-favorite">
            {isFavorite ? (
              <FavoriteIcon htmlColor="red" />
            ) : (
              <FavoriteBorderIcon htmlColor="black" />
            )}
          </FavoriteIconHolder>
        </Tooltip>
        {appFollowers?.length > 0 && (
        <Typography
          style={{ cursor: 'pointer' }}
          variant="body1"
          title={`Favorited by ${appFollowers?.length} ${appFollowers?.length === 1 ? 'Person' : 'People'}`}
          onClick={handleFollowersListDialog}
        >
          (
          <span style={{ textDecoration: 'underline' }}>{appFollowers?.length}</span>
          )
        </Typography>
        )}
      </TopRow>

      <LastRow>
        {(presenceStatus === APP_ARCHIVED_STATUS) && (
        <Chip>ARCHIVED APP</Chip>
        )}
        <Chip color="#eee">
          {resolveCategoryId(category, appCategories).toUpperCase()}
        </Chip>
      </LastRow>

      <FollowAppDialog
        followAppDialogOpen={followAppDialogOpen}
        setFollowAppDialogOpen={setFollowAppDialogOpen}
        appId={appId}
        name={name}
      />

      <ContactListDialog
        contactIdsArray={appFollowers}
        openList={followersDialog}
        handleListDialog={handleFollowersListDialog}
        groupText={`People With ${name} in their Favorites`}
      />

    </Container>
  );
};

const Container = styled.div``;

const TopRow = styled.div`
  display: flex;
  align-items: center;
`;

const FavoriteIconHolder = styled(IconButton)`
  margin-left: 10px;
`;

const LastRow = styled.div`
  margin-top: 5px;
`;

const Chip = styled.div`
  display: inline-block;
  background-color: ${(props) => (props.color ? props.color : '#ffe135')};
  color: #000;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 700
  ;
  font-size: 12px;
  margin-right: 20px;
`;
