import React from 'react';

import { Typography } from '@material-ui/core';
import Select from 'react-select';
import styled from 'styled-components';

import algoliasearch from 'algoliasearch/lite';
import { connectAutoComplete, InstantSearch } from 'react-instantsearch-dom';

import { useSelector } from 'react-redux';
import { applicationId } from '../../config/algolia';
import {
  ALGOLIA_COMPANY_INDEX_PREFIX, ALGOLIA_USERS_SUFFIX,
} from '../../config/constants';

const ContactFields = ({ companyId, group, formData, setFormData, fieldName }) => {
  const algoliaId = useSelector((store) => store.company.data.algoliaId);
  const constructedIndexId = ALGOLIA_COMPANY_INDEX_PREFIX + companyId + ALGOLIA_USERS_SUFFIX;
  const searchClient = algoliasearch(applicationId, algoliaId);
  const cachedUsers = useSelector((store) => store.users.cachedUsers.data);

  const selectStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 500,
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 500,
      position: 'relative',
    }),
    menuList: (provided) => ({
      ...provided,
      zIndex: 500,
    }),
  };

  const AutocompleteContactsLite = ({
    refine,
    hits,
    groupId,
  }) => (
    <Select
      isMulti
      key={`selectMenu-${groupId}`}
      styles={selectStyles}
      options={hits}
      noOptionsMessage={() => 'Nobody Found'}
      value={formData[groupId] || []}
      getOptionValue={(option) => option.objectID || option.data}
      getOptionLabel={(option) => option.name || cachedUsers[option.data]?.name}
      name={fieldName}
      filterOption={(options) => options}
      defaultValue={formData[groupId]}
      onInputChange={(searchString) => refine(searchString)}
      onChange={(e) => {
        setFormData({
          ...formData,
          [groupId]: e,
        });
      }}
    />
  );

  const CustomAutocomplete = connectAutoComplete(AutocompleteContactsLite);

  return (
    <Container>
      <TypeDescription>
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          {group.name.toUpperCase()}
        </Typography>
        <Typography gutterBottom variant="body2">
          {group.description}
        </Typography>
      </TypeDescription>
      <Content>
        <InstantSearch indexName={constructedIndexId} searchClient={searchClient}>
          <CustomAutocomplete
            formData={formData}
            setFormData={setFormData}
            groupId={group.id}
          />
        </InstantSearch>
      </Content>
    </Container>
  );
};

export default ContactFields;

const Container = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  border: 1px solid #eee;
`

const TypeDescription = styled.div`
  flex-basis: 30%;
`

const Content = styled.div`
  flex-grow: 1;
`
