import React from 'react';
import {
  makeStyles, Button,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ProgressButton = ({
  loading,
  cta,
  variant,
  color,
  startIcon,
  endIcon,
  size,
  type,
  component,
  disabled,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        onClick={onClick}
        type={type || 'submit'}
        component={component}
        variant={variant || 'contained'}
        color={color || 'primary'}
        size={size || 'large'}
        disableElevation
        disabled={loading || disabled}
        startIcon={startIcon}
        endIcon={!loading ? (endIcon || <ChevronRightIcon />) : null}
      >
        {loading ? <CircularProgress size={24} className={classes.buttonProgress} /> : cta}&nbsp;
      </Button>
    </div>
  );
};

export default ProgressButton;
