import React, { useState } from 'react';

import {
  Typography, Avatar, InputBase, Popper, fade, makeStyles,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import styled from 'styled-components';

import { useHistory } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { labelInitials } from '../../helpers/strings';
import { resolveCategoryId } from '../../helpers/resolvers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.10),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.15),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    color: fade(theme.palette.common.black, 0.50),
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'black',
  },
  inputFocused: {
    color: fade(theme.palette.common.black, 0.9),
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '30ch',
      '&:focus': {
        width: '50ch',
      },
    },
  },
}));

const ResultOption = ({ option, categories, state = {} }) => {
  if (option.advancedSearch) {
    return (
      <AdvancedSearchOption>
        <SearchResultItemTextContainer>
          <Typography variant="body1" style={{ fontWeight: 500 }} color="textSecondary">
            {state.inputValue ? `All Results for "${state.inputValue}"...` : 'Advanced Search...'}
          </Typography>
        </SearchResultItemTextContainer>
      </AdvancedSearchOption>
    );
  }

  if (option.addApp) {
    return (
      <AdvancedSearchOption>
        <SearchResultItemTextContainer>
          <Typography variant="body1" style={{ fontWeight: 500 }} color="textSecondary">
            {state.inputValue ? `Add "${state.inputValue}"...` : 'Add a New App'}
          </Typography>
        </SearchResultItemTextContainer>
      </AdvancedSearchOption>
    );
  }

  return (
    <SearchResultItemContainer>
      <SearchResultItemLogo>
        <Avatar variant="rounded" src={option.icon}>{labelInitials(option.name)}</Avatar>
      </SearchResultItemLogo>
      <SearchResultItemTextContainer>
        <Typography variant="body1" style={{ fontWeight: 500 }}>{option.name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {resolveCategoryId(option.category, categories)}
          { option.presenceStatus === 1 && ' - Archived'}
        </Typography>
      </SearchResultItemTextContainer>
    </SearchResultItemContainer>
  );
};

const InputComponent = ({ classes, params, displayName }) => (
  <div className={classes.search}>
    <div className={classes.searchIcon}>
      <SearchIcon />
    </div>
    <InputBase
      ref={params.InputProps.ref}
      placeholder={`Search Apps at ${displayName}...`}
      inputProps={params.inputProps}
      disabled={params.disabled}
      id={params.id}
      fullWidth
      classes={{
        root: classes.inputRoot,
        input: classes.inputInput,
        focused: classes.inputFocused,
      }}
    />
  </div>
);

const NavBarAutoComplete = ({ hits, refine }) => {
  const classes = useStyles();
  const history = useHistory();

  const appCategories = useSelector((store) => store.client.data.categories);
  const { displayName } = useSelector((store) => store.company.data);

  const [currentInput, setCurrentInput] = useState('');

  const handleSelected = (event, values) => {
    const {
      objectID, name, advancedSearch, addApp,
    } = values;
    if (advancedSearch) history.push(`/search?query=${currentInput}`);
    if (addApp) history.push('/add');
    if (objectID) history.push(`/app/${objectID}/${name}`);
  };

  const customPopper = (props) => <Popper {...props} style={{ width: 505 }} placement="bottom-end" />;

  return (
    <Autocomplete
      disableClearable
      freeSolo
      noOptionsText="No Results"
      PopperComponent={customPopper}
      options={[{ advancedSearch: true }, ...hits, { addApp: true }]}
      filterOptions={(options) => options}
      getOptionLabel={(option) => option.name || ''}
      onChange={handleSelected}
      getOptionSelected={(option, value) => option.objectID === value.objectID}
      onInputChange={(event) => {
        refine(event.currentTarget.value);
        setCurrentInput(event.currentTarget.value);
      }}
      renderOption={(option, state) => (
        <ResultOption option={option} categories={appCategories} state={state} />
      )}
      renderInput={(params) => (
        <InputComponent params={params} classes={classes} displayName={displayName} />
      )}
    />
  );
};

export default NavBarAutoComplete;

const SearchResultItemContainer = styled.div`
  display: flex;
  padding: 0.5rem;
  align-items: center;
  justify-content: space-between;
`;

const SearchResultItemLogo = styled.div`
  margin-right: 1rem;
`;

const SearchResultItemTextContainer = styled.div`
  flex-grow: 1;
`;

const AdvancedSearchOption = styled.div`
  width: 100%;
  padding: 0.5rem;
`;
