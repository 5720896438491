import React from 'react';

import {
  Grid, Box, Snackbar, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useSelector, useDispatch } from 'react-redux';

import { clearSnackbar } from '../../actions/snackbar';

const UnauthenticatedLayout = ({ children }) => {
  const dispatch = useDispatch();
  const snackbar = useSelector((store) => store.snackbar.data);
  const handleClose = () => {
    dispatch(clearSnackbar());
  };
  return (
    <Box style={{ backgroundColor: '#FAFAFA' }}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={snackbar.display}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbar.message}
        action={(
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      />

      <Grid
        container
        direction="row"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh', minWidth: '100%' }}
      >
        <Grid item xs={10} md={5}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UnauthenticatedLayout;
