import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import { getFirstName } from '../../helpers/strings';

import JKImg from './images/jk.jpg';

const TrialDialog = ({
  handleDialog, dialogOpen, plan,
}) => {
  const { name } = useSelector((store) => store.users.currentUser.data);
  const { endDate = {} } = plan;

  const end = DateTime.fromSeconds(endDate._seconds || 0);
  const days = Math.round(end.diff(DateTime.local(), ['days'])?.days || 1);
  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialog}
      aria-labelledby="form-dialog-title"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="form-dialog-title">
        You're Currently on a Free Trial
      </DialogTitle>
      <DialogContent>

        <Typography variant="body1">
          Hi
          {' '}
          {getFirstName(name)}
          , I hope you're enjoying Brodi so far! I'm your Account Manger, Jonathan!
        </Typography>
        <br />

        <Typography variant="body1" style={{ fontWeight: 700 }}>
          About Your Free Trial
        </Typography>
        <Typography variant="body1">
          On the Free Trial plan, you can add as many Apps and invite as many of your team members as you like.
        </Typography>
        <br />

        <Typography variant="body1">
          Your trial has
          {' '}
          <span style={{ fontWeight: 600 }}>
            {days}
            {' '}
            days remaining
          </span>
          .
          {' '}
          If you need some more time, no problem. Just let me know!
        </Typography>

        <AvatarContainer>
          <div>
            <Avatar src={JKImg} />
          </div>
          <div>
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              Jonathan K. (Your Account Manager)
            </Typography>
            <Typography variant="body1">
              Email: jonathan@brodi.io
              {' '}
              <a href="mailto:jonathan@brodi.io">(Email Me)</a>
            </Typography>

          </div>
        </AvatarContainer>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default TrialDialog;

const Avatar = styled.img`
  border-radius: 50%;
  background-color: #FFF;
  overflow: hidden;
  object-fit: cover;
  width: 70px;
  height: 70px;
  box-shadow: 0px 2px 3px #AAA;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  > div {
    margin-right: 1rem;
  }
`;
