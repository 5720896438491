import React from 'react';

import {
  Box, Paper, Grid, Fade, Avatar, Typography, makeStyles, Grow,
} from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';
import { labelInitials } from '../../helpers/strings';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

const SimulatedAppPage = ({ animate, name, icon }) => {
  const classes = useStyles();
  return (
    <Grow in timeout={1000}>
      <Paper variant="outlined" square>
        <Box m={5}>
          <Grid container direction="column" spacing={3}>

            <Grid container item spacing={2} alignItems="center">
              <Grid item>
                <Fade in={animate} style={{ transitionDelay: animate ? '500ms' : '0ms' }} timeout={1000}>
                  <Avatar className={classes.large} src={icon}>{labelInitials(name)}</Avatar>
                </Fade>
              </Grid>
              <Grid item>
                <Fade in={animate} style={{ transitionDelay: animate ? '700ms' : '0ms' }} timeout={1000}>
                  <Typography variant="h4">{name}</Typography>
                </Fade>
              </Grid>
            </Grid>

            <Grid item>
              <Fade in={animate} style={{ transitionDelay: animate ? '800ms' : '0ms' }} timeout={1000}>
                <Skeleton animation={false} />
              </Fade>
            </Grid>
            <Grid item>
              <Fade in={animate} style={{ transitionDelay: animate ? '900ms' : '0ms' }} timeout={1000}>
                <Skeleton animation={false} />
              </Fade>
            </Grid>
            <Grid item>
              <Fade in={animate} style={{ transitionDelay: animate ? '1000ms' : '0ms' }} timeout={1000}>
                <Skeleton animation={false} />
              </Fade>
            </Grid>
            <Grid item>
              <Fade in={animate} style={{ transitionDelay: animate ? '1100ms' : '0ms' }} timeout={1000}>
                <Skeleton animation={false} />
              </Fade>
            </Grid>
            <Grid item>
              <Fade in={animate} style={{ transitionDelay: animate ? '1200ms' : '0ms' }} timeout={1000}>
                <Skeleton animation={false} />
              </Fade>
            </Grid>
            <Grid item>
              <Fade in={animate} style={{ transitionDelay: animate ? '1300ms' : '0ms' }} timeout={1000}>
                <Skeleton animation={false} />
              </Fade>
            </Grid>
            <Grid item>
              <Fade in={animate} style={{ transitionDelay: animate ? '1400ms' : '0ms' }} timeout={1000}>
                <Skeleton animation={false} />
              </Fade>
            </Grid>

          </Grid>
        </Box>
      </Paper>
    </Grow>
  );
};

export default SimulatedAppPage;
