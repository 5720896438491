import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import FallbackAvatar from '../../images/FallbackAvatar.svg';

const OverlappingAvatarGroup = ({ userIds = [] }) => {
  const [avatars, setAvatars] = useState([]);
  const allUsers = useSelector((store) => store.users.cachedUsers.data);

  useEffect(() => {
    const tmpObjects = [];
    userIds.forEach(({ id }) => {
      if (allUsers[id]) tmpObjects.push(allUsers[id]?.avatar);
    });

    // Save max 5
    setAvatars(tmpObjects.slice(0, 5));
  }, [userIds, allUsers]);

  return (
    <AvatarContainer>
      {avatars.map((a) => {
        if (a) return <Avatar key={JSON.stringify(a)} img={a} />;
        return <Avatar key={JSON.stringify(a)} img={FallbackAvatar} />;
      })}
    </AvatarContainer>
  );
};

export default OverlappingAvatarGroup;

const AvatarContainer = styled.div`
  display: inline-flex;
  flex-direction: row-reverse;
`;

const Avatar = styled.span`
  position: relative;
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 70px;
  height: 70px;
  box-shadow: 0px 2px 3px #AAA;
  :not(:first-child) {
    margin-left: -30px;
  }
  ${({ img }) => img && `
    background-image: url(${img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; 
  `}
`;
