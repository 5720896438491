import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import { createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import * as serviceWorker from './serviceWorker';

import App from './App';
import rootReducer from './reducers';
import { VERIFY_AUTH_SUCCESS } from './actions/auth';
import firebase from './config/firebase';

const middlewares = [
  thunk,
];

ReactGA.initialize('UA-168896049-1', {
  debug: false,
  titleCase: false,
});

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middlewares),
  ),
);

firebase.auth().onAuthStateChanged((user) => {
  const isAuthenticated = (user !== null);
  store.dispatch({ type: VERIFY_AUTH_SUCCESS, isAuthenticated });
});

window.store = store; // TODO remove in prod

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
