import React, { useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';

import Add from './components/pages/Add';
import AppPage from './components/pages/App';
import Error from './components/pages/Error';
import Home from './components/pages/Home';
import Login from './components/pages/Login';
import Search from './components/pages/Search';
import Settings from './components/pages/Settings';
import UserSearch from './components/pages/Management/UserSearch';
import User from './components/pages/Management/User';
import UserInvite from './components/pages/Management/UserInvite';

import CenteredLayout from './components/templates/CenteredLayout';
import CoreLayout from './components/templates/CoreLayout';
import UnauthenticatedLayout from './components/templates/UnauthenticatedLayout';

import { initializeBaseData, setClientNotReady } from './actions/client';
import AuthAction from './components/pages/AuthAction';
import Company from './components/pages/Management/Company';

import Loading from './images/Loading.svg';
import Insights from './components/pages/Insights';
import VerifySlack from './components/pages/VerifySlack';

let theme;

theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3A4ADE',
    },
    secondary: {
      main: pink[500],
    },
    background: {
      default: '#FFF',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '5.0rem',
      fontWeight: 400,
      letterSpacing: -2,
    },
    h2: {
      fontWeight: 400,
      letterSpacing: -2,
    },
    h3: {
      fontWeight: 800,
      lineHeight: '1.6',
      letterSpacing: -2,
    },
    h4: {
      lineHeight: '1.6',
      fontWeight: 900,
      letterSpacing: -2,
    },
    h5: {
      lineHeight: '1.4',
      fontWeight: 600,
      letterSpacing: -1.5,
    },
    h6: {
      lineHeight: '1.6',
      fontWeight: 600,
      letterSpacing: -0.9,
    },
    body1: {
      fontWeight: 500,
      letterSpacing: -0.5,
    },
    body2: {
      fontWeight: 500,
      letterSpacing: -0.5,
    },
    button: {
      fontWeight: 700,
    },
  },
});

theme = responsiveFontSizes(theme);

const App = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((store) => store.auth.data.isAuthenticated);
  const isReady = useSelector((store) => store.client.data.isReady);
  const userRole = useSelector((state) => state.users.currentUser.data.role);
  const [clientError, setClientError] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(initializeBaseData())
        .catch(() => {
          setClientError(true);
        });
    }
  }, [dispatch, isAuthenticated]);

  const authRenderFlow = (children, location, admin) => {
    if (admin && userRole < 10) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { from: location },
          }}
        />
      );
    }
    if (clientError) return (<Error type={400} message="(We have notified the team that you had this problem)" />);
    if (isAuthenticated) {
      if (isReady) return children;
      return (
        <CenteredLayout>
          <img src={Loading} alt="loading" />
        </CenteredLayout>
      );
    }
    dispatch(setClientNotReady());
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  };

  const PrivateRoute = ({ children, admin, ...rest }) => (
    <Route
      {...rest}
      render={({ location }) => authRenderFlow(children, location, admin)}
    />
  );


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* REACT ROUTER */}
      <Router>
        <Switch>
          <Route path="/login" exact>
            <UnauthenticatedLayout>
              <Login />
            </UnauthenticatedLayout>
          </Route>

          <Route path="/auth-action" exact>
            <AuthAction />
          </Route>

          <Route path="/verify-slack" exact>
            <VerifySlack />
          </Route>

          <PrivateRoute path="/" exact>
            <CoreLayout>
              <Home />
            </CoreLayout>
          </PrivateRoute>

          <PrivateRoute path="/insights" exact>
            <CoreLayout>
              <Insights />
            </CoreLayout>
          </PrivateRoute>

          <PrivateRoute path="/search" exact>
            <CoreLayout>
              <Search />
            </CoreLayout>
          </PrivateRoute>

          <PrivateRoute path="/settings" exact>
            <CoreLayout>
              <Settings />
            </CoreLayout>
          </PrivateRoute>

          <Redirect from="/add/" to="/add/0" exact />
          <PrivateRoute path="/add/:formStep" exact>
            <CoreLayout>
              <Add />
            </CoreLayout>
          </PrivateRoute>

          <PrivateRoute path="/app/:appId/:appName" exact>
            <CoreLayout>
              <AppPage />
            </CoreLayout>
          </PrivateRoute>

          <PrivateRoute path="/manage/users/search" admin exact>
            <CoreLayout>
              <UserSearch />
            </CoreLayout>
          </PrivateRoute>

          <PrivateRoute path="/manage/users/invite" admin exact>
            <CoreLayout>
              <UserInvite />
            </CoreLayout>
          </PrivateRoute>

          <PrivateRoute path="/manage/user/:userId" admin exact>
            <CoreLayout>
              <User />
            </CoreLayout>
          </PrivateRoute>

          <PrivateRoute path="/manage/company" admin exact>
            <CoreLayout>
              <Company />
            </CoreLayout>
          </PrivateRoute>

          <CoreLayout>
            <Error type={404} />
          </CoreLayout>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

export default App;
