import React, { useState } from 'react';

import { Typography, Button } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { INTERNAL_SUPPORT } from '../../../config/constants';

import AvatarWithLabelLink from '../../atoms/AvatarWithLabelLink';
import OverlappingAvatarGroup from '../../atoms/OverlappingAvatarGroup';
import CollapsableItemPanel from '../../organisms/CollapsableItemPanel';
import EditContacts from '../AppPageEditDialogs/EditContacts';
import ContactListDialog from '../ContactsListDialog';
import { getApp } from '../../../actions/apps';

const Contacts = ({
  data, appId, contacts,
}) => {
  const dispatch = useDispatch();
  const [openContacts, setOpenContacts] = useState(false);
  const handleContactsDialog = () => {
    dispatch(getApp(appId, true)).then(() => {
      setOpenContacts(!openContacts);
    });
  };

  const [openList, setOpenList] = useState(false);
  const [listData, setListData] = useState([]);

  const handleListDialog = (ld = []) => {
    setListData(ld);
    setOpenList(!openList);
  };

  const { displayName, contactGroups } = useSelector((store) => store.company.data);
  const users = useSelector((store) => store.users.cachedUsers.data);

  const getContactGroupName = (groupId) => contactGroups.find((x) => x.id === groupId);

  const generateContactsTeaserText = (userIdArr = []) => {
    const totalInGroup = userIdArr.length;
    const totalToTease = 5;
    const remainingInGroup = totalInGroup - totalToTease;

    let returnStr = '';

    returnStr += userIdArr.slice(0, totalToTease).map((u) => u.id).reduce((str, id, curIndex) => {
      if (!users[id]) return '';

      let tmpStr = '';
      tmpStr += `${str} ${users[id]?.name || ''}`;
      if ((curIndex + 1) !== totalInGroup) tmpStr += ', ';
      return tmpStr;
    }, '');

    if (remainingInGroup > 0) returnStr += ` and ${remainingInGroup} more...`;

    return returnStr;
  };

  const sourceText = () => ({
    heading: `Contacts at ${displayName}`,
    editHeading: `Edit Contacts at ${displayName}`,
    tooltip: `Edit ${displayName} Contacts`,
  });

  const menuItems = [
    {
      label: sourceText().tooltip,
      action: handleContactsDialog,
    },
  ];

  return (
    <CollapsableItemPanel
      heading={sourceText().heading}
      menuItems={menuItems}
      key={sourceText().heading}
    >
      {!data[INTERNAL_SUPPORT] && (
      <EmptyStateContainer>
        <Typography align="center" variant="body1" color="textSecondary">
          {`No contacts at ${displayName} to show`}
        </Typography>
        <Button size="small" onClick={handleContactsDialog} color="primary">Add Contacts</Button>
      </EmptyStateContainer>
      )}

      {data[INTERNAL_SUPPORT] && (
        Object.keys(data[INTERNAL_SUPPORT]).map((contactType) => (
          <ContactTypeSection key={contactType}>
            <ContactTypeBubbles>
              <AvatarsContainer>
                <OverlappingAvatarGroup
                  userIds={
                    contacts?.filter(
                      ({ source: rawSource, group: rawGroup }) => (
                        rawSource === INTERNAL_SUPPORT
                        && rawGroup === contactType
                      ),
                    )
                  }
                />
                {data[INTERNAL_SUPPORT][contactType].map((contact) => (
                  <AvatarWithLabelLink
                    data={contact.id}
                    key={contact.id}
                    collapsed
                  />
                ))}
                <div />
              </AvatarsContainer>
            </ContactTypeBubbles>
            <ContactTypeText>
              <Typography variant="body1" style={{ fontWeight: 600 }}>
                {contactType.name}
                {getContactGroupName(contactType)?.name}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {generateContactsTeaserText(data[INTERNAL_SUPPORT][contactType])}
              </Typography>
              <Button
                size="small"
                onClick={() => handleListDialog(data[INTERNAL_SUPPORT][contactType]?.map((contact) => contact.id))}
              >
                {`See All ${getContactGroupName(contactType)?.name}`}
              </Button>
            </ContactTypeText>
          </ContactTypeSection>
        ))
      )}

      {/* Dialog */}
      <EditContacts
        handleContactsDialog={handleContactsDialog}
        openContacts={openContacts}
        data={data}
        source={INTERNAL_SUPPORT}
        heading={sourceText().editHeading}
        appId={appId}
        contacts={contacts}
      />

      <ContactListDialog
        handleListDialog={() => handleListDialog([])}
        openList={openList}
        contactIdsArray={listData}
      />

    </CollapsableItemPanel>
  );
};
export default Contacts;

const AvatarsContainer = styled.div`
  direction: rtl;
  text-align: left;
  padding-left: 25px;
  padding-top: 10px;
`;

const ContactTypeSection = styled.div`
  margin-bottom: 1.5rem;
  padding: 2.0rem;
  background-color: #fbfbfb;
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

const ContactTypeBubbles = styled.div`
  margin-right: 2rem;
`;
const ContactTypeText = styled.div`
`;

const EmptyStateContainer = styled.div`
  text-align: center;
  margin: 2rem auto;
`;
