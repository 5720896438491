import React, { useEffect } from 'react';
import { DateTime } from 'luxon';

import {
  Grid, Typography, Tooltip,
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { convertTimestampToDate } from '../../../helpers/dates';

import CollapsableItemPanel from '../../organisms/CollapsableItemPanel';
import LeadingTitleText from '../../atoms/LeadingTitleText';

import { getSingleUser } from '../../../actions/users';

const Housekeeping = ({
  created,
  modernUpdateStamp,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((store) => store.users.cachedUsers.data[modernUpdateStamp?.updateBy]);

  useEffect(() => {
    if (modernUpdateStamp) dispatch(getSingleUser(modernUpdateStamp.updateBy));
  }, [modernUpdateStamp, dispatch]);

  return (
    <CollapsableItemPanel heading="Housekeeping" key="Housekeeping">
      <Grid container spacing={5}>
        <Grid item>
          <LeadingTitleText heading="Last Updated">

            {(modernUpdateStamp && (
            <Tooltip title={
              DateTime.fromSeconds(modernUpdateStamp.updateTime._seconds - 2).toLocaleString(DateTime.DATETIME_HUGE_WITH_SECONDS)
            }>
              <Typography variant="body2">
                {DateTime.fromSeconds(modernUpdateStamp.updateTime._seconds - 2).toRelative()}
                {' '}
                by
                {' '}
                {user?.name}
              </Typography>
            </Tooltip>
            ))}

            {!modernUpdateStamp && (
            <Typography variant="body2" color="textSecondary">
              No Updates Yet
            </Typography>
            )}

          </LeadingTitleText>
        </Grid>
        <Grid item>
          <LeadingTitleText heading="Added">
            <Tooltip title={convertTimestampToDate(created, true)}>
              <Typography variant="body2">
                {DateTime.fromSeconds(created._seconds).toLocaleString(DateTime.DATETIME_FULL)}
              </Typography>
            </Tooltip>
          </LeadingTitleText>
        </Grid>
      </Grid>
    </CollapsableItemPanel>
  );
};

export default Housekeeping;
