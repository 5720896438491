import React from 'react';

import {
  Typography, Grid, makeStyles, Card, CardActionArea, CardContent, Fade, Box, Avatar,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { truncate, labelInitials } from '../../helpers/strings';

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: '240px',
    maxWidth: '240px',
  },
  grid: {
    minHeight: '120px',
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

const ProfileCard = ({
  name, id, icon, email, onboarding, disabled,
}) => {
  const classes = useStyles();

  const renderStatus = () => {
    if (disabled) {
      return (
        <Typography align="center" variant="body2" component="div" color="textSecondary">
          Disabled
        </Typography>
      );
    }
    if (!onboarding?.fromAdminInvite || !onboarding?.fromFreeTrial) {
      return (
        <Typography align="center" variant="body2" component="div" color="textSecondary">
          Active
        </Typography>
      );
    }
    return (
      <Typography align="center" variant="body2" component="div" color="textSecondary">
        Not Onboarded
      </Typography>
    );
  };

  if (id) {
    return (
      <Fade in={Boolean(id)} timeout={500}>
        <Card className={classes.card} elevation={1}>
          <CardActionArea component={Link} to={`/manage/user/${id}`}>
            <CardContent>
              <Box my={1}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  className={classes.grid}
                  spacing={2}
                >
                  <Grid item>
                    <Avatar className={classes.avatar} src={icon}>{labelInitials(name)}</Avatar>
                  </Grid>

                  <Grid item>
                    <Typography align="center" variant="h6">
                      {name && truncate(name, 30)}
                    </Typography>

                    <Typography component="div" variant="body2" color="textSecondary" align="center">
                      {email && truncate(email, 25)}
                    </Typography>

                    {renderStatus()}
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      </Fade>
    );
  }
  return null;
};

export default ProfileCard;
