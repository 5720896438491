import React from 'react';
import Select from 'react-select';

const OwnerSearchRefinement = ({
  items, refine, searchState,
}) => (
  <Select
    isMulti
    placeholder="All Teams"
    noOptionsMessage={() => 'No Teams for this Search'}
    options={items.filter(({ isRefined = false }) => isRefined === false)}
    getOptionValue={(option) => option.label}
    getOptionLabel={(option) => `${option.label} ${(option.count > 0) ? `(${option.count})` : ''}`}
    name="owner"
    defaultValue={searchState.map((i) => ({ label: i }))}
    filterOption={(options) => options}
    onChange={(v) => {
      if (v) refine(v.map(({ label }) => label));
      else refine([]);
    }}
  />
);

export default OwnerSearchRefinement;
