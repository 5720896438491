import React, { useState } from 'react';

import styled from 'styled-components';
import { Typography, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CollapsableItemPanel from '../../organisms/CollapsableItemPanel';
import LabledLink from '../../atoms/LabledLink';
import EditLinks from '../AppPageEditDialogs/EditLinks';
import { getApp } from '../../../actions/apps';

const Links = ({
  data,
  appId,
}) => {
  const dispatch = useDispatch();
  const [openLinks, setOpenLinks] = useState(false);
  const handleLinksDialog = () => {
    dispatch(getApp(appId, true)).then(() => {
      setOpenLinks(!openLinks);
    });
  };

  const menuItems = [
    {
      label: 'Edit Links',
      action: handleLinksDialog,
    },
  ];

  return (
    <CollapsableItemPanel heading="Links" menuItems={menuItems} key="links">

      {!data.length && (
        <EmptyStateContainer>
          <Typography align="center" variant="body1" color="textSecondary">
            No Links
          </Typography>
          <Button size="small" onClick={handleLinksDialog} color="primary">Add a Link</Button>
        </EmptyStateContainer>
      )}

      {data.map((link) => (
        <LabledLink
          key={`${link.displayName}-${link.url}`}
          label={link.displayName}
          url={link.url}
        />
      ))}

      <EditLinks
        appId={appId}
        data={data}
        handleLinksDialog={handleLinksDialog}
        openLinks={openLinks}
      />
    </CollapsableItemPanel>
  );
};

export default Links;

const EmptyStateContainer = styled.div`
  text-align: center;
  margin: 2rem auto;
`;
