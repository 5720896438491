export const convertTimestampToDate = (dt, time) => {
  if (dt) {
    const date = new Date(dt._seconds * 1000);
    if (time) return date.toLocaleString();
    return date.toLocaleDateString();
  }
  return '--';
};

export const getTimeDifference = (dt1, dt2) => Math.abs(dt1 - dt2) / 36e5;

export const getGreeting = () => {
  const today = new Date();
  const curHr = today.getHours();

  if (curHr < 12) return 'Good Morning';
  if (curHr < 18) return 'Good Afternoon';
  return 'Good Evening';
};
