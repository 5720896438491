import Company from '../models/company';
import {
  GET_COMPANY_INFO_SUCCESS,
} from '../actions/company';

const defaultCompany = new Company();

const initialState = {
  data: {
    ...defaultCompany,
  },
};

const company = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY_INFO_SUCCESS:
      return {
        data: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default company;
