import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress, TextField,
  Typography,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { sendClientFeedback } from '../../actions/company'

const FeedbackDialog = ({
  handleDialog, dialogOpen,
}) => {
  const location = useLocation();
  const { companyId, email } = useSelector((store) => store.users.currentUser.data);

  const sendFeedback = async ({ feedback }) => {
    const payload = {
      feedback,
      companyId,
      email,
      pathname: location.pathname,
    };

    await sendClientFeedback(payload);
    handleDialog();
  };

  const {
    handleSubmit, register, errors, formState,
  } = useForm();

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleDialog}
      aria-labelledby="form-dialog-title"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(sendFeedback)}>
        {
          (formState.isSubmitting) && (
            <LinearProgress />
          )
        }
        <DialogContent>
          <Typography variant="h6">
            Submit Feedback
          </Typography>
          <Typography variant="body1">
            Send us a bug report or a new feature request
          </Typography>

          <br />

          <TextField
            inputRef={register({ required: true, maxLength: 1000 })}
            variant="outlined"
            label="Your Feedback"
            helperText={errors.feedback && '1000 characters or less'}
            size="medium"
            multiline
            rows="3"
            name="feedback"
            error={Boolean(errors.feedback)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialog}>
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Send
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default FeedbackDialog;
