import React, { useState } from 'react';

import {
  Typography, Avatar, Menu, MenuItem, IconButton, makeStyles, Chip,
} from '@material-ui/core';

import styled from 'styled-components';

import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { labelInitials } from '../../helpers/strings';
import { logout } from '../../actions/auth';
import { showErrorSnackbar } from '../../actions/snackbar';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const NavBarAccountMenu = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState();
  const companyName = useSelector((state) => state.company.data.displayName);
  const userName = useSelector((state) => state.users.currentUser.data.name);
  const userAvatar = useSelector((state) => state.users.currentUser.data.avatar);
  const userRole = useSelector((state) => state.users.currentUser.data.role);

  const handleClose = () => {
    setAnchorEl();
  };

  const handleLogoutClick = () => {
    dispatch(logout())
      .catch(() => {
        dispatch(showErrorSnackbar('There was a problem logging out.'));
      });
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleAvatarClick}
        color="default"
        size="small"
      >
        <Avatar className={classes.avatar} src={userAvatar}>{labelInitials(userName)}</Avatar>
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component={Link} to="/settings" onClick={handleClose}>
          <HeaderSection>
            <Avatar src={userAvatar}>
              {labelInitials(userName)}
            </Avatar>
            <Typography component="div" variant="h6"><strong>{userName}</strong></Typography>
            <Typography component="div" variant="body1" gutterBottom>{companyName}</Typography>
            {userRole >= 10 && (
              <Chip component="div" clickable color="secondary" size="small" label="Admin" />
            )}
          </HeaderSection>
        </MenuItem>

        <Section borderTop borderBottom>
          <MenuItem component={Link} to="/settings" onClick={handleClose}>Edit Profile</MenuItem>
        </Section>

        {userRole >= 10 && (
          <Section>
            <MenuItem component={Link} to="/manage/users/search" onClick={handleClose}>Manage Users</MenuItem>
            <MenuItem component={Link} to="/manage/company" onClick={handleClose}>Manage Company</MenuItem>
          </Section>
        )}

        <Section borderTop>
          <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
        </Section>

      </Menu>
    </>
  );
};

export default NavBarAccountMenu;

const HeaderSection = styled.div`
  padding: 1rem 2rem 1rem .5rem;
  `;

const Section = styled.div`
  padding: .5rem 0.5rem .5rem .5rem;
  ${({ borderTop }) => borderTop && 'border-top: 1px solid #eee;'}
  ${({ borderBottom }) => borderBottom && 'border-bottom: 1px solid #eee;'}
`;
