import React, { useEffect, useState } from 'react';
import {
  Typography, Button,
} from '@material-ui/core';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { getFirstName } from '../../helpers/strings';
import { getGreeting } from '../../helpers/dates';

import AppIcon from '../atoms/AppIcon';
import AppInfoRow from '../atoms/AppInfoRow';
import PinnedApp from '../atoms/PinnedApp';

import { getPinnedApps, getUnhealthyApps } from '../../actions/apps'
import FadingLoader from '../loaders/app-row-loader';
import { BreakPoints } from '../../config/theme'

const Home = () => {
  const history = useHistory();

  const [unhealthyApps, setUnhealthyApps] = useState({
    loading: true,
    data: [],
  });

  const [pinnedApps, setPinnedApps] = useState({
    loading: true,
    data: [],
  });

  const { name: userName, followingPreferences } = useSelector((store) => store.users.currentUser.data);

  useEffect(() => {
    (async () => {
      setUnhealthyApps({
        loading: false,
        data: await getUnhealthyApps(),
      });

      setPinnedApps({
        loading: false,
        data: await getPinnedApps(),
      });
    })();
  }, []);

  return (
    <>
      <Header>
        <WelcomeText>
          <Typography variant="h4">
            {`${getGreeting()}, ${getFirstName(userName)}!`}
          </Typography>
          <Typography variant="h5">What can we help you find today?</Typography>
        </WelcomeText>
        {!pinnedApps.loading && (
          <PinnedAppsContainer>
            {pinnedApps.data.slice(0, 4).map((app) => (
              <PinnedApp
                name={app.name}
                icon={app.icon}
                id={app.id}
                key={app.id}
                onClickEvent={() => history.push(`/app/${app.id}/${app.name}`)}
              />
            ))}
          </PinnedAppsContainer>
        )}
      </Header>

      <SectionsContainer>

        {/* FAVORITE APPS */}
        <Section>
          {!!followingPreferences.length && (
            <>
              <Typography gutterBottom align="center" variant="h5" style={{ fontWeight: 800 }}>Your Favorites</Typography>
              <AppsContainer>
                {followingPreferences.map(({ appId }) => (
                  <div key={appId}>
                    <AppIcon appId={appId} onClickEvent={() => history.push(`/app/${appId}/0`)} />
                  </div>
                ))}
              </AppsContainer>
            </>
          )}
          {(!followingPreferences.length) && (
            <EmptyStateContainer>
              <Typography align="center" variant="h5" style={{ fontWeight: 800 }}>You don't have any favorite apps</Typography>
              <Typography align="center" variant="body1">When you favorite apps they'll appear here.</Typography>

              <Button className="cta" component={Link} to="/search" variant="outlined" color="secondary">Find Apps to Favorite</Button>
            </EmptyStateContainer>
          )}
        </Section>

        {/* ATTENTION APPS */}
        <Section>

          {unhealthyApps.loading && (
          <LoadingStateContainer>
            <FadingLoader />
          </LoadingStateContainer>
          )}

          {(!!unhealthyApps.data.length && !unhealthyApps.loading) && (
            <>
              <Typography gutterBottom align="center" variant="h5" style={{ fontWeight: 800 }}>For Your Information</Typography>
              <AppsContainer direction="column">
                {unhealthyApps.data.map(({
                  id, name, iconUrl: icon, updated, status,
                }) => (
                  <div>
                    <AppInfoRow
                      appId={id}
                      payload={{
                        name, icon, availability: { status, updated },
                      }}
                      onClickEvent={() => history.push(`/app/${id}/${name}`)}
                    />
                  </div>
                ))}
              </AppsContainer>
            </>
          )}
          {(!unhealthyApps.data.length && !unhealthyApps.loading) && (
            <EmptyStateContainer>
              <Typography align="center" variant="h5" style={{ fontWeight: 800 }}>All Caught Up</Typography>
              <Typography align="center" variant="body1">No apps require your attention at the moment.</Typography>
            </EmptyStateContainer>
          )}
        </Section>
      </SectionsContainer>
    </>
  );
};

export default Home;

const EmptyStateContainer = styled.div`
  margin: 2rem;
  padding: 2rem;
  text-align: center;
  .cta {
    margin-top: 2rem;
  }
`;

const LoadingStateContainer = styled.div`
  margin: 2rem;
`;

const Header = styled.div`
  padding: 10rem;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${BreakPoints.mobileS} {
    padding: 3rem;
  }
  @media ${BreakPoints.desktopS} {
    padding: 4rem;
  }
  @media ${BreakPoints.desktopM} {
    padding: 6rem;
  }
`;

const WelcomeText = styled.div``;
const PinnedAppsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  > div {
    margin-right: 2rem;
  }
`;

const AppsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: 400px;
  max-width: 600px;
  align-items: stretch;
  ${({ direction }) => direction && `flex-direction: ${direction};`}
  > div {
    margin: .5rem;
  }
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2rem;
`;

const Section = styled.div`
  background-color: #fafafa;
  padding: 3rem 2rem;
  border-radius: 25px;
  margin: 2rem;
  max-width: 500px;
`;
