import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import SingleStatLoader from '../loaders/single-stat-loader';

const SingleStat = ({
  header,
  stat,
  context,
  contextTheme = 'neutral',
  caption,
  loading = true,
}) => {
  if (loading) return <SingleStatLoader />;
  return (
    <SingleStatContainer>
      <SingleStatHeader>
        <Typography variant="caption" style={{ fontWeight: 700 }}>
          {header}
        </Typography>
      </SingleStatHeader>
      <SingleStatContentContainer>
        <SingleStatContent>
          <Typography variant="h4">
            {stat}
          </Typography>
        </SingleStatContent>
        <SingleStatComparison>
          <Typography
            variant="body1"
            style={{
              color: contextTheme === 'positive' ? 'green' : 'red',
            }}
          >
            {context}
          </Typography>
        </SingleStatComparison>
      </SingleStatContentContainer>

      {caption && (
        <SingleStatCaption>
          <Typography variant="caption" color="textSecondary">
            {caption}
          </Typography>
        </SingleStatCaption>
      )}
    </SingleStatContainer>
  );
};

export default SingleStat;

const SingleStatContainer = styled.div`
  padding: 3rem;
  background-color: #fafafa;
  border-radius: 25px;
`;

const SingleStatHeader = styled.div``;

const SingleStatContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SingleStatContent = styled.div``;
const SingleStatCaption = styled.div``;
const SingleStatComparison = styled.div`
  margin-left: 3rem;
`;
