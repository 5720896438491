import { v4 as uuidv4 } from 'uuid';
import firebase from '../config/firebase';
import {
  FB_COMPANIES_COLLECTION,
  FB_USER_AVATARS_STORAGE,
  EMAIL,
  PASSWORD_REGEX,
  GET,
  PATCH,
  POST,
} from '../config/constants';
import { reauth, getSlugInfo } from './auth';
import { addFollower, removeFollower } from './apps';
import { brodiUnauthedRequest, brodiAuthedRequest } from '../helpers/backend';

export const GET_SINGLE_USER_INFO_SUCCESS = 'GET_SINGLE_USER_INFO_SUCCESS';
export const GET_CURRENT_USER_INFO_SUCCESS = 'GET_CURRENT_USER_INFO_SUCCESS';

export const changePassword = async (newPassword, oldPassword) => {
  const { currentUser } = firebase.auth();
  const { email } = currentUser;
  await reauth(oldPassword);
  const { authMethod } = await getSlugInfo(email);
  if (authMethod !== EMAIL) throw new Error('Auth Method not email/password');
  const strongRegex = new RegExp(PASSWORD_REGEX);
  if (!strongRegex.test(newPassword)) throw new Error('Password not complex enough');
  await firebase.auth().currentUser.updatePassword(newPassword);
};

export const getCurrentUser = () => async (dispatch) => {
  const { uid } = firebase.auth().currentUser;
  const payload = await brodiAuthedRequest(GET, `/user/${uid}`);
  dispatch({ type: GET_CURRENT_USER_INFO_SUCCESS, payload });
};

export const changePasswordOnboard = async (newPassword) => {
  const { email, uid } = firebase.auth().currentUser;
  const { tenantId, authMethod } = await getSlugInfo(email);
  if (authMethod !== EMAIL) throw new Error('Auth Method not email/password');
  const strongRegex = new RegExp(PASSWORD_REGEX);
  if (!strongRegex.test(newPassword)) throw new Error('Password not complex enough');
  await brodiAuthedRequest(PATCH, `/user/${uid}/change-password`, {
    tenantId,
    password: newPassword,
  });
};

export const resetPasswordRequest = async (email) => {
  if (!email) throw new Error('No email address');
  const { tenantId, authMethod } = await getSlugInfo(email);
  const returnUrl = window.location.href;
  if (authMethod !== EMAIL) throw new Error('Auth Method not email/password');
  await brodiUnauthedRequest(POST, '/user/forgotten-password', {
    email,
    tenantId,
    returnUrl,
  });
};

export const getSingleUser = (userId, forceRefresh = false) => async (
  dispatch,
  getState,
) => {
  const user = getState().users.cachedUsers.data[userId];
  if (user && !forceRefresh) return;
  const data = await brodiAuthedRequest(GET, `/user/${userId}`);
  const payload = { [userId]: data };
  dispatch({ type: GET_SINGLE_USER_INFO_SUCCESS, payload });
};

export const getUsers = (userIds = [], forceRefresh = false) => async () => {
  Promise.all(userIds.map((u) => getSingleUser(u, forceRefresh)));
};

export const requestEmailChange = (newEmail, password) => async (dispatch) => {
  const { email, uid } = firebase.auth().currentUser;
  const { tenantId, authMethod } = await getSlugInfo(email);
  const returnUrl = window.location.href;
  if (authMethod !== EMAIL) throw new Error('Auth Method not email/password');
  await reauth(password);
  await brodiAuthedRequest(PATCH, `/user/${uid}/change-email`, {
    email,
    newEmail,
    tenantId,
    returnUrl,
  });
  await dispatch(getCurrentUser());
};

export const verifyEmailChange = async (oobCode) => {
  await brodiUnauthedRequest(POST, '/user/verify-email', { oobCode });
};

export const toggleFollowApp = (isFollowing, appId, data) => async (
  dispatch,
) => {
  const { uid } = firebase.auth().currentUser;
  await brodiAuthedRequest(PATCH, `/user/${uid}/toggle-follow-app`, {
    isFollowing,
    appId,
    data,
  });
  await dispatch(getCurrentUser());

  if (isFollowing) {
    await dispatch(removeFollower(appId));
  } else {
    await dispatch(addFollower(appId));
  }
};

export const updateRecentApps = (appId) => async (dispatch) => {
  const { uid } = firebase.auth().currentUser;
  await brodiAuthedRequest(PATCH, `/user/${uid}/recent-apps`, { appId });
  await dispatch(getCurrentUser());
};

export const updateOnboarding = (payload) => async (dispatch) => {
  const { uid } = firebase.auth().currentUser;
  await brodiAuthedRequest(PATCH, `/user/${uid}/onboarding`, payload);
  await dispatch(getCurrentUser());
};

export const uploadUserAvatar = (file) => async (dispatch, getState) => {
  const { companyId } = getState().users.currentUser.data;
  const id = uuidv4();
  const extension = file.type.split('/')[1];
  const storageRef = firebase
    .storage()
    .ref(
      `${FB_COMPANIES_COLLECTION}/${companyId}/${FB_USER_AVATARS_STORAGE}/${id}.${extension}`,
    );
  await storageRef.put(file);
  const icon = await storageRef.getDownloadURL();
  return icon;
};

export const updateUserDetails = (preferences) => async (dispatch) => {
  const { uid } = firebase.auth().currentUser;
  await brodiAuthedRequest(PATCH, `/user/${uid}`, { preferences });
  await dispatch(getCurrentUser());
};

export const updateProfilePicture = (payload) => async (dispatch) => {
  const { uid } = firebase.auth().currentUser;
  await brodiAuthedRequest(PATCH, `/user/${uid}/profile-picture`, payload);
  await dispatch(getCurrentUser());
};

export const openSlack = async (id) => brodiAuthedRequest(GET, `/user/${id}/slack-chat-url`);
