import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import styled from 'styled-components';
import {
  Button, Checkbox, Dialog, TextField, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Wave from '../../images/wave.svg';
import { getFirstName } from '../../helpers/strings';
import { PASSWORD_REGEX } from '../../config/constants';
import { changePasswordOnboard, updateOnboarding, updateUserDetails } from '../../actions/users';
import { reauth } from '../../actions/auth';
import GetStructureAutocomplete from '../molecules/GetStructureAutocomplete';
import { structurePassThru } from '../../helpers/form';
import { getCompany } from '../../actions/company';

const InitialSigninFromFreeTrial = ({ open }) => {
  const dispatch = useDispatch();
  const { name, team, companyId } = useSelector((store) => store.users.currentUser.data);

  const {
    displayName, structure, structureChildren,
  } = useSelector((store) => store.company.data);

  const mappedChildren = team
    .map((childId) => structureChildren.find(({ id }) => id === childId));

  const [form, setForm] = useState(0);

  const {
    register: registerPwd,
    handleSubmit: handleSubmitPwd,
    errors: errorsPwd,
    watch: watchPwd,
    formState: formStatePwd,
  } = useForm();

  const {
    control: controlTeam,
    handleSubmit: handleSubmitTeam,
    errors: errorsTeam,
    formState: formStateTeam,
  } = useForm();

  const {
    handleSubmit: handleSubmitFinalize,
    formState: formStateFinalize,
  } = useForm();

  const updatePassword = async ({ newPassword }) => {
    try {
      await changePasswordOnboard(newPassword);
      await reauth(newPassword);
      setForm(2);
    } catch (err) {
      // error
    }
  };

  const updateTeam = async ({ team: formTeam }) => {
    try {
      const newTeam = await structurePassThru(formTeam, companyId);
      await dispatch(updateUserDetails({ team: newTeam }));
      await dispatch(getCompany());
      setForm(3);
    } catch (err) {
      // error
    }
  };

  const completeOnboarding = async () => {
    try {
      await dispatch(updateOnboarding({ fromAdminInvite: false }));
    } catch (err) {
      // error
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <ContentRow>
        <Left>
          <div>
            <img alt="waving hand" style={{ opacity: 0.15 }} src={Wave} />
          </div>
        </Left>

        <AnimatePresence exitBeforeEnter>
          {form === 0 && (
          <motion.div
            key="welcome"
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 0, opacity: 0 }}
            style={{ display: 'flex', flex: 1 }}
          >
            <BodyAction>
              <Headers>
                <Typography variant="h4">
                  Welcome to Brodi,
                  {' '}
                  {getFirstName(name)}
                  !
                </Typography>
                <Typography variant="h6">
                  {displayName}
                  {' '}
                  uses Brodi to organize the Apps you and your team use every day
                </Typography>
              </Headers>

              <div className="content">
                <Typography variant="body1" style={{ fontWeight: 600 }} gutterBottom>
                  To get started, let's take care of two quick things:
                </Typography>

                <FieldRow>
                  <Checkbox disabled />
                  <Typography component="div" variant="body1" ss>
                    Choose a new password
                  </Typography>
                </FieldRow>

                <FieldRow>
                  <Checkbox disabled />
                  <Typography component="div" variant="body1" ss>
                    Select your Department & Team
                  </Typography>
                </FieldRow>
              </div>
              <ActionRow>
                <Typography variant="body1" component="div">
                  Step
                  {' '}
                  {form + 1}
                  {' '}
                  of 3
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  size="large"
                  onClick={() => setForm(1)}
                >
                  Next
                </Button>
              </ActionRow>
            </BodyAction>
          </motion.div>
          )}

          {form === 1 && (
          <motion.div
            key="password"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ display: 'flex', flex: 1 }}
          >
            <form onSubmit={handleSubmitPwd(updatePassword)} style={{ display: 'flex', flex: 1 }}>
              <BodyAction>
                <Headers>
                  <Typography variant="h4">
                    Choose a New Password
                  </Typography>
                  <Typography variant="body1">
                    You'll use this password when you next sign in to Brodi
                  </Typography>
                </Headers>

                <div className="content">
                  <FieldRow mb>
                    <TextField
                      label="New Password"
                      name="newPassword"
                      id="new-password"
                      autoComplete="new-password"
                      variant="outlined"
                      type="password"
                      error={Boolean(errorsPwd.newPassword)}
                      helperText="At least eight characters. A mix of uppercase, lowercase, symbols and numbers."
                      inputRef={registerPwd({ required: true, pattern: new RegExp(PASSWORD_REGEX) })}
                      fullWidth
                    />
                  </FieldRow>

                  <FieldRow mb>
                    <TextField
                      label="New Password Again"
                      name="newPasswordVerify"
                      id="new-password"
                      autoComplete="new-password"
                      type="password"
                      variant="outlined"
                      error={Boolean(errorsPwd.newPasswordVerify)}
                      helperText={errorsPwd.newPasswordVerify ? "Those passwords didn't match" : ''}
                      inputRef={registerPwd({
                        required: true,
                        validate: (value) => value === watchPwd('newPassword'),
                      })}
                      fullWidth
                    />
                  </FieldRow>

                </div>
                <ActionRow>
                  <Typography variant="body1" component="div">
                    Step
                    {' '}
                    {form + 1}
                    {' '}
                    of 3
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={!formStatePwd.dirty || formStatePwd.isSubmitting}
                    size="large"
                    type="submit"
                  >
                    {formStatePwd.isSubmitting ? 'SAVING...' : 'Next'}
                  </Button>
                </ActionRow>
              </BodyAction>
            </form>
          </motion.div>
          )}

          {form === 2 && (
          <motion.div
            key="team"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ display: 'flex', flex: 1 }}
          >
            <form onSubmit={handleSubmitTeam(updateTeam)} style={{ display: 'flex', flex: 1 }}>
              <BodyAction>
                <Headers>
                  <Typography variant="h4">
                    Find your team
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Add your team to personalize Brodi and stay informed about important apps in and around your team
                  </Typography>
                </Headers>

                <div className="content">
                  {structure.filter(({ status }) => status !== 'DELETED').map((s) => (
                    <FieldRow mb key={s.id}>
                      <GetStructureAutocomplete
                        errors={errorsTeam}
                        structureItem={s}
                        control={controlTeam}
                        name={`team[${s.id}]`}
                        defaultValue={mappedChildren.find((mC = {}) => mC.parentId === s.id)}
                      />
                    </FieldRow>
                  ))}
                </div>

                <ActionRow>
                  <Typography variant="body1" component="div">
                    Step
                    {' '}
                    {form + 1}
                    {' '}
                    of 3
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={!formStateTeam.dirty}
                    size="large"
                    type="submit"
                    onClick={handleSubmitTeam(updateTeam)}
                  >
                    {formStateTeam.isSubmitting ? 'SAVING...' : 'Next'}
                  </Button>
                </ActionRow>
              </BodyAction>
            </form>
          </motion.div>
          )}

          {form === 3 && (
          <motion.div
            key="done"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ display: 'flex', flex: 1 }}
          >
            <form onSubmit={handleSubmitFinalize(completeOnboarding)} style={{ display: 'flex', flex: 1 }}>
              <BodyAction>
                <Headers>
                  <Typography variant="h4">
                    You're all set!
                  </Typography>
                  <Typography variant="body1">
                    We've setup your new profile and you're ready to go.
                  </Typography>
                </Headers>

                <div className="content">
                  &nbsp;
                </div>
                <ActionRow>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={formStateFinalize.isSubmitting}
                    size="large"
                    type="submit"
                    onClick={handleSubmitFinalize(completeOnboarding)}
                  >
                    {formStateFinalize.isSubmitting ? 'SAVING...' : 'Finish'}
                  </Button>
                </ActionRow>
              </BodyAction>
            </form>
          </motion.div>
          )}
        </AnimatePresence>
      </ContentRow>
    </Dialog>
  );
};
export default InitialSigninFromFreeTrial;

const ContentRow = styled.div`
  display: flex;
  > div {
    :first-child {
      flex-basis: 35%;
    }
    :last-child {
      flex-basis: 65%;
    }
  }
`;

const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > div {
    :not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

const Left = styled.div`
  background-color: #3A4ADE;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8rem 0;
  z-index: 10;
`;

const BodyAction = styled.div`
  background-color: #FAFAFA;
  padding: 2rem;
  z-index: 5;
  flex: 1;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
  }
`;

const Headers = styled.div`
  margin-bottom: 1rem;
`;

const FieldRow = styled.div`
  display: flex;
  align-items: center;

  ${((props) => props.mb && 'margin-bottom: 1rem;')}

  > div {
    :not(:last-child) {
      margin-right: 1rem;
    }
  }
`;
