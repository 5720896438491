import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getSingleUser } from '../../actions/users';
import FallbackAvatar from '../../images/FallbackAvatar.svg';

const SimpleAvatar = ({
  userId,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.users.cachedUsers.data[userId]);

  useEffect(() => {
    if (userId) dispatch(getSingleUser(userId)).catch((e) => console.log('Error'));
  }, [userId, dispatch]);

  return (
    <AvatarContainer>
      <Avatar src={user?.avatar || FallbackAvatar} />
    </AvatarContainer>
  );
};

export default SimpleAvatar;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 0rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  &:hover {
    background-color: #FAFAFA;
    font-weight: 500;
    div {
      display: block;
    }
  };
`;

const Avatar = styled.img`
  border-radius: 50%;
  background-color: #FFF;
  overflow: hidden;
  object-fit: cover;
  width: 50px;
  height: 50px;
  box-shadow: 0px 2px 3px #AAA;
`;
