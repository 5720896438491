export default class App {
  constructor(
    category = '',
    description = '',
    firstParty = false,
    icon = '',
    name = '',
    url = '',
    links = [],
    customFields = [],
    contacts = [],
    contracts = [],
    owner = [],
    created = new Date(),
  ) {
    this.category = category;
    this.description = description;
    this.firstParty = firstParty;
    this.icon = icon;
    this.name = name;
    this.url = url;
    this.links = links;
    this.customFields = customFields;
    this.contacts = contacts;
    this.contracts = contracts;
    this.owner = owner;
    this.created = created;
  }
}
