import React from 'react';

import {
  Grid, Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import ProgressButton from '../atoms/ProgressButton';

import { getPrevFormStep } from '../../helpers/form';

const FormProgressionButtons = ({
  buttonLoading, forwardCta, children, formStep, backwardClick
}) => {
  const history = useHistory();

  const goBack = () => {
    if (backwardClick) backwardClick();
    else history.push(`/add/${getPrevFormStep(formStep)}`);
  };

  return (
    <Grid container item spacing={2}>
      <Grid item>
        <Button
          variant="contained"
          disableElevation
          onClick={goBack}
          color="default"
          size="large"
        >
          Go Back
        </Button>
      </Grid>
      {children && (
        <Grid item>
          {children}
        </Grid>
      )}
      <Grid item>
        <ProgressButton
          loading={buttonLoading}
          cta={forwardCta || 'CONTINUE'}
        />
      </Grid>
    </Grid>
  );
};

export default FormProgressionButtons;
