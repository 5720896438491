import React, { useState } from 'react';
import {
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  LinearProgress,
  Typography,
  Box,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { showSnackbar } from '../../actions/snackbar';
import { pinApp, unpinApp } from '../../actions/apps';

const PinAppDialog = ({
  appId,
  pinAppDialogOpen,
  setPinAppDialogOpen,
  isPinned,
  name,
}) => {
  const [manualError, setManualError] = useState(false);
  const [saving, setSaving] = useState(false);
  const {
    handleSubmit,
  } = useForm();
  const dispatch = useDispatch();

  const handlePin = () => {
    setSaving(true);
    setManualError(false);

    if (isPinned) {
      unpinApp(appId)
        .then(() => {
          setSaving(false);
          dispatch(showSnackbar(`Unpinned ${name} for Everyone`));
          setPinAppDialogOpen();
        })
        .catch(() => {
          setSaving(false);
          setManualError(true);
        });
    } else {
      pinApp(appId)
        .then(() => {
          setSaving(false);
          dispatch(showSnackbar(`Pinned ${name} for Everyone`));
          setPinAppDialogOpen();
        })
        .catch(() => {
          setSaving(false);
          setManualError(true);
        });
    }
  };

  const handleClose = () => {
    setPinAppDialogOpen();
    setManualError(false);
  };

  return (
    <Dialog
      open={pinAppDialogOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(handlePin)}>
        {
          (saving) && (
            <LinearProgress />
          )
        }
        <DialogTitle id="form-dialog-title">
          {isPinned ? 'Unpin' : 'Pin'}
          {' '}
          {name}
          {' '}
          for Everyone?
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            When you
            {' '}
            {isPinned ? 'unpin' : 'pin'}
            {' '}
            {name}
            ,
            {' '}
            {isPinned ? 'the shortcut will be removed from' : 'a shortcut will be placed on'}
            {' '}
            everyone's homepage for this app.
          </Typography>
          {manualError && (
            <Box my={2}>
              <Typography color="error" variant="body2">
                Sorry, there was a problem {isPinned ? 'unpinning' : 'pinning'}
                {' '}
                {name}
                .
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={saving} type="submit" color="primary">
            {isPinned ? 'Unpin App' : 'Pin App'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PinAppDialog;
