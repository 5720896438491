import React, { useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import ContainedLayout from '../../../templates/ContainedLayout';
import LeadingTitleText from '../../../atoms/LeadingTitleText';
import SlackTokenDeleteAdminDialog from '../../../organisms/SlackTokenDeleteAdminDialog';

const Company = () => {
  const [manualError] = useState(false);
  const [slackTokenDeleteDialogOpen, setSlackTokenDeleteDialogOpen] = useState(
    false,
  );
  const toggleSlackTokenDeleteDialog = () => setSlackTokenDeleteDialogOpen(!slackTokenDeleteDialogOpen);

  const { slackEnabled } = useSelector((state) => state.company.data);
  const companyId = useSelector((store) => store.users.currentUser.data.companyId);

  if (manualError) {
    // TODO: STYLING need to show something here
    return <div>Failed to load...</div>;
  }

  return (
    <>
      <ContainedLayout>
        <MultiFormsContainer>

          <FormContainer>
            <FormHeader>
              <Typography variant="h6">Brodi Slack Bot</Typography>
            </FormHeader>
            <FormContent>
              <FormRow>
                {slackEnabled ? (
                  <LeadingTitleText heading="Bot Token">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body1">
                        &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
                      </Typography>

                      <Button
                        color="secondary"
                        onClick={toggleSlackTokenDeleteDialog}
                      >
                        Revoke Token
                      </Button>
                    </div>
                  </LeadingTitleText>
                ) : (
                  <a href={`https://slack.com/oauth/v2/authorize?client_id=1060352034884.1375378235890&scope=users:read,users:read.email,chat:write&state=${companyId}`}><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>
                )}
              </FormRow>
            </FormContent>
          </FormContainer>
        </MultiFormsContainer>
      </ContainedLayout>
      {/* Dialog To Delete Slack Token */}
      <SlackTokenDeleteAdminDialog
        slackTokenDeleteDialogOpen={slackTokenDeleteDialogOpen}
        toggleSlackTokenDeleteDialog={toggleSlackTokenDeleteDialog}
      />
    </>
  );
};

export default Company;

const MultiFormsContainer = styled.div`
  padding: 1rem;
  max-width: 600px;
`;

const FormContainer = styled.div`
  margin-bottom: 2rem;
`;

const FormHeader = styled.div`
  margin-bottom: 1rem;
`;

const FormContent = styled.div``;

const FormRow = styled.div`
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
`;
