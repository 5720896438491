import React from 'react';

import { Grid, Box } from '@material-ui/core';

const CenteredLayout = ({ children }) => (
  <Box style={{ backgroundColor: '#FAFAFA' }}>
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={6}>
        {children}
      </Grid>
    </Grid>
  </Box>
);

export default CenteredLayout;
