import React from 'react';
import { Controller } from 'react-hook-form';
import Debounce from 'lodash.debounce';

import {
  Typography, TextField, Grid, Avatar, CircularProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { resolveCategoryId } from '../../helpers/resolvers';
import { labelInitials } from '../../helpers/strings';
import { searchTemplateApps } from '../../actions/apps';

const GetNameAutocomplete = ({
  categories,
  handleOnChange = () => {},
  errors,
  control,
}) => {
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const WAIT_INTERVAL = 1000;
  let timerID;

  const handleInputChange = async (e, v) => {
    if (!v?.trim()) return;
    handleOnChange({ name: v });

    clearTimeout(timerID);
    timerID = setTimeout(async () => {
      setLoading(true);
      setOptions(await searchTemplateApps(v));
      setLoading(false);
    }, WAIT_INTERVAL);

  };

  return (
    <Controller
      control={control}
      name="name"
      rules={{ required: true }}
      onChange={([, data]) => {
        if (data?.category) {
          handleOnChange(data);
        } else {
          handleOnChange({ name: data });
        }
        return data.name;
      }}
      as={(
        <Autocomplete
          freeSolo
          autocomplete="off"
          disableClearable
          loading={loading}
          onInputChange={handleInputChange}
          getOptionLabel={(option) => option?.name || option || ''}
          groupBy={(option) => resolveCategoryId(option.category, categories)}
          options={options.sort((a, b) => -b.category.localeCompare(a.category))}
          renderOption={(option) => (
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item>
                <Avatar src={option.icon}>{labelInitials(option.name)}</Avatar>
              </Grid>
              <Grid container item direction="column" xs>
                <Grid item><Typography variant="body1">{option.name}</Typography></Grid>
                <Grid item><Typography variant="body2" color="textSecondary">{option.defaultUrl}</Typography></Grid>
              </Grid>
            </Grid>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              autocomplete="off"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              label="App Name"
              error={Boolean(errors)}
              helperText={errors ? 'Please provide a valid name' : 'E.g: Slack'}
              variant="outlined"
              placeholder="Start Typing for Suggestions..."
            />
          )}
        />
      )}
    />
  );
};

export default GetNameAutocomplete;
