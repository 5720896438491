import React, { useEffect, useState } from 'react';
import {
  Box, Typography,
} from '@material-ui/core';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import { Tableau20 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.tableau';

import { DateTime } from 'luxon';
import ContainedLayout from '../templates/ContainedLayout';
import SingleStat from '../atoms/SingleStat';
import {
  getCostApps, getCountApps, getCountAppsByStructure, getExpiringContracts, getCostAppsByStructure, getAppsByCategory,
} from '../../actions/insights';
import { currencyFormat } from '../../helpers/numbers';

const Insights = () => {
  const dispatch = useDispatch();
  const [manualError] = useState(false);

  const [appsByDept, setAppsByDept] = useState({
    data: [],
    loading: true,
  });

  const [costAppsByDept, setCostAppsByDept] = useState({
    data: {
      data: [],
    },
    loading: true,
  });

  // Single Stats
  const [countAppsSummary, setCountAppsSummary] = useState({
    data: {},
    loading: true,
  });

  const [costSummary, setCostSummary] = useState({
    data: {},
    loading: true,
  });

  const [appsByCategory, setAppsByCategory] = useState({
    data: [],
    loading: true,
  });

  const [renewals, setRenewals] = useState({
    data: [],
    loading: true,
  });

  useEffect(() => {
    (async () => {
      setRenewals({
        data: await dispatch(getExpiringContracts(DateTime.local().plus({ months: 3 }).toUTC())),
        loading: false,
      });
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      setAppsByCategory({
        data: await dispatch(getAppsByCategory()),
        loading: false,
      });
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const { data, sample } = await dispatch(getCostAppsByStructure());
      setCostAppsByDept({
        data: {
          data, sample,
        },
        loading: false,
      });
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      setCostSummary({
        data: await dispatch(getCostApps()),
        loading: false,
      });
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      setAppsByDept({
        data: await dispatch(getCountAppsByStructure()),
        loading: false,
      });
    })();
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      setCountAppsSummary({
        data: await dispatch(getCountApps()),
        loading: false,
      });
    })();
  }, [dispatch]);

  if (manualError) {
    // TODO: STYLING need to show something here
    return <div>Failed to load...</div>;
  }

  return (
    <>
      <ContainedLayout tinted>
        <Box py={8}>
          <Typography variant="h3">
            Stack Insights
          </Typography>
        </Box>
      </ContainedLayout>

      <Container>
        <TabsContentContainer>
          <SingleStatContainerRow>
            {/* Count Apps Summary */}
            <SingleStat
              loading={countAppsSummary.loading}
              stat={`${countAppsSummary.data.active || 0} ${countAppsSummary.data.active === 1 ? 'App' : 'Apps'}`}
              header="APPS IN YOUR STACK"
              caption={countAppsSummary.data.archived > 0 && `And ${countAppsSummary.data.archived} archived ${countAppsSummary.data.archived === 1 ? 'App' : 'Apps'}`}
            />

            <SingleStat
              loading={costSummary.loading}
              stat={currencyFormat(Math.round(costSummary.data.monthly) || 0, { currency: costSummary.data.currency })}
              header={`MONTHLY STACK COST ${costSummary.data.sample?.appsWithoutCost > 0 ? '*' : ''}`}
              caption={!!costSummary.data.sample?.appsWithoutCost > 0 && `* ${costSummary.data.sample.appsWithoutCost} Apps in your stack are missing cost information`}
            />

            <SingleStat
              loading={renewals.loading}
              stat={`${renewals.data?.data?.length || 0} ${renewals.data?.data?.length === 1 ? 'App' : 'Apps'}`}
              header="UPCOMING APP RENEWALS"
              caption={!!renewals.data.sample?.appsWithoutContract > 0 && `* ${renewals.data.sample.appsWithoutContract} Apps in your stack are missing contract information`}
            />
          </SingleStatContainerRow>

          <ChartsGroup>
            <GroupTitle>
              <Typography variant="h5">
                Number of Apps by Org Structure
              </Typography>
            </GroupTitle>
            <GroupData>
              {appsByDept.data.map(({ id, name, children }) => (
                <ChartContainer key={id}>
                  <ChartTitle>
                    <Typography variant="h6" align="center">
                      Apps by
                      {' '}
                      {name}
                    </Typography>
                  </ChartTitle>
                  <ChartContent>
                    <Doughnut
                      legend={{ position: 'bottom' }}
                      width={400}
                      height={300}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          colorschemes: {
                            scheme: Tableau20,
                          },
                        },
                      }}
                      data={{
                        datasets: [{ data: children.map(({ count }) => count) }],
                        labels: children.map(({ name: childName }) => childName),
                      }}
                    />
                  </ChartContent>
                </ChartContainer>
              ))}
            </GroupData>
          </ChartsGroup>

          <ChartsGroup>
            <GroupTitle>
              <Typography variant="h5">
                App Cost by Org Structure
              </Typography>
            </GroupTitle>
            <GroupData>
              {costAppsByDept.data.data.map((parentStructure) => (
                <ChartContainer key={parentStructure.id}>
                  <ChartTitle>
                    <Typography variant="h6" align="center">
                      Apps by
                      {' '}
                      {parentStructure.name}
                    </Typography>
                    <Typography variant="body1" align="center">
                      Monthly Cost
                    </Typography>
                  </ChartTitle>
                  <ChartContent>
                    <Doughnut
                      legend={{ position: 'bottom' }}
                      width={400}
                      height={300}
                      options={{
                        maintainAspectRatio: false,
                        plugins: {
                          colorschemes: {
                            scheme: Tableau20,
                          },
                        },
                      }}
                      data={{
                        datasets: [{ data: parentStructure.children.map(({ monthly }) => monthly) }],
                        labels: parentStructure.children.map(({ name }) => name),
                      }}
                    />
                  </ChartContent>
                </ChartContainer>
              ))}
            </GroupData>
          </ChartsGroup>

          <ChartsGroup>
            <GroupTitle>
              <Typography variant="h5">
                Apps by Category
              </Typography>
            </GroupTitle>
            <GroupData>
              <ChartContainer>
                <ChartTitle>
                  <Typography variant="h6" align="center">
                    Apps by Category
                  </Typography>
                </ChartTitle>
                <ChartContent>
                  <Doughnut
                    legend={{ position: 'bottom' }}
                    width={400}
                    height={300}
                    options={{
                      maintainAspectRatio: false,
                      plugins: {
                        colorschemes: {
                          scheme: Tableau20,
                        },
                      },
                    }}
                    data={{
                      datasets: [{ data: appsByCategory.data.map(({ active, archived }) => active + archived) }],
                      labels: appsByCategory.data.map(({ name }) => name),
                    }}
                  />
                </ChartContent>
              </ChartContainer>
            </GroupData>
          </ChartsGroup>
        </TabsContentContainer>
      </Container>
    </>
  );
};

export default Insights;

const SingleStatContainerRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 2rem;
  > div {
    :not(:last-child) {
      margin-right: 2rem;
    }
  }
`;

const ChartContainer = styled.div`
  padding: 3rem;
  background-color: #fafafa;
  max-width: 400px;
  border-radius: 10px;
`;

const ChartContent = styled.div``;
const ChartTitle = styled.div`
  margin-bottom: 2rem;
`;

const Container = styled.div`
`;

const TabsContentContainer = styled.div`
  padding: 1rem 3rem;
`;

const ChartsGroup = styled.div`
  margin-bottom: 2rem;
`;

const GroupTitle = styled.div`
  margin-bottom: 1rem;
`;

const GroupData = styled.div`
  display: flex;
  > div {
    :not(:last-child) {
      margin-right: 1rem;
    }
  }  
`;
