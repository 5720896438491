import React, { useState } from 'react';
import {
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  Grid,
  Box,
  Typography,
  LinearProgress,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { changePassword } from '../../actions/users';
import { showSnackbar } from '../../actions/snackbar';
import { PASSWORD_REGEX } from '../../config/constants';

const PasswordChangeDialog = ({
  passwordDialogOpen, togglePasswordChangeDialog,
}) => {
  const dispatch = useDispatch();
  const [manualError, setManualError] = useState(false);
  const [saving, setSaving] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    watch,
  } = useForm();

  const handleChangeRequest = (data) => {
    setSaving(true);
    setManualError(false);
    changePassword(data.newPassword, data.currentPassword)
      .then(() => {
        setSaving(false);
        dispatch(showSnackbar('Password Changed'));
        togglePasswordChangeDialog();
      })
      .catch(() => {
        setSaving(false);
        setManualError(true);
      });
  };

  const handleClose = () => {
    setManualError(false);
    togglePasswordChangeDialog();
  };

  return (
    <Dialog
      open={passwordDialogOpen}
      onClose={togglePasswordChangeDialog}
      aria-labelledby="form-dialog-title"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(handleChangeRequest)}>
        {
          (saving) && (
            <LinearProgress />
          )
        }
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TextField
                autoFocus
                label="Current Password"
                name="currentPassword"
                type="password"
                inputRef={register({ required: true })}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                label="New Password"
                name="newPassword_1"
                type="password"
                error={Boolean(errors.newPassword_1)}
                helperText="At least eight characters and must be a mix of uppercase, lowercase, symbols and numbers."
                inputRef={register({ required: true, pattern: new RegExp(PASSWORD_REGEX) })}
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                label="New Password Again"
                name="newPassword"
                type="password"
                error={Boolean(errors.newPassword)}
                helperText={errors.newPassword ? "Those passwords didn't match" : ''}
                inputRef={register({
                  required: true,
                  validate: (value) => value === watch('newPassword_1'),
                })}
                fullWidth
              />
            </Grid>
          </Grid>
          {manualError && (
            <Box my={2}>
              <Typography color="error" variant="body2">
                {'Sorry, there was a problem changing your password. \
                Please check that you entered the correct current password.'}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={saving} type="submit" color="primary">
            Change Password
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PasswordChangeDialog;
