import React, { useState } from 'react';
import {
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  LinearProgress,
  Typography,
  Box,
} from '@material-ui/core';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { showSnackbar } from '../../actions/snackbar';
import { deleteApp } from '../../actions/admin';

const ConfirmAppDelete = ({
  appId,
  confirmAppDeleteDialogOpen,
  setConfirmAppDeleteDialogOpen,
  setRedirect,
  name,
}) => {
  const [manualError, setManualError] = useState(false);
  const [saving, setSaving] = useState(false);

  const [doubleConfirmDialog, setDoubleConfirmDialog] = useState(false);

  const {
    handleSubmit,
  } = useForm();
  const dispatch = useDispatch();

  const handleDelete = () => {
    setSaving(true);
    setManualError(false);
    dispatch(deleteApp(appId))
      .then(() => {
        setSaving(false);
        dispatch(showSnackbar(`Deleted ${name}`));
        setConfirmAppDeleteDialogOpen();
        setRedirect(true);
      })
      .catch(() => {
        setSaving(false);
        setManualError(true);
      });
  };

  const handleClose = () => {
    setConfirmAppDeleteDialogOpen();
    setManualError(false);
    setDoubleConfirmDialog(false)
  };

  return (
    <>
      <Dialog
        open={confirmAppDeleteDialogOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
      >
          <DialogTitle id="form-dialog-title">
            Delete
            {' '}
            {name}
            ?
          </DialogTitle>
          <DialogContent>
            <WarningBanner>
              <Typography variant='body2' style={{ fontWeight: 600 }}>
                WARNING: This action cannot be undone.
              </Typography>
            </WarningBanner>
            <Typography variant="body1">
              When you delete
              {' '}
              {name}
              , it will no longer appear in search results.
              {' '}
              Its data will be permanently removed. This action cannot be undone.
            </Typography>
            {manualError && (
              <Box my={2}>
                <Typography color="error" variant="body2">
                  Sorry, there was a problem deleting
                  {name}
                  .
                </Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button disabled={saving} onClick={handleClose}>
              Cancel
            </Button>
            <Button disabled={saving} color="primary" onClick={() => setDoubleConfirmDialog(true)}>
              Delete
            </Button>
          </DialogActions>
      </Dialog>

      {/* DOUBLE CONFIRMATION DIALOG */}
      <Dialog
        open={doubleConfirmDialog}
        onClose={handleClose}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <form onSubmit={handleSubmit(handleDelete)}>
          {
            (saving) && (
              <LinearProgress />
            )
          }
        <DialogTitle id="form-dialog-title">
          Permanently Delete
          {' '}
          {name}
          ?
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            "{name}" will be permanently removed from Brodi for everyone.
          </Typography>
          {manualError && (
            <Box my={2}>
              <Typography color="error" variant="body2">
                Sorry, there was a problem deleting
                {name}
                .
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={saving} type="submit" color="primary">
            Delete
          </Button>
        </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ConfirmAppDelete;

const WarningBanner = styled.div`
  padding: 1rem;
  border-radius: 10px;
  background-color: rgb(255,65,54, 0.5);
  margin-bottom: 1rem;
`;
