import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  DialogContent,
  DialogContentText,
  TextField,
  Dialog,
  DialogActions, LinearProgress, IconButton, Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { editExternalContacts } from '../../../actions/apps';
import { showSnackbar } from '../../../actions/snackbar';

const externalContactsDefault = { name: '', info: '', type: '' };

export default ({
  appId, externalContacts, handleExternalContacts, openExternalContacts,
}) => {
  const dispatch = useDispatch();
  const [manualError, setManualError] = useState(false);

  const {
    control,
    errors,
    handleSubmit,
    register,
    reset,
    formState,
  } = useForm({
    defaultValues: { externalContacts },
  });

  const { fields, append, remove } = useFieldArray({
    control, name: 'externalContacts',
  });

  useEffect(() => {
    reset({ externalContacts });
  }, [reset, externalContacts]);

  const handleFormCancel = () => {
    handleExternalContacts();
    reset({ externalContacts });
  };

  const updateExternalContacts = (
    { externalContacts: updatedExternals = [] },
  ) => new Promise((resolve, reject) => {
    setManualError(false);

    dispatch(editExternalContacts(updatedExternals, appId))
      .then(() => {
        dispatch(showSnackbar('External Contacts Updated'));
        handleExternalContacts();
        resolve();
      })
      .catch(() => {
        setManualError(true);
        reject();
      });
  });

  const externalContactSuggestions = [
    'Choose a Suggestion or Enter Your Own', // disabled
    'Billing Manager',
    'Account Manager',
    'Technical Support',
    'Legal Support',
    'Compliance Support',
  ];

  return (
    <Dialog
      open={openExternalContacts}
      onClose={handleExternalContacts}
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(updateExternalContacts)}>
        {
          (formState.isSubmitting) && (
            <LinearProgress />
          )
        }
        <DialogActionTitle>
          <Typography variant="h6" component="div">
            Edit External Contacts
          </Typography>

          <DialogAction>
            <IconButton onClick={() => append(externalContactsDefault)} title="Add New Link">
              <AddIcon />
            </IconButton>
          </DialogAction>

        </DialogActionTitle>
        <DialogContent>
          {fields.map((value, index) => (
            <LinkContainer key={value.name}>
              <LinkLhs>
                <IconButton onClick={() => remove(index)} title="Remove Contact">
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </LinkLhs>

              <LinkRhs>
                <FieldRow>
                  <TextField
                    error={!!errors.externalContacts?.[index]?.name}
                    fullWidth
                    size="small"
                    label="Contact Name"
                    defaultValue={value.name || ''}
                    inputRef={register()}
                    helperText={errors.externalContacts?.[index] && 'Please provide a valid contact name'}
                    name={`externalContacts[${index}].name`}
                    autoComplete="off"
                  />
                </FieldRow>

                <FieldRow>
                  <TextField
                    error={!!errors.externalContacts?.[index]?.info}
                    fullWidth
                    size="small"
                    label="Contact Info"
                    defaultValue={value.info || ''}
                    placeholder="Email, Phone, or URL"
                    inputRef={register()}
                    helperText={errors.externalContacts?.[index] && 'Please provide valid contact information'}
                    name={`externalContacts[${index}].info`}
                  />
                </FieldRow>

                <FieldRow>
                  <Controller
                    as={(
                      <Autocomplete
                        freeSolo
                        autoSelect
                        disableClearable
                        getOptionDisabled={(option) => option === 'Choose a Suggestion or Enter Your Own'}
                        options={externalContactSuggestions || []}
                        renderInput={(params) => (
                          <TextField
                            error={!!errors.externalContacts?.[index]?.type}
                            {...params}
                            label="Contact Type"
                            helperText={errors.externalContacts?.[index]?.type && 'Please provide a contact type'}
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                    )}
                    onChange={([, d]) => d}
                    name={`externalContacts[${index}].type`}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={value.type || ''}
                  />
                </FieldRow>
              </LinkRhs>
            </LinkContainer>
          ))}
          {manualError ? (
            // TODO: STYLING: This needs better styling
            <DialogContentText color="error">
              There was a problem changing the app links.
            </DialogContentText>
          ) : null}
        </DialogContent>

        <DialogActions>
          <Button disabled={formState.isSubmitting} onClick={handleFormCancel}>
            <span>Cancel</span>
          </Button>
          <Button color="primary" type="submit" disabled={!formState.dirty || formState.isSubmitting}>
            <span>Save Changes</span>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const LinkContainer = styled.div`
  border: 1px solid #eee;
  margin-bottom: 20px;
  display: flex;
`;

const LinkLhs = styled.div`
  width: 15%;
  padding: 10px;
  background-color: #eee;
  text-align: center;
`;
const LinkRhs = styled.div`
width: 85%;
padding: 25px;
`;

const FieldRow = styled.div`
  :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const DialogActionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

const DialogAction = styled.div``;
