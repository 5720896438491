import firebase from '../config/firebase';
import { POST } from '../config/constants';
import { brodiUnauthedRequest } from '../helpers/backend';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const VERIFY_AUTH_SUCCESS = 'VERIFY_AUTH_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const getSlugInfo = async (email) => brodiUnauthedRequest(POST, '/auth/slug', { email });

export const reauth = async (password) => {
  // Tenant info is attached to the currentUser object, therefore no need to set it
  const { currentUser } = firebase.auth();
  const { email } = currentUser;
  const credential = firebase.auth.EmailAuthProvider.credential(
    email,
    password,
  );
  await currentUser.reauthenticateWithCredential(credential);
};

export const auth = (credentials, tenantId) => async (dispatch) => {
  firebase.auth().tenantId = tenantId;
  await firebase.auth().signInWithEmailAndPassword(
    credentials.email,
    credentials.password,
  );
  dispatch({ type: AUTH_SUCCESS });
};

export const logout = () => async (dispatch) => {
  await firebase.auth().signOut();
  dispatch({ type: LOGOUT_SUCCESS });
};

export const redirectToSAML = async (tenantId, providerId) => {
  const provider = new firebase.auth.SAMLAuthProvider(providerId);
  firebase.auth().tenantId = tenantId;
  await firebase.auth().signInWithRedirect(provider);
};
