import React, { useEffect, createRef, useMemo } from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';
import { useTable } from 'react-table';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import AppCard from './AppCard';
import { resolveCategoryId } from '../../helpers/resolvers';
import AppLogo from '../atoms/AppLogo';
import AppAvailabilityStatusIcon from '../atoms/AppAvailabilityStatusIcon';

const HitsSearch = ({
  hits, hasMore, refineNext, detailedView,
}) => {
  const sentinel = createRef();
  const history = useHistory();

  useEffect(() => {
    const onSentinelIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && hasMore) {
          // In that case we can refine
          refineNext();
        }
      });
    };

    const observer = new IntersectionObserver(onSentinelIntersection);
    observer.observe(sentinel.current);
    return () => observer.disconnect();
  }, [sentinel, hasMore, refineNext]);

  console.log(hits)
  const { categories } = useSelector((store) => store.client.data);

  const columns = useMemo(() => [
    {
      accessor: 'availabilityStatus',
      Cell: ({ value }) => <AppAvailabilityStatusIcon status={value} />,
    },
    {
      accessor: 'icon',
      Cell: ({ value, row }) => <AppLogo flat name={row.original.name} icon={value} height="40px" width="40px" />,
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ value }) => <Typography variant="body1" align="center" style={{ fontWeight: 600 }}>{value}</Typography>,
    }, {
      Header: 'URL',
      accessor: 'url',
      Cell: ({ value }) => <Typography variant="body2" align="center">{value}</Typography>,
    },
    {
      Header: 'Category',
      accessor: 'category',
      Cell: ({ value }) => <Typography variant="body2" align="center">{resolveCategoryId(value, categories)}</Typography>,
    }, {
      Header: 'Favorited By',
      accessor: 'followers',
      Cell: ({ value = [] }) => (
        <Typography variant="body2" align="center">
          {value.length > 1 && `${value.length} People`}
          {value.length === 1 && `${value.length} Person`}
          {value.length === 0 && 'Nobody'}
        </Typography>
      ),
    },
    {
      Header: 'Responsible Team',
      accessor: 'owner',
      Cell: ({ value }) => (
        <div style={{ textAlign: 'center' }}>
          {value.map((child) => (
            <Chip>
              {child.name}
            </Chip>
          ))}
        </div>
      ),
    },
    {
      Header: 'Updated',
      accessor: 'updatedTimestamp',
      Cell: ({ value }) => {
        if (value) {
          return <Typography title={DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)} variant="body2" align="center">{DateTime.fromISO(value).toRelative()}</Typography>;
        }
        return <></>;
      },
    },
  ], [categories]);

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow,
  } = useTable({ columns, data: hits });

  if (detailedView) {
    return (
      <>
        <DetailedTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <DetailedHitRow {...row.getRowProps()} onClick={() => history.push(`/app/${row.original.objectID}/${row.original.name}`)}>
                  { row.cells.map((cell) => (
                    <DetailedTableData {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </DetailedTableData>
                  ))}
                </DetailedHitRow>
              );
            })}
          </tbody>
        </DetailedTable>
        <span className="ais-InfiniteHits-sentinel" ref={sentinel} />
      </>
    );
  }

  return (
    <>
      <HitsContainer>
        {hits.map((hit) => (
          <HitWrapper key={hit.objectID}>
            <AppCard
              name={hit.name}
              id={hit.objectID}
              icon={hit.icon}
              category={hit.category}
            />
          </HitWrapper>
        ))}
        <span className="ais-InfiniteHits-sentinel" ref={sentinel} />
      </HitsContainer>

      {hasMore && (
      <ButtonContainer>
        <Button
          variant="contained"
          onClick={refineNext}
        >
          Load More Apps
        </Button>
      </ButtonContainer>
      )}
    </>
  );
};

export default HitsSearch;

const DetailedTableData = styled.td`
  padding: 0.5rem 1.5rem;
`;

const DetailedHitRow = styled.tr`
  background-color: #fafafa;
  border: solid 1px #000;
  cursor: pointer;
  :hover {
    background-color: #f0f0f0;
  }
`;

const DetailedTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 1rem;
  tr td:first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
  }

  tr td:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }

`;

const HitsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const HitWrapper = styled.div`
  display: flex;
  margin: 1.5rem;
  ${(props) => props.detailedView && `
  @media (min-width: 0px) {
    flex: 0 0 100%;
  }   
  @media (min-width: 900px) {
    flex: 0 0 30%;
  }   
  `}
`;

const Chip = styled.div`
  background-color: #eee;
  display: inline-block;
  color: #000;
  border-radius: 5px;
  padding: 2px 10px;
  font-weight: 500;
  font-size: 12px;
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  margin-top: 2.0rem;
  text-align: center;
`;
