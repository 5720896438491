import React from 'react';
import {
  Grid, Box, Button,
} from '@material-ui/core';

import algoliasearch from 'algoliasearch/lite';
import {
  connectSearchBox,
  connectInfiniteHits,
  connectStats,
  InstantSearch,
} from 'react-instantsearch-dom';
import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { applicationId } from '../../../config/algolia';
import { ALGOLIA_COMPANY_INDEX_PREFIX, ALGOLIA_USERS_SUFFIX } from '../../../config/constants';

import ContainedLayout from '../../templates/ContainedLayout';
import HitsUsers from '../../molecules/HitsUsers';
import SearchBox from '../../molecules/SearchBox';
import SearchStats from '../../molecules/SearchStats';

const UserSearch = () => {
  const cid = useSelector((store) => store.users.currentUser.data.companyId);
  const algoliaId = useSelector((store) => store.company.data.algoliaId);

  const CustomSearchBox = connectSearchBox(SearchBox);
  const CustomHits = connectInfiniteHits(HitsUsers);
  const CustomStats = connectStats(SearchStats);

  const constructedIndexId = ALGOLIA_COMPANY_INDEX_PREFIX + cid + ALGOLIA_USERS_SUFFIX;
  const searchClient = algoliasearch(
    applicationId,
    algoliaId,
  );

  const history = useHistory();

  const handleInviteClick = () => {
    history.push('/manage/users/invite');
  };

  return (
    <InstantSearch
      indexName={constructedIndexId}
      searchClient={searchClient}
    >
      <ContainedLayout>
        <Box mt={5}>
          <Grid container item direction="column" spacing={8}>

            <Grid container item direction="column" spacing={3}>
              <Grid item>
                <CustomSearchBox label="Search Users" />
              </Grid>
            </Grid>

            <Grid container item direction="column" spacing={5}>
              <Grid item container direction="row" justify="space-between">
                <Grid item>
                  <CustomStats surroundingText="Users" />
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    size="large"
                    disableElevation
                    color="primary"
                    onClick={handleInviteClick}
                  >
                    Invite User
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <CustomHits />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </ContainedLayout>
    </InstantSearch>
  );
};

export default UserSearch;
