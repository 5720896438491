import React from 'react';
import styled from 'styled-components';

import {
  TextField, Button, Typography,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import LogoSvg from '../../../images/logo.svg';

const GetPassword = ({
  register,
  toggleResetDialogDisplay,
  handleSubmit,
  handlePasswordSubmit,
  formError,
  processing,
  email,
}) => (
  <Container>
    <LogoContainer>
      <img src={LogoSvg} alt="logo" />
    </LogoContainer>
    <TitleContainer>
      <Typography variant="h4" align="center">And now, your password</Typography>
    </TitleContainer>

    {(formError) && (
    <Typography color="error" align="center" variant="body1">
      {formError}
    </Typography>
    )}

    <form onSubmit={handleSubmit(handlePasswordSubmit)}>
      <TextFieldContainer>
        <TextField
          autoFocus
          autoComplete="current-password"
          name="password"
          variant="outlined"
          label="Password"
          type="password"
          fullWidth
          inputRef={register}
          disabled={Boolean(processing)}
        />
        <input id="emailfield" type="text" value={email} autoComplete="username" style={{ display: 'none' }} />

      </TextFieldContainer>
      <ActionContainer>
        <div>
          <Button
            disableElevation
            size="medium"
            onClick={toggleResetDialogDisplay}
          >
            Forgot
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            disableElevation
            color="primary"
            type="submit"
            size="large"
            name="login"
            endIcon={<ChevronRightIcon />}
            disabled={Boolean(processing)}
          >
            Login
          </Button>
        </div>
      </ActionContainer>
    </form>
  </Container>
);

export default GetPassword;

const Container = styled.div``;

const LogoContainer = styled.div`
  margin: 1.5rem 0;
  text-align: center;
`;

const TitleContainer = styled.div``;

const TextFieldContainer = styled.div`
  margin: 2rem auto;
  max-width: 500px;
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 2rem;
  text-align: right;
  max-width: 500px;
`;
