import React from 'react';

import {
  Typography,
} from '@material-ui/core';

const SearchStats = ({ nbHits, surroundingText }) => (
  <Typography variant="h4" style={{ fontWeight: 700 }}>
    {surroundingText}
    {' '}
    <span style={{ fontWeight: 400 }}>
      {`(${nbHits})`}
    </span>
  </Typography>
);

export default SearchStats;
