import React, { createRef, useEffect } from 'react'

import {
  Grid,
} from '@material-ui/core';

import ProfileCard from './ProfileCard';

const HitsSearch = ({ hits, hasMore, refineNext }) => {
  const sentinel = createRef();

  useEffect(() => {
    const onSentinelIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && hasMore) {
          // In that case we can refine
          refineNext();
        }
      });
    };

    const observer = new IntersectionObserver(onSentinelIntersection);
    observer.observe(sentinel.current);
    return () => observer.disconnect();
  }, [sentinel, hasMore, refineNext]);

  return (
    <Grid container item spacing={4}>
      {hits.map((hit) => (
        <Grid item key={hit.objectID}>
          <ProfileCard
            name={hit.name}
            id={hit.objectID}
            icon={hit.avatar}
            email={hit.email}
            onboarding={hit.onboarding}
            disabled={hit.disabled}
          />
        </Grid>
      ))}
      <span className="ais-InfiniteHits-sentinel" ref={sentinel} />
    </Grid>
  )
};

export default HitsSearch;
