export default class Client {
  constructor(
    categories = {},
    contactAutoSuggestions = [],
    contactTyles = {},
    isReady = false,
    linkAutoSuggestions = [],
  ) {
    this.categories = categories;
    this.contactAutoSuggestions = contactAutoSuggestions;
    this.contactTyles = contactTyles;
    this.isReady = isReady;
    this.linkAutoSuggestions = linkAutoSuggestions;
  }
}
