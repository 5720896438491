export const resolveOrgChildName = (findId, children) => {
  if (children) {
    const res = children.find(({ id }) => id === findId);
    return res?.name || 'Unknown';
  }
  return 'Other';
};

export const resolveOrgStructureType = (findId, structure) => {
  if (structure) {
    const res = structure.find(({ id }) => id === findId);
    return res?.name || 'Unknown';
  }
  return 'Other';
};

export const resolveCategoryId = (id, appCategories = {}) => {
  if (id in appCategories) return appCategories[id].name;
  return 'Other';
};
