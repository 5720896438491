import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { labelInitials, truncate } from '../../helpers/strings';
import PushPinSvg from '../../images/Pin.svg';

const PinnedApp = ({
  name,
  icon,
  id,
  onClickEvent = () => {},
}) => {
  const { displayName } = useSelector((state) => state.company.data);

  return (
    <Container onClick={onClickEvent} key={id}>
      <PinRow title={`Pinned by ${displayName}`}>
        <img src={PushPinSvg} alt="push pin" />
      </PinRow>
      <ContentRow>
        {(icon ? (
          <Icon alt={`Logo for ${name}`} src={icon} />
        ) : (
          <LetterIcon>{labelInitials(name)}</LetterIcon>
        ))}
        <Typography component="div" title={name} variant="body1" style={{ fontWeight: 700, marginLeft: '1rem' }}>
          {truncate(name, 13)}
        </Typography>
      </ContentRow>
    </Container>
  );
};
export default PinnedApp;

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.17);
  border-radius: 25px;
  transition: 0.10s box-shadow ease-in-out;
  min-width: 250px;
  &:hover {
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.25);
    cursor: pointer;
  }
`;

const PinRow = styled.div`
  text-align: right;
  padding: 1rem 1rem 0 1rem;
`;

const ContentRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0rem 2.5rem 1.5rem 2rem;
`;

const LetterIcon = styled.div`
  background-color: #EEE;
  align-items: center;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  height: 40px;
  width: 40px;
  color: #000;
  background-color: #EEE;
  margin-bottom: 5px;
`;

const Icon = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 5px;
  object-fit: cover;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px;
`;
