import React, { useEffect, useState } from 'react';

import {
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  LinearProgress,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Select from 'react-select';

import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import algoliasearch from 'algoliasearch/lite';
import { connectAutoComplete, InstantSearch } from 'react-instantsearch-dom';

import { editApp } from '../../../actions/apps';
import {
  ALGOLIA_COMPANY_INDEX_PREFIX,
  ALGOLIA_USERS_SUFFIX,
  INTERNAL_SUPPORT,
  PERSON,
} from '../../../config/constants';
import { applicationId } from '../../../config/algolia';
import { showSnackbar } from '../../../actions/snackbar';

const useStyles = makeStyles({
  paperFullWidth: {
    overflowY: 'visible',
  },
  dialogContentRoot: {
    overflowY: 'visible',
  },
});

const selectStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 500,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 500,
    position: 'relative',
  }),
  menuList: (provided) => ({
    ...provided,
    zIndex: 500,
  }),
};

const AutocompleteContactsLite = ({
  refine,
  hits,
  type,
  cachedUsers,
  fieldName,
  contactsForUpdate,
  setFormIsDirty,
  setContactsForUpdate,
}) => (
  <Select
    isMulti
    key={type}
    styles={selectStyles}
    value={contactsForUpdate[type] || []}
    options={hits}
    noOptionsMessage={() => 'Nobody Found'}
    getOptionValue={(option) => option.objectID || option.id}
    getOptionLabel={(option) => option.name || cachedUsers[option.id]?.name}
    name={fieldName}
    filterOption={(options) => options}
    onInputChange={(searchString) => refine(searchString)}
    onChange={(e) => {
      setFormIsDirty(true);
      setContactsForUpdate({
        ...contactsForUpdate,
        [type]: e || [],
      });
    }}
  />
);

const EditContacts = ({
  appId,
  handleContactsDialog,
  openContacts,
  heading,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const cachedUsers = useSelector((store) => store.users.cachedUsers.data);
  const { algoliaId, contactGroups } = useSelector((store) => store.company.data);
  const { companyId } = useSelector((store) => store.users.currentUser.data);
  const { contacts } = useSelector((store) => store.apps.cachedApps.data[appId]);

  const [manualError, setManualError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [contactsForUpdate, setContactsForUpdate] = useState({});

  const groupContactsByHelpType = (c) => c.reduce((accum, contact) => {
    if (accum[contact.group]) accum[contact.group].push(contact);
    // eslint-disable-next-line no-param-reassign
    else accum[contact.group] = [contact];
    return accum;
  }, {});

  // const createListOfContactTypesToDisplay = (activeTypes, currentContacts = []) => {
  //   const currentContactTypes = currentContacts?.map(({ group }) => group) || [];
  //   const currentActiveTypes = activeTypes?.map(({ id }) => id) || [];
  //   return [...currentContactTypes, ...currentActiveTypes];
  // };

  useEffect(() => {
    setContactsForUpdate(groupContactsByHelpType(contacts));
  }, [contacts]);

  const handleContactsCancelForm = () => {
    setManualError(false);
    // Reset the content of the modal back to the original
    setContactsForUpdate(groupContactsByHelpType(contacts));
    handleContactsDialog();
  };

  const updateContacts = () => {
    const newArr = [];

    setSaving(true);
    setManualError(false);

    if (Object.keys(contactsForUpdate).length) {
      Object.keys(contactsForUpdate)
        .map((group) => contactsForUpdate[group]
          .map((contact) => newArr.push(
            {
              group,
              source: INTERNAL_SUPPORT,
              type: PERSON,
              id: contact.id || contact.objectID,
            },
          )));
    }

    dispatch(editApp({ contacts: newArr }, appId))
      .then(() => {
        dispatch(showSnackbar('Changes Saved'));
        handleContactsDialog();
        setSaving(false);
        setFormIsDirty(false);
      })
      .catch(() => {
        setSaving(false);
        setManualError(true);
      });
  };

  const constructedIndexId = ALGOLIA_COMPANY_INDEX_PREFIX + companyId + ALGOLIA_USERS_SUFFIX;
  const searchClient = algoliasearch(applicationId, algoliaId);
  const CustomAutocomplete = connectAutoComplete(AutocompleteContactsLite);

  return (
    <Dialog
      open={openContacts}
      onClose={handleContactsDialog}
      maxWidth="sm"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      classes={{
        paperFullWidth: classes.paperFullWidth,
      }}
    >
      {(saving) && <LinearProgress />}
      <DialogTitle id="form-dialog-title">{heading}</DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <GroupContainer>
          {/* TODO: if a contact group is removed, then this will not show the previous contacts */}
          {contactGroups.map((group) => (
            <GroupRow key={group.id}>
              <Typography variant="body2" style={{ fontWeight: 700 }}>{group.name.toUpperCase()}</Typography>
              <Typography variant="body2" style={{ marginBottom: '.5rem' }}>{group.description}</Typography>
              <InstantSearch indexName={constructedIndexId} searchClient={searchClient}>
                <CustomAutocomplete
                  fieldName={group.id}
                  contactsForUpdate={contactsForUpdate}
                  setContactsForUpdate={setContactsForUpdate}
                  setFormIsDirty={setFormIsDirty}
                  type={group.id}
                  cachedUsers={cachedUsers}
                />
              </InstantSearch>
            </GroupRow>
          ))}
        </GroupContainer>

        {manualError ? (
          <Typography variant="body2" color="error" style={{ fontWeight: 500 }}>
            Sorry, there was a problem saving these contacts. Please try again.
          </Typography>
        ) : null}

      </DialogContent>
      <DialogActions>
        <Button disabled={saving} onClick={handleContactsCancelForm}>
          <span>Cancel</span>
        </Button>
        <Button disabled={saving || !formIsDirty} color="primary" onClick={updateContacts}>
          <span>Save Changes</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditContacts;

const GroupRow = styled.div``;

const GroupContainer = styled.div`
  > div {
    margin-bottom: 1rem;
  }
`;
