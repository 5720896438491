import React, { useState } from 'react';
import {
  Grid,
  Button,
  makeStyles,
  MenuItem,
  DialogContent,
  DialogContentText,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  Select,
  FormControlLabel,
  Checkbox,
  LinearProgress,
} from '@material-ui/core';

import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setAppStatus } from '../../actions/apps';
import { APP_AVAILABLE_STATUS, APP_PARTIALLY_UNAVAILABLE_STATUS, APP_UNAVAILABLE_STATUS } from '../../config/constants';
import { showSnackbar } from '../../actions/snackbar';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));

const ChangeAppAvailabilityStatusDialog = ({
  statusDialogOpen, setStatusDialogOpen, appId, name, availability, followers,
}) => {
  const {
    register, handleSubmit, control, watch,
  } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [manualError, setManualError] = useState(false);
  const [saving, setSaving] = useState(false);

  const toggleStatusDialog = () => {
    setManualError(false);
    setStatusDialogOpen(!statusDialogOpen);
  };

  const handleUpdateSubmit = (data) => {
    setSaving(true);
    setManualError(false);
    dispatch(setAppStatus(appId, data))
      .then(() => {
        if (data.notify) dispatch(showSnackbar('A notification was sent to people following this app'));
        setSaving(false);
        toggleStatusDialog();
      })
      .catch(() => {
        setSaving(false);
        setManualError(true);
      });
  };

  const statusWatch = watch('status');

  return (

    <Dialog
      open={statusDialogOpen}
      onClose={toggleStatusDialog}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(handleUpdateSubmit)}>
        {
          (saving) && (
            <LinearProgress />
          )
        }
        <DialogTitle id="form-dialog-title">Update App Availability</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* TODO, this needs changed */}
            If this App is experiencing an issue, you can let people know by updating its status.
          </DialogContentText>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Controller
                as={(
                  <Select
                    inputRef={register}
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value={APP_AVAILABLE_STATUS}>Available</MenuItem>
                    <MenuItem value={APP_PARTIALLY_UNAVAILABLE_STATUS}>Partially Unavailable</MenuItem>
                    <MenuItem value={APP_UNAVAILABLE_STATUS}>Completely Unavailable</MenuItem>
                  </Select>
                )}
                name="status"
                defaultValue={availability.status}
                control={control}
              />
            </Grid>
            {(
              (statusWatch === undefined && availability.status !== APP_AVAILABLE_STATUS)
              || (statusWatch !== APP_AVAILABLE_STATUS))
            && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Issue Summary"
                  multiline
                  rows={3}
                  helperText="Help people understand what isn't working, and when it's expected to be fixed."
                  name="message"
                  inputRef={register}
                  fullWidth
                  autoComplete="off"
                  defaultValue={availability.message}
                />
              </Grid>
            )}

            {followers?.length > 0 && (
            <Grid item xs={12}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormControlLabel
                  control={(
                    <Controller
                      as={<Checkbox />}
                      control={control}
                      name="notify"
                      defaultValue
                    />
                )}
                  name="notify"
                  label={`Notify ${followers?.length} ${followers?.length === 1 ? 'follower' : 'followers'} of ${name} about this change`}
                />
              </FormControl>

            </Grid>
            )}
            <Grid item xs={12}>
              {manualError ? (
              // TODO: STYLING: This needs better styling
                <DialogContentText color="error">
                  There was a problem changing the app status.
                </DialogContentText>
              ) : null}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={toggleStatusDialog}>Cancel</Button>
          <Button disabled={saving} type="submit" color="primary">Update Status</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangeAppAvailabilityStatusDialog;
