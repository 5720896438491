import React, { useEffect, useState } from 'react';

import {
  Fade,
  Button, IconButton, TextField, Typography,
} from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import { getNextFormStep, getPrevFormStep } from '../../../helpers/form'
import { addAppFromState, updateAddApp } from '../../../actions/apps';

import SingleFormWrapper from './SingleFormWrapper';
import GetStructureAutocomplete from '../../molecules/GetStructureAutocomplete';
import ContactFields from '../../molecules/ContactFields';
import FormProgressionButtons from '../../molecules/FormProgressionButtons';
import { addStructureChild, getCompany } from '../../../actions/company';
import { INTERNAL_SUPPORT, PERSON } from '../../../config/constants';
import { showErrorSnackbar } from '../../../actions/snackbar';

const FormHelpContacts = ({ formStep }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  // Custom Name Form
  const app = useSelector((store) => store.apps.addApp.data);
  const externalContactDefault = { name: '', info: '' };
  const { structure } = useSelector((store) => store.company.data);
  const [formData, setFormData] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);

  const { displayName, contactGroups } = useSelector((store) => store.company.data);
  const companyId = useSelector((store) => store.users.currentUser.data.companyId);

  const {
    register, handleSubmit, control, errors, reset, formState,
  } = useForm({
    defaultValues: {},
  });

  const { fields: externalContactFields, append: appendExternalContact, remove: removeExternalContact } = useFieldArray({
    control, name: 'externalContacts',
  });

  const externalContactSuggestions = [
    'Choose a Suggestion or Enter Your Own', // disabled
    'Billing Manager',
    'Account Manager',
    'Technical Support',
    'Legal Support',
    'Compliance Support',
  ];

  const saveToState = async (fD) => {
    const sendData = {};

    // Process Owner/Responsible Team
    if (fD.owner) {
      const newChildren = [];
      const existingChildren = [];

      // Check for IDs that need to be created (new structures)
      Object.keys(fD.owner).map((k) => {
        const { name, parentId, id } = fD.owner[k];
        if (name && parentId && !id) {
          // ID Must Be Created
          newChildren.push(addStructureChild(companyId, { name, parentId }));
        } else {
          // Already Exists
          existingChildren.push(id);
        }
        return fD;
      });

      const newIds = await Promise.all(newChildren);
      sendData.owner = [...newIds, ...existingChildren];
    }

    if (Object.keys(formData).length) {
      const contacts = [];
      Object.keys(formData).map((group) => formData[group].map((contact) => contacts.push(
        {
          source: INTERNAL_SUPPORT,
          type: PERSON,
          id: contact.objectID,
          group,
        },
      )));

      sendData.contacts = contacts;
    }

    if (fD.externalContacts) {
      sendData.externalContacts = fD.externalContacts;
    }

    setButtonLoading(true);
    dispatch(getCompany());
    const signature = { [formStep]: new Date() };
    dispatch(updateAddApp(sendData, signature))
  }

  const advanceForm = async (fD) => {
    await saveToState(fD);

    dispatch(addAppFromState())
      .then((res) => {
        history.push(`/add/${getNextFormStep(formStep)}`, { ...res });
      })
      .catch(() => {
        setButtonLoading(false);
        dispatch(showErrorSnackbar('There was a problem creating a new app.'));
      });
  };

  useEffect(() => {
    if (app) reset(app);
  }, [app, reset]);

  const goBackIntercept = () => {
    if(!formState.isDirty) history.push(`/add/${getPrevFormStep(formStep)}`);
    if (
      window.confirm('If you leave this page you will loose any unsaved changes. Are you sure you want to go back?')
    ) {
      history.push(`/add/${getPrevFormStep(formStep)}`);
    }
  }

  return (
    <Fade in>
      <form onSubmit={handleSubmit(advanceForm)}>
        <SingleFormWrapper subheading="STEP THREE" heading="Help & Contacts">

          <RowsContainer>
            <FormSection>
              <FormRow>
                <Typography variant="h6">
                  Owner or Responsible Team
                </Typography>
                <Typography variant="body1">
                  Select the team which owns this App at
                  {' '}
                  {displayName}
                </Typography>
              </FormRow>
              <FormRow>
                {structure.filter(({ status }) => status !== 'DELETED').map((s) => (
                  <GetStructureAutocomplete
                    errors={errors}
                    structureItem={s}
                    control={control}
                    defaultValue={app?.owner[s.id]}
                  />
                ))}
              </FormRow>

            </FormSection>

            <FormSection>
              <FormRow>
                <Typography variant="h6">
                  Add
                  {' '}
                  {displayName}
                  {' '}
                  Contacts (Optional)
                </Typography>
                <Typography variant="body1">
                  You can add team members who can provide help for this App
                </Typography>
              </FormRow>
              <FormRow>
                {contactGroups.map((group) => (
                  <ContactFields
                    group={group}
                    companyId={companyId}
                    formData={formData}
                    setFormData={setFormData}
                  />
                ))}
              </FormRow>

            </FormSection>

            <FormSection>
              <FormRow>
                <Typography variant="h6">
                  Add an External Contact (Optional)
                </Typography>
                <Typography variant="body1">
                  External contacts are people outside
                  {' '}
                  {displayName}
                  {' '}
                  who can provide help with this App
                </Typography>
              </FormRow>
              <FormRow>
                {externalContactFields.map((value, index) => (
                  <LinkContainer key={value.info}>
                    <LinkLhs>
                      <IconButton onClick={() => removeExternalContact(index)} title="Remove Link">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </LinkLhs>

                    <LinkRhs>
                      <div style={{ width: '100%' }}>
                        <Controller
                          as={(
                            <Autocomplete
                              autoSelect
                              freeSolo
                              disableClearable
                              getOptionDisabled={(option) => option === 'Choose a Suggestion or Enter Your Own'}
                              options={externalContactSuggestions || []}
                              renderInput={(params) => (
                                <TextField
                                  error={!!errors.externalContacts?.[index]?.type}
                                  {...params}
                                  label="Contact Type"
                                  helperText={errors.externalContacts?.[index]?.type && 'Please provide a contact type'}
                                  size="small"
                                  fullWidth
                                />
                              )}
                            />
                          )}
                          onChange={([, d]) => d}
                          name={`externalContacts[${index}].type`}
                          control={control}
                          rules={{ required: true }}
                          defaultValue={value.type || ''}
                        />
                      </div>

                      <TextField
                        error={!!errors.externalContacts?.[index]?.name}
                        fullWidth
                        size="small"
                        label="Contact Name"
                        defaultValue={value.name || ''}
                        inputRef={register()}
                        helperText={errors.externalContacts?.[index] && 'Please provide a valid contact name'}
                        name={`externalContacts[${index}].name`}
                        autoComplete="off"
                      />

                      <TextField
                        error={!!errors.externalContacts?.[index]?.info}
                        fullWidth
                        size="small"
                        label="Contact Info"
                        defaultValue={value.info || ''}
                        placeholder="Email, Phone, or URL"
                        inputRef={register()}
                        helperText={errors.externalContacts?.[index] && 'Please provide valid contact information'}
                        name={`externalContacts[${index}].info`}
                      />
                    </LinkRhs>
                  </LinkContainer>
                ))}

                <FormRow>
                  <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    onClick={() => appendExternalContact(externalContactDefault)}
                    startIcon={<AddIcon />}
                  >
                    Add an External Contact
                  </Button>
                </FormRow>

              </FormRow>
            </FormSection>

            <FormRow>
              <FormProgressionButtons
                buttonLoading={buttonLoading}
                backwardClick={goBackIntercept}
                formStep={formStep}
              />
            </FormRow>
          </RowsContainer>

        </SingleFormWrapper>
      </form>
    </Fade>
  );
};

export default FormHelpContacts;

const RowsContainer = styled.div`
`;

const FormRow = styled.div`
  margin-bottom: 2rem;
  > div {
    margin-bottom: 1rem;
  }
`;

const FormSection = styled.div`
  max-width: 1000px;
  margin-bottom: 3rem;

  :not(:last-child) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1.5rem;
  }
`;

const LinkContainer = styled.div`
  border: 1px solid #eee;
  border-radius: 5px;
  display: flex;
`;

const LinkLhs = styled.div`
  background-color: #eee;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkRhs = styled.div`
  padding: 2rem 1.5rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  > div {
    :not(:last-child) {
      margin-right: 2rem;
    }
  }
`;
