import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import firebase from '../../config/firebase';

import { EMAIL, SAML } from '../../config/constants';
import { labelInitials } from '../../helpers/strings';
import { structurePassThru } from '../../helpers/form';
import { updateUserDetails } from '../../actions/users';

import ContainedLayout from '../templates/ContainedLayout';
import GetStructureAutocomplete from '../molecules/GetStructureAutocomplete';
import LeadingTitleText from '../atoms/LeadingTitleText';
import Upload from '../molecules/Upload';
import PasswordChangeDialog from '../organisms/PasswordChangeDialog';
import EmailChangeDialog from '../organisms/EmailChangeDialog';
import { showErrorSnackbar, showSnackbar } from '../../actions/snackbar';
import ProgressButton from '../atoms/ProgressButton';
import { getCompany } from '../../actions/company';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
  },
}));

const Settings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [avatarDialog, setAvatarDialog] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [notifyError, setNotifyError] = useState(false);

  const currentUser = useSelector((store) => store.users.currentUser.data);
  const {
    displayName, signInMethod, structure, slackEnabled, structureChildren,
  } = useSelector((store) => store.company.data);
  const { companyId } = useSelector((store) => store.users.currentUser.data);

  const mappedChildren = currentUser.team
    .map((childId) => structureChildren.find(({ id }) => id === childId));

  const togglePasswordChangeDialog = () => setPasswordDialogOpen(!passwordDialogOpen);
  const toggleEmailChangeDialog = () => setEmailDialogOpen(!emailDialogOpen);

  const { email } = firebase.auth().currentUser;

  const getSigninMethodText = () => {
    if (signInMethod === EMAIL) {
      return 'Email Address & Password';
    } if (signInMethod === SAML) {
      return 'Single Sign On';
    }
    return 'Unknown Sign In Method';
  };

  const {
    handleSubmit,
    control,
    errors,
    register,
    formState,
    reset,
  } = useForm({ defaultValues: currentUser });

  const handleAvatarDialog = () => setAvatarDialog(!avatarDialog);

  const updateUser = async (form) => {
    const polishedForm = form;
    polishedForm.team = await structurePassThru(form.team, companyId);

    dispatch(getCompany());

    const countPrefs = Object.keys(form.contactPreferences).reduce((accum, key) => {
      if (form.contactPreferences[key]) accum += 1;
      return accum;
    }, 0);

    if (countPrefs === 0) {
      setNotifyError(true);
      setSaving(false);
      return;
    }

    setSaving(true);
    dispatch(updateUserDetails(polishedForm))
      .then(() => {
        dispatch(showSnackbar('User Profile Saved'));
        setSaving(false);
      })
      .catch(() => {
        dispatch(showErrorSnackbar('There was a problem saving user profile.'));
        setSaving(false);
      });
  };

  useEffect(() => reset(currentUser), [currentUser, reset]);

  return (
    <>
      <form onSubmit={handleSubmit(updateUser)}>

        {/* Header Name and Avatar */}
        <ContainedLayout tinted>
          <Box py={8}>

            <Grid container alignItems="center" spacing={3}>

              {/* Avatar and Name */}
              <Grid container item alignItems="center" direction="column" spacing={2} xs={12} md={3} lg={2}>
                <Grid item>
                  <Avatar className={classes.avatar} src={currentUser.avatar}>
                    {labelInitials(currentUser.name)}
                  </Avatar>
                </Grid>
                <Grid item>
                  <Button onClick={handleAvatarDialog} color="primary" variant="outlined">Change</Button>
                </Grid>
              </Grid>

              {/* Name and Company Name */}
              <Grid item xs={12} md lg>
                <Typography variant="h3">{currentUser.name}</Typography>
                <Typography variant="h5" style={{ fontWeight: 400 }} gutterBottom>{displayName}</Typography>
              </Grid>

            </Grid>
          </Box>
        </ContainedLayout>

        <ContainedLayout>
          <MultiFormsContainer>
            <FormContainer>
              <FormHeader>
                <Typography variant="h6">My Info</Typography>
              </FormHeader>
              <FormContent>
                <FormRow>
                  <TextField fullWidth inputRef={register} name="name" variant="outlined" label="Full Name" />
                </FormRow>
                <FormRow>
                  <TextField fullWidth inputRef={register} name="title" variant="outlined" label="What I Do" />
                </FormRow>
              </FormContent>
            </FormContainer>

            <FormContainer>
              <FormHeader>
                <Typography variant="h6">My Team</Typography>
              </FormHeader>
              <FormContent>
                {structure.filter(({ status }) => status !== 'DELETED').map((s) => (
                  <FormRow key={s.id}>
                    <GetStructureAutocomplete
                      errors={errors}
                      structureItem={s}
                      control={control}
                      name={`team.${s.id}`}
                      fieldKey={'team'}
                      defaultValue={mappedChildren.find((mC = {}) => mC.parentId === s.id)}
                    />
                  </FormRow>
                ))}
              </FormContent>
            </FormContainer>

            <FormContainer>
              <FormHeader>
                <Typography variant="h6" gutterBottom>Notifications</Typography>
                <Typography variant="body1">
                  {`Select how to receive important updates about apps at ${displayName}`}
                </Typography>
              </FormHeader>
              <FormContent>
                {slackEnabled && (
                <FormControl component="fieldset" className={classes.formControl} fullWidth>
                  <FormControlLabel
                    control={(
                      <Controller
                        as={<Checkbox />}
                        control={control}
                        name="contactPreferences.slack"
                      />
                )}
                    name="contactPreferences.slack"
                    label="Receive notifications via Slack"
                  />
                </FormControl>
                )}
                <FormControl component="fieldset" className={classes.formControl} fullWidth>
                  <FormControlLabel
                    control={(
                      <Controller
                        as={<Checkbox />}
                        control={control}
                        name="contactPreferences.email"
                      />
                )}
                    name="contactPreferences.email"
                    label="Receive notifications via Email"
                  />
                </FormControl>
              </FormContent>
            </FormContainer>

            <FormContainer>
              <FormHeader>
                <Typography variant="h6">Role</Typography>
              </FormHeader>
              <FormContent>
                <FormControl variant="outlined" className={classes.formControl} fullWidth error={Boolean(errors.category)}>
                  <InputLabel id="site-role-label">Site Role</InputLabel>
                  <Controller
                    as={(
                      <Select
                        value={currentUser.role}
                        labelId="site-role-label"
                        id="site-role"
                        label="Category"
                        disabled
                      >
                        <MenuItem disabled value="">Select a Role</MenuItem>
                        <MenuItem value={10}>Administrator</MenuItem>
                        <MenuItem value={1}>Contributor</MenuItem>
                        {/*<MenuItem value={0}>Viewer</MenuItem>*/}
                      </Select>
                        )}
                    name="role"
                    rules={{ required: 'Please select a valid role for this user' }}
                    control={control}
                    defaultValue={0}
                  />
                  <FormHelperText>
                    {/* TODO: Change this to be a description of the roles and read from FB */}
                    {errors.role ? errors.role.message : ''}
                  </FormHelperText>
                </FormControl>

              </FormContent>
            </FormContainer>

            <FormContainer>
              <FormHeader>
                <Typography variant="h6">Signing In</Typography>
              </FormHeader>
              <FormContent>

                {/* Method */}
                {/* TODO read from slug */}
                <LeadingTitleText heading="Sign In Method">
                  <Typography variant="body1" gutterBottom>{getSigninMethodText()}</Typography>
                </LeadingTitleText>

                {/* Email */}
                <FormRow>
                  <LeadingTitleText heading="Primary Email">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body1" component="div">{email}</Typography>
                      <Button color="primary" onClick={toggleEmailChangeDialog}>Change</Button>
                    </div>
                    {currentUser.pendingEmail ? (
                      <Typography color="textSecondary" variant="body2">
                        {currentUser.pendingEmail}
                        {' '}
                        (Pending Verification)
                      </Typography>
                    ) : null}
                  </LeadingTitleText>
                </FormRow>

                <FormRow>
                  {(signInMethod === EMAIL) && (
                  <LeadingTitleText heading="Password">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body1" component="div">
                        <span>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
                      </Typography>
                      <Button color="primary" onClick={togglePasswordChangeDialog}>Change</Button>
                    </div>
                  </LeadingTitleText>
                  )}
                </FormRow>
              </FormContent>
            </FormContainer>

            <ProgressButton
              cta="Save Changes"
              disabled={!formState.dirty}
              endIcon={<> </>}
              loading={saving}
            />
            {notifyError && (
              <Typography variant="body1" color="error" component="div">
                At least one notification method must be selected.
              </Typography>
            )}

          </MultiFormsContainer>
        </ContainedLayout>
      </form>
      <Upload
        header="Edit Avatar"
        dialog={avatarDialog}
        handleUploadDialog={handleAvatarDialog}
        icon={currentUser.avatar}
        text={currentUser.name}
        avatar
      />

      {/* Dialog To Change Email */}
      <EmailChangeDialog
        emailDialogOpen={emailDialogOpen}
        toggleEmailChangeDialog={toggleEmailChangeDialog}
      />

      {/* Dialog To Change Password */}
      <PasswordChangeDialog
        passwordDialogOpen={passwordDialogOpen}
        togglePasswordChangeDialog={togglePasswordChangeDialog}
      />
    </>
  );
};

export default Settings;

const MultiFormsContainer = styled.div`
  padding: 1rem;
  max-width: 600px;
`;

const FormContainer = styled.div`
  margin-bottom: 2rem;
`;

const FormHeader = styled.div`
  margin-bottom: 1rem;
`;

const FormContent = styled.div``;

const FormRow = styled.div`
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
`;
