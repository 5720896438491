import React, { useState } from 'react';
import { Typography, TextField } from '@material-ui/core';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import { useSelector, useDispatch } from 'react-redux';
import ContainedLayout from '../../../templates/ContainedLayout';
import ProgressButton from '../../../atoms/ProgressButton';
import { updateCompanyDetails } from '../../../../actions/company';
import { showErrorSnackbar, showSnackbar } from '../../../../actions/snackbar';

const Company = () => {
  const dispatch = useDispatch();
  const [manualError] = useState(false);
  const [saving, setSaving] = useState(false);

  const { displayName } = useSelector((state) => state.company.data);
  const company = useSelector((state) => state.company.data);

  const {
    handleSubmit, formState, register, errors,
  } = useForm({
    defaultValues: company,
  });

  const handleCompanyUpdate = (data) => {
    setSaving(true);
    dispatch(updateCompanyDetails(data))
      .then(() => {
        setSaving(false);
        dispatch(showSnackbar('Company details saved'));
      })
      .catch(() => {
        dispatch(
          showErrorSnackbar(
            `There was an error updating ${displayName} settings`,
          ),
        );
      });
  };

  if (manualError) {
    // TODO: STYLING need to show something here
    return <div>Failed to load...</div>;
  }

  return (
    <form onSubmit={handleSubmit(handleCompanyUpdate)}>
      <ContainedLayout>
        <MultiFormsContainer>
          <FormContainer>
            <FormHeader>
              <Typography variant="h6">Company Name</Typography>
            </FormHeader>
            <FormContent>
              <FormRow>
                <TextField
                  fullWidth
                  inputRef={register({ required: true })}
                  name="name"
                  error={!!errors.name}
                  variant="outlined"
                  helperText={errors.name && 'Enter a Name'}
                  label="Company Legal Name"
                />
              </FormRow>
              <FormRow>
                <TextField
                  fullWidth
                  inputRef={register({ required: true })}
                  name="displayName"
                  variant="outlined"
                  label="Display Name"
                  error={!!errors.displayName}
                  helperText={
                    errors.displayName ? 'Enter a Display Name'
                      : `The name to show throughout Brodi. Eg: Apps at ${company.displayName}`
                  }
                />
              </FormRow>
            </FormContent>
          </FormContainer>

          <ProgressButton
            cta="Save Changes"
            disabled={!formState.dirty}
            endIcon={<> </>}
            loading={saving}
          />
        </MultiFormsContainer>
      </ContainedLayout>
    </form>
  );
};

export default Company;

const MultiFormsContainer = styled.div`
  padding: 1rem;
  max-width: 600px;
`;

const FormContainer = styled.div`
  margin-bottom: 2rem;
`;

const FormHeader = styled.div`
  margin-bottom: 1rem;
`;

const FormContent = styled.div``;

const FormRow = styled.div`
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
`;
