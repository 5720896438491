import React from 'react';

import {
  Grid, Typography, Fade, Button,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SimulatedAppPage from '../SimulatedAppPage';

const AppAdded = () => {
  const location = useLocation();
  const displayName = useSelector((store) => store.company.data.displayName);

  let id = '';
  let name = '';
  let icon = '';

  if (location.state) {
    id = location.state.id;
    name = location.state.name;
    icon = location.state.icon;
  }

  return (
    <Fade in>
      <Grid container justify="space-between" alignItems="flex-start" spacing={5}>
        <Grid container item xs={12} md={6} direction="column" spacing={6}>
          <Grid container item direction="column" spacing={4}>

            {/* Header */}
            <Grid item>
              <Typography gutterBottom variant="h2" style={{ fontWeight: 600 }}>That&apos;s it!</Typography>
              <Typography variant="h5" style={{ fontWeight: 400 }}>
                {`You added ${name} and it's now available for everyone to see at ${displayName}.`}
              </Typography>
            </Grid>

            {/* Open App Button */}
            <Grid item>
              <Button
                variant="contained"
                disableElevation
                color="secondary"
                type="submit"
                component={Link}
                to={`/app/${id}/${name}`}
                size="large"
                endIcon={<ChevronRightIcon />}
              >
                {`Go To ${name}`}
              </Button>
            </Grid>

          </Grid>
        </Grid>

        {/* Animated App Page */}
        <Grid item xs={12} md={6}>
          <SimulatedAppPage animate name={name} icon={icon} />
        </Grid>

      </Grid>
    </Fade>
  );
};

export default AppAdded;
