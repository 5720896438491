import React from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { resolveCategoryId } from '../../helpers/resolvers';

const CategorySearchRefinement = ({
  items, refine, searchState = [],
}) => {
  const { categories } = useSelector((store) => store.client.data);
  return (
    <Select
      isMulti
      placeholder="All Categories"
      noOptionsMessage={() => 'No Categories for this Search'}
      options={items.filter(({ isRefined = false }) => isRefined === false)}
      getOptionValue={(option) => option.label}
      getOptionLabel={(option) => `${resolveCategoryId(option.label, categories)} ${(option.count > 0) ? `(${option.count})` : ''}`}
      name="category"
      defaultValue={searchState.map((i) => ({ label: i }))}
      filterOption={(options) => options}
      onChange={(v) => {
        if (v) refine(v.map(({ label }) => label));
        else refine([]);
      }}
    />
  );
};

export default CategorySearchRefinement;
