import { GET } from '../config/constants';
import { getCompany } from './company';
import { getCurrentUser } from './users';
import { brodiAuthedRequest } from '../helpers/backend';

export const SET_CLIENT_READY = 'SET_CLIENT_READY';
export const SET_CLIENT_NOT_READY = 'SET_CLIENT_NOT_READY';
export const GET_GLOBAL_CONSTS_SUCCESS = 'GET_GLOBAL_CONSTS_SUCCESS';

export const setClientNotReady = () => (dispatch) => {
  dispatch({ type: SET_CLIENT_NOT_READY });
};

export const getConstants = () => async (dispatch) => {
  const payload = await brodiAuthedRequest(GET, '/general/constants');
  dispatch({ type: GET_GLOBAL_CONSTS_SUCCESS, payload });
};

export const initializeBaseData = () => async (dispatch) => {
  await dispatch(getCurrentUser());
  await dispatch(getCompany());
  await dispatch(getConstants());
  dispatch({ type: SET_CLIENT_READY });
};
