import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { labelInitials, truncate } from '../../helpers/strings';
import { getApp } from '../../actions/apps';
import { APP_AVAILABILITY_STATUS_MAPS } from '../../config/constants';

const AppInfoRow = ({
  appId,
  payload = {}, // If passed, no API call
  onClickEvent = () => {},
}) => {
  const dispatch = useDispatch();

  const {
    icon, name, availability: { status, updated },
  } = useSelector((store) => store.apps.cachedApps.data[appId]) || payload;

  useEffect(() => {
    if (Object.keys(payload).length) return;
    dispatch(getApp(appId));
  }, [appId, payload, dispatch]);

  return (
    <IconContainer onClick={() => onClickEvent()}>
      {(icon ? (
        <Icon alt={`Logo for ${name}`} src={icon} />
      ) : (
        <LetterIcon>{labelInitials(name)}</LetterIcon>
      ))}
      <div style={{ marginLeft: '1rem' }}>
        <Typography component="div" title={name} variant="body2" style={{ fontWeight: 600 }}>
          {truncate(name, 30)}
        </Typography>
        {status && (
          <Typography component="span" color="textSecondary" variant="body2" style={{ fontWeight: 500 }}>
            {APP_AVAILABILITY_STATUS_MAPS[status]?.title || 'Possible Issue'}
          </Typography>
        )}
        {(status && updated?._seconds)
          && (
          <Typography component="span" color="textSecondary" variant="body2" style={{ fontWeight: 500 }}>
            &nbsp;-&nbsp;
          </Typography>
          )}
        {updated?._seconds && (
          <Typography
            component="span"
            color="textSecondary"
            variant="body2"
            style={{ fontWeight: 500 }}
            title={DateTime.fromSeconds(updated?._seconds).toLocaleString(DateTime.DATETIME_HUGE)}
          >
            {DateTime.fromSeconds(updated?._seconds).toRelative()}
          </Typography>
        )}
      </div>
    </IconContainer>
  );
};
export default AppInfoRow;

const IconContainer = styled.div`
  display: flex;
  border-radius: 10px;
  flex-direction: row;
  width: 100%;
  text-align: left;
  align-items: center;
  padding: 1rem;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.17);
  background-color: #fff;
  &:hover {
    cursor: pointer;
    font-weight: 500;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.25);
  };
`;

const LetterIcon = styled.div`
  background-color: #EEE;
  align-items: center;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  height: 50px;
  width: 50px;
  color: #000;
  background-color: #EEE;
  margin-bottom: 5px;
`;

const Icon = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 5px;
  object-fit: cover;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px;
`;
