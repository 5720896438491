/* eslint-disable eqeqeq */
import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { useHistory } from 'react-router-dom'

const FormStep = ({ name, completed, inProgress, onClick }) => (
  <ProgressItem completed={completed} inProgress={inProgress} onClick={completed ? onClick : undefined}>
    <div>
      {completed ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
    </div>
    <Typography variant="body2" style={{ fontWeight: 600, paddingBottom: '3px' }} component="div">
      {name}
    </Typography>
  </ProgressItem>
);

const FormProgressIndicator = ({ formConfig = [], signatures = {}, formStep }) => {
  const history = useHistory();

  return (
    <Container>
      {formConfig.map((step, idx) => (
        <FormStep
          key={step.tabName}
          name={step.tabName}
          completed={!!signatures[idx]}
          inProgress={idx == formStep}
          onClick={() => history.push(`/add/${idx}`)}
        />
      ))}
    </Container>
  )
};
export default FormProgressIndicator;

const Container = styled.div`
  display: flex;
  background-color: #fafafa;
  padding: 1rem 2rem;
  margin: 2rem auto;
  min-width: 70%;
  width: max-content;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  > div {
    :not(:last-child) {
      margin-right: 2rem;
    }
  }
`;

const ProgressItem = styled.div`
  border-radius: 5px;
  padding: 1rem 1.5rem;
  background: #e0e0e0;
  color: #898989;
  ${(({ completed }) => completed && `
    background: #4E27FF;
    color: white;
    cursor: pointer;
  `)}

  ${(({ inProgress }) => inProgress && `
    background: #4E27FF;
    color: white;
  `)}
  
  transition: background 0.25s ease-in-out;
  
  display: flex;
  align-items: center;
  > div {
    :not(:last-child) {
      margin-right: 0.5rem;
    }
  }
`;
