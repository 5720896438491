import React, { useState } from 'react';

import styled from 'styled-components';
import EditIcon from '@material-ui/icons/Edit';
import { Typography } from '@material-ui/core';
import Upload from '../Upload';
import { labelInitials } from '../../../helpers/strings';

export default ({
  appId,
  name,
  icon,
}) => {
  const [openIcon, setOpenIcon] = useState(false);
  const handleIconDialog = () => setOpenIcon(!openIcon);
  return (
    <>
      <Container onClick={handleIconDialog}>
        <EditOverlay>
          <div>
            <EditIcon />
          </div>
          <Typography variant="body2" style={{ fontWeight: 600, marginRight: '5px' }} component="div">Edit</Typography>
        </EditOverlay>
        {(icon ? (
          <Icon alt="logo" src={icon} />
        ) : (
          <LetterIcon colorScheme={{ backgroundColor: '#EEE', color: '#000' }}>
            {labelInitials(name)}
          </LetterIcon>
        ))}

      </Container>

      <Upload
        header="Edit App Icon"
        appId={appId}
        dialog={openIcon}
        handleUploadDialog={handleIconDialog}
        icon={icon}
        text={name}
      />
    </>
  );
};

const Container = styled.div`
  cursor: pointer;
  position: relative;

  height: 100px;
  width: 100px;
`;

const EditOverlay = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  background-color: rgb(0, 0, 0, 0.6);
  color: white;
  opacity: 0;
  transition: opacity 0.10s ease-in-out;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 1;
  }
`;

const Icon = styled.img`
  height: 100px;
  width: 100px;
  border-radius: 5px;
  object-fit: cover;
  background-color:transparent;

`;

const LetterIcon = styled.div`
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.23);
  background-color: #EEE;
  align-items: center;
  border-radius: 5px;
  display: flex;
  font-size: 40px;
  font-weight: 600;
  height: 100px;
  width: 100px;
  justify-content: center;
  ${(props) => `
  background-color: ${props.colorScheme.backgroundColor};
  color: ${props.colorScheme.fontColor};
  `});
`;
