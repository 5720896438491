import { combineReducers } from 'redux';
import client from './client';
import auth from './auth';
import company from './company';
import apps from './apps';
import users from './users';
import snackbar from './snackbar';

export default combineReducers({
  client,
  auth,
  company,
  apps,
  users,
  snackbar,
});
