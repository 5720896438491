import React, { useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';

import CollapsibleItemPanel from '../../organisms/CollapsableItemPanel';
import LeadingTitleText from '../../atoms/LeadingTitleText';
import EditResponsibleTeam from '../AppPageEditDialogs/EditResponsibleTeam';
import { getApp } from '../../../actions/apps';

export default ({
  data, appId,
}) => {
  const dispatch = useDispatch();
  const { structure, structureChildren } = useSelector((store) => store.company.data);

  const [open, setOpen] = useState(false);
  const handleOwnerDialog = () => {
    dispatch(getApp(appId, true)).then(() => {
      setOpen(!open);
    });
  };

  const menuItems = [
    {
      label: 'Edit Responsible Team',
      action: handleOwnerDialog,
    },
  ];

  return (
    <CollapsibleItemPanel heading="Responsible Team" menuItems={menuItems} key="responsible_team">
      <StructureContainer>
        {data.map((c) => {
          const child = structureChildren.find(({ id }) => id === c) || {};
          const parent = structure.find(({ id }) => id === child.parentId) || {};

          return (
            <StructureItem key={`${child.parentId}-${child.id}`}>
              <LeadingTitleText heading={parent.name} spacing={0}>
                {child.name}
                {child.status === 'DELETED' && ' (Deleted)'}
              </LeadingTitleText>
            </StructureItem>
          );
        })}
      </StructureContainer>

      <EditResponsibleTeam
        appId={appId}
        handleOwnerDialog={handleOwnerDialog}
        open={open}
        data={data}
        structure={structure}
      />
    </CollapsibleItemPanel>
  );
};

const StructureContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

const StructureItem = styled.div`
  margin-right: 100px;
`;
