import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import CenteredLayout from '../templates/CenteredLayout';
import { editSlackToken } from '../../actions/admin';
import Loading from '../../images/Loading.svg';

const VerifySlack = () => {
  const loading = () => (
    <CenteredLayout>
      <img src={Loading} alt="loading" />
    </CenteredLayout>
  );
  const cid = useSelector((store) => store.users.currentUser.data.companyId);
  const [redirect, setRedirect] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    if (cid === undefined) return;
    if (state !== cid) return;
    axios({
      method: 'GET',
      url: `https://slack.com/api/oauth.v2.access?code=${code}&client_id=1060352034884.1375378235890&client_secret=cc7928349f542dca989710d4ce73f402`,
    }).then((res) => {
      dispatch(editSlackToken(res.data.access_token, true)).then(() => {
        setRedirect(true);
      });
    });
  }, [cid, dispatch]);
  if (redirect) return (<Redirect to="/manage/company" />);
  return loading();
};

export default VerifySlack;
