import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Grid,
  LinearProgress,
  Avatar,
  makeStyles,
} from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import DeleteIcon from '@material-ui/icons/Delete';
import { editApp, uploadAppIcon } from '../../actions/apps';
import { labelInitials } from '../../helpers/strings';
import { checkIcon } from '../../helpers/files';
import { updateProfilePicture, uploadUserAvatar } from '../../actions/users';
import { showSnackbar } from '../../actions/snackbar';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  largeAvatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const Upload = ({
  appId, handleUploadDialog, dialog, icon, text, header, avatar,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    reset,
  } = useForm();

  const [errorMessage, setErrorMessage] = useState(null);
  const [parkedImage, setParkedImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [saving, setSaving] = useState(false);

  const handleFormCancel = () => {
    handleUploadDialog();
    setPreview(null);
    setErrorMessage(null);
    reset();
  };

  const handleRemoveImage = async () => {
    if (avatar) {
      // User avatar
      if (window.confirm('Delete your profile image?')) {
        setSaving(true);
        await dispatch(updateProfilePicture({ avatar: '' }));

        dispatch(showSnackbar('User Profile Saved'));
        handleUploadDialog();
        setSaving(false);
      }
    } else {
      // App icon
      if (window.confirm('Delete this App\'s icon?')) {
        setSaving(true);
        await dispatch(editApp({ icon: '' }, appId));
        dispatch(showSnackbar('Changes Saved'));
        handleUploadDialog();
        setSaving(false);
      }
    }
  };

  const handleIconPreview = (e) => {
    try {
      if (!e.target.files[0]) throw new Error('Cannot find image.');
      checkIcon(e.target.files[0]);
      const previewUrl = URL.createObjectURL(e.target.files[0]);
      setPreview(previewUrl);
      setParkedImage(e.target.files[0]);
      setErrorMessage(null);
    } catch (err) {
      setPreview(null);
      setErrorMessage(err.message);
      reset();
    }
  };

  const update = async () => {
    // TODO: Compress images ourselves
    try {
      setSaving(true);
      checkIcon(parkedImage);
      try {
        if (avatar) {
          // User avatar
          const downloadUrl = await dispatch(uploadUserAvatar(parkedImage));
          await dispatch(updateProfilePicture({ avatar: downloadUrl }));
          dispatch(showSnackbar('User Profile Saved'));
          handleUploadDialog();
          setSaving(false);
        } else {
          // App icon
          const downloadUrl = await dispatch(uploadAppIcon(parkedImage));
          await dispatch(editApp({ icon: downloadUrl }, appId));
          dispatch(showSnackbar('Changes Saved'));
          handleUploadDialog();
          setSaving(false);
        }
      } catch (err) {
        setPreview(null);
        setSaving(false);
        setErrorMessage('There was a problem uploading. Try again.');
      }
    } catch (err) {
      setPreview(null);
      setSaving(false);
      setErrorMessage(err.message);
    }
  };

  return (
    <Dialog
      open={dialog}
      onClose={handleUploadDialog}
      fullWidth
    >
      {(saving) && (<LinearProgress />)}
      <form onSubmit={handleSubmit(update)}>
        <DialogTitle id="form-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>

            {/* Icon Previews */}
            <Grid container direction="column" spacing={2} justify="center" alignItems="center">

              {/* Current Icon Preview */}
              <Grid container item xs={12} direction="column" alignItems="center" spacing={1}>
                <Grid item>
                  <Avatar
                    variant="square"
                    className={classes.largeAvatar}
                    src={preview || icon}
                  >
                    <Typography variant="h4">{labelInitials(text)}</Typography>
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography align="center" variant="overline" color="textSecondary">PREVIEW</Typography>
                </Grid>
              </Grid>

              {/* Upload Button */}
              <Grid item xs>

                <ActionRow>
                  <div>
                    <label htmlFor="contained-button-file">
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        ref={register()}
                        name="icon"
                        type="file"
                        onChange={handleIconPreview}
                        style={{ display: 'none' }}
                      />
                      <Button component="span" variant="outlined" size="medium" startIcon={<BackupIcon />}>Upload</Button>
                    </label>
                  </div>
                  <div>
                    <Button onClick={handleRemoveImage} component="span" variant="outlined" size="medium" startIcon={<DeleteIcon />}>Remove Image</Button>
                  </div>
                </ActionRow>
              </Grid>
            </Grid>

            {/* Error Message */}
            <Grid item>
              <Typography variant="body2" color="error">{errorMessage}</Typography>
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>

          <Button onClick={handleFormCancel} disabled={saving}>
            <span>Cancel</span>
          </Button>

          <Button color="primary" type="submit" disabled={!preview || saving}>
            <span>Upload</span>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Upload;

const ActionRow = styled.div`
  display: flex;
  > div {
    :not(:last-child) {
      margin-right: 1rem;
    }
  }
`;
