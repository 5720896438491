import React, { useState } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import TrialDialog from './dialog';

const TrialChip = ({ plan = {} }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialog = () => setDialogOpen(!dialogOpen);
  const { endDate = {} } = plan;

  const end = DateTime.fromSeconds(endDate._seconds || 0);
  const days = Math.round(end.diff(DateTime.local(), ['days'])?.days || 1);

  return (
    <>
      <TrialCounter onClick={handleDialog}>
        <Typography variant="body2" style={{ fontWeight: 700 }}>
          {days}
          {' '}
          {days === 1 ? 'Day' : 'Days'}
          {' '}
          Left
        </Typography>
      </TrialCounter>
      <TrialDialog
        dialogOpen={dialogOpen}
        handleDialog={handleDialog}
        plan={plan}
      />
    </>
  );
};
export default TrialChip;

const TrialCounter = styled.div`
  cursor: pointer;
  background-color: #FFDA00;
  border-radius: 5px;
  padding: 0.5rem 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
