import React from 'react';
import ContentLoader from 'react-content-loader';

const SingleStatLoader = (props) => (
  <ContentLoader
    width={400}
    height={180}
    viewBox="0 0 400 180"
    backgroundColor="#fafafa"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="7" y="1" rx="20" ry="20" width="377" height="173" />
  </ContentLoader>
);

export default SingleStatLoader;
