import React from 'react';

import {
  TextField, Button, Typography,
} from '@material-ui/core';
import styled from 'styled-components';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import LogoSvg from '../../../images/logo.svg';

const GetEmail = ({
  register,
  handleSubmit,
  handleEmailCheckSubmit,
  processing,
  formError,
  defaultValue
}) => (
  <Container>
    <LogoContainer>
      <img src={LogoSvg} alt="logo" />
    </LogoContainer>
    <TitleContainer>
      <Typography variant="h4" align="center">Your work email, please</Typography>
    </TitleContainer>
    <form onSubmit={handleSubmit(handleEmailCheckSubmit)}>
      <Typography variant="body2" align="center" style={{ color: 'red', margin: '.75rem 0' }}>
        {formError}
      </Typography>
      <TextFieldContainer>
        <TextField
          autoFocus
          autoComplete="username"
          name="username"
          disabled={Boolean(processing)}
          variant="outlined"
          label="Username"
          type="text"
          id="username"
          fullWidth
          inputRef={register}
          defaultValue={defaultValue}
        />
      </TextFieldContainer>
      <ButtonContainer>
        <Button
          variant="contained"
          disableElevation
          disabled={Boolean(processing)}
          color="primary"
          type="submit"
          size="large"
          endIcon={<ChevronRightIcon />}
        >
          Continue
        </Button>
      </ButtonContainer>
    </form>
  </Container>
);

export default GetEmail;

const Container = styled.div``;

const LogoContainer = styled.div`
  margin: 1.5rem 0;
  text-align: center;
`;

const TitleContainer = styled.div``;

const TextFieldContainer = styled.div`
  margin: 2rem auto;
  max-width: 500px;
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
  text-align: right;
`;
