import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  DialogContent,
  DialogContentText,
  TextField,
  Dialog,
  DialogActions, LinearProgress, IconButton, Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { urlRegex } from '../../../helpers/url';
import { editApp } from '../../../actions/apps';
import { showSnackbar } from '../../../actions/snackbar';

const linksDefaultInput = { displayName: '', url: '' };

export default ({
  appId, data, handleLinksDialog, openLinks,
}) => {
  const dispatch = useDispatch();
  const [manualError, setManualError] = useState(false);

  const {
    control,
    errors,
    handleSubmit,
    register,
    reset,
    formState,
  } = useForm({
    defaultValues: { links: data },
  });

  const { fields, append, remove } = useFieldArray({
    control, name: 'links',
  });

  useEffect(() => {
    const links = [];
    links.push(...data);

    if (!data.length) links.push(linksDefaultInput);

    reset({ links });
  }, [data, reset]);

  const handleFormCancel = () => {
    handleLinksDialog();
    reset({ links: data });
  };

  const updateLink = (formData) => new Promise((resolve, reject) => {
    setManualError(false);
    if (!Object.keys(formData).length) {
      dispatch(editApp({ links: [] }, appId))
        .then(() => {
          resolve();
          dispatch(showSnackbar('Changes Saved'));
          handleLinksDialog();
        })
        .catch(() => {
          setManualError(true);
          reject();
        });
    } else {
      dispatch(editApp(formData, appId))
        .then(() => {
          resolve();
          dispatch(showSnackbar('Changes Saved'));
          handleLinksDialog();
        })
        .catch(() => {
          setManualError(true);
          reject();
        });
    }
  });

  const linkSuggestions = useSelector((store) => store.client.data.linkAutoSuggestions);
  const linkSuggestionsWithHelper = [
    'Choose a Suggestion or Enter Your Own', // disabled
    ...linkSuggestions,
  ]

  return (
    <Dialog
      open={openLinks}
      onClose={handleLinksDialog}
      fullWidth
      maxWidth="sm"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(updateLink)}>
        {
          (formState.isSubmitting) && (
            <LinearProgress />
          )
        }
        <DialogActionTitle>
          <Typography variant="h6" component="div">
            Edit App Links
          </Typography>

          <DialogAction>
            <IconButton onClick={() => append(linksDefaultInput)} title="Add New Link">
              <AddIcon />
            </IconButton>
          </DialogAction>

        </DialogActionTitle>
        <DialogContent>
          {fields.map((value, index) => (
            <LinkContainer key={value.url}>
              <LinkLhs>

                <IconButton onClick={() => remove(index)} title="Remove Link" disabled={fields.length === 1}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
              </LinkLhs>

              <LinkRhs>
                <FieldRow>
                  <Controller
                    as={(
                      <Autocomplete
                        autoSelect
                        freeSolo
                        getOptionDisabled={(option) => option === 'Choose a Suggestion or Enter Your Own'}
                        disableClearable
                        options={linkSuggestionsWithHelper || []}
                        renderInput={(params) => (
                          <TextField
                            error={!!errors.links?.[index]?.displayName}
                            {...params}
                            label="Display Name"
                            helperText={errors.links?.[index]?.displayName && 'Please provide a display name or remove this link'}
                            size="small"
                            fullWidth
                          />
                        )}
                      />
                            )}
                    onChange={([, d]) => d}
                    name={`links[${index}].displayName`}
                    control={control}
                    rules={{ required: true }}
                    defaultValue={value.displayName || ''}
                  />
                </FieldRow>

                <TextField
                  error={!!errors.links?.[index]?.url}
                  fullWidth
                  size="small"
                  label="URL"
                  defaultValue={value.url || ''}
                  inputRef={register({ required: true, pattern: urlRegex })}
                  helperText={errors.links?.[index] && 'Please provide a valid URL or remove this link'}
                  name={`links[${index}].url`}
                  autoComplete="off"
                />
              </LinkRhs>
            </LinkContainer>
          ))}
          {manualError ? (
            // TODO: STYLING: This needs better styling
            <DialogContentText color="error">
              There was a problem changing the app links.
            </DialogContentText>
          ) : null}
        </DialogContent>

        <DialogActions>
          <Button disabled={formState.isSubmitting} onClick={handleFormCancel}>
            <span>Cancel</span>
          </Button>
          <Button color="primary" type="submit" disabled={!formState.dirty || formState.isSubmitting}>
            <span>Save Changes</span>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const LinkContainer = styled.div`
  border: 1px solid #eee;
  margin-bottom: 20px;
  display: flex;
`;

const LinkLhs = styled.div`
  width: 15%;
  padding: 10px;
  background-color: #eee;
  text-align: center;
`;
const LinkRhs = styled.div`
width: 85%;
padding: 25px;
`;

const FieldRow = styled.div`
  margin-bottom: 20px;
`;

const DialogActionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

const DialogAction = styled.div``;
