export default class User {
  constructor(
    name = '',
    avatar = null,
    following = [],
    recentAppps = [],
  ) {
    this.name = name;
    this.avatar = avatar;
    this.following = following;
    this.recentAppps = recentAppps;
  }
}
