import React from 'react';
import {
  Typography, Button,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Error = ({ type }) => {
  if (type === 404) {
    return (
      <Container>
        <ContentContainer>
          <Typography gutterBottom variant="h4">We can't find what you're looking for.</Typography>
          <Typography variant="h6">
            Sorry, there was a problem and we can't show you the page you requested.
          </Typography>
          <Typography variant="h6">
            It may have been deleted or moved somewhere else.
          </Typography>
          <CallToAction>
            <Button size="large" variant="contained" disableElevation component={Link} to="/">
              GO BACK TO YOUR HOMEPAGE
            </Button>
          </CallToAction>
        </ContentContainer>
      </Container>
    );
  }

  return (
    <Container>
      <ContentContainer>
        <Typography gutterBottom align="center" variant="h4">Uh oh. There was a problem.</Typography>
        <Typography align="center" variant="body1">It looks like we've run into an issue</Typography>
        <Typography align="center" variant="body1">We've sent an alert to our Development Team to take a look.</Typography>

        <CallToAction>
          <Button size="large" variant="contained" disableElevation component={Link} to="/">
            GO TO YOUR HOMEPAGE
          </Button>
        </CallToAction>
      </ContentContainer>
    </Container>
  );
};

export default Error;

const Container = styled.div``;

const ContentContainer = styled.div`
  padding-top: 5rem;
  text-align: center;
`;

const CallToAction = styled.div`
  margin-top: 2rem;
`;
