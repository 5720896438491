import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { EMAIL, SAML } from '../../../config/constants';
import { labelInitials } from '../../../helpers/strings';
import { getSingleUser } from '../../../actions/users';
import { updateUserDetailsAdmin } from '../../../actions/admin';

import ContainedLayout from '../../templates/ContainedLayout';
import LeadingTitleText from '../../atoms/LeadingTitleText';
import DisableAccountDialog from '../../organisms/DisableAccountDialog';
import EmailChangeAdminDialog from '../../organisms/EmailChangeAdminDialog';
import { showErrorSnackbar, showSnackbar } from '../../../actions/snackbar';
import ProgressButton from '../../atoms/ProgressButton';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
  },
}));

const Settings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { userId } = useParams();

  const [disableDialog, setDisableDialog] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [manualError, setManualError] = useState(false);
  const [saving, setSaving] = useState(false);

  const currentUser = useSelector((store) => store.users.cachedUsers.data[userId]);
  const displayName = useSelector((store) => store.company.data.displayName);
  const signInMethod = useSelector((store) => store.company.data.signInMethod);
  const { structure, structureChildren } = useSelector((store) => store.company.data);

  const toggleDisableDialog = () => setDisableDialog(!disableDialog);
  const toggleEmailChangeDialog = () => setEmailDialogOpen(!emailDialogOpen);

  const getSigninMethodText = () => {
    if (signInMethod === EMAIL) {
      return 'Email Address & Password';
    } if (signInMethod === SAML) {
      return 'Single Sign On';
    }
    return 'Unknown Signin Method';
  };

  useEffect(() => {
    setManualError(false);
    dispatch(getSingleUser(userId))
      .catch(() => {
        setManualError(true);
        dispatch(showErrorSnackbar('There was a problem getting user.'));
      });
  }, [userId, dispatch]);

  const {
    handleSubmit,
    control,
    errors,
    formState,
    reset,
  } = useForm({ defaultValues: currentUser });

  const updateUser = (fields) => {
    setSaving(true);
    dispatch(updateUserDetailsAdmin(userId, { role: fields.role }))
      .then(() => {
        dispatch(showSnackbar('User Profile Saved'));
        setSaving(false);
      })
      .catch(() => {
        dispatch(showErrorSnackbar('There was a problem changing user settings.'));
        setSaving(false);
      });
  };

  useEffect(() => reset(currentUser), [currentUser, reset]);

  // TODO: STYLING need to show something here
  if (!currentUser && !manualError) return <div />;

  if (manualError) {
    // TODO: STYLING need to show something here
    return (
      <div>Failed to load...</div>
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit(updateUser)}>

        {/* Header Name and Avatar */}
        <ContainedLayout tinted>
          <Box py={8}>

            <Grid container alignItems="center" spacing={3}>

              {/* Avatar and Name */}
              <Grid item xs={12} md={3} lg={2}>
                <Avatar className={classes.avatar} src={currentUser.avatar}>
                  {labelInitials(currentUser.name)}
                </Avatar>
              </Grid>

              {/* Name and Company Name */}
              <Grid item xs={12} md lg>
                <Typography variant="h3">{currentUser.name}</Typography>
                <Typography variant="h5" style={{ fontWeight: 400 }} gutterBottom>{displayName}</Typography>
              </Grid>

            </Grid>
          </Box>
        </ContainedLayout>

        <ContainedLayout>
          <Box py={5}>
            <Grid container item direction="column" spacing={4} xs={12} sm={6}>

              <Grid container item direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6">Info</Typography>
                </Grid>
                <Grid container item direction="column" spacing={3}>
                  <Grid item>
                    <LeadingTitleText heading="Full Name">
                      <Typography variant="body1">{currentUser.name}</Typography>
                    </LeadingTitleText>
                  </Grid>
                </Grid>
              </Grid>

              {currentUser.team && (
                <Grid container item direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h6">Team</Typography>
                  </Grid>
                  <Grid container item direction="column" spacing={3}>
                    {structure.filter(({ status }) => status !== 'DELETED').map((s) => (
                      <Grid item key={s.id}>
                        <LeadingTitleText heading={s.name}>
                          <Typography variant="body1">
                            {structureChildren.find(({ parentId, status }) => parentId === s.id && status !== 'DELETED').name}
                          </Typography>
                        </LeadingTitleText>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}

              <Grid container item direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6">Role</Typography>
                </Grid>
                <Grid container item direction="column" spacing={3}>
                  <Grid item>
                    <FormControl variant="outlined" className={classes.formControl} fullWidth error={Boolean(errors.category)}>
                      <InputLabel id="site-role-label">Site Role</InputLabel>
                      <Controller
                        as={(
                          <Select
                            value={currentUser.role}
                            labelId="site-role-label"
                            id="site-role"
                            label="Category"
                          >
                            {/* TODO: what roles do we have */}
                            <MenuItem disabled value="">Select a Role</MenuItem>
                            <MenuItem value={10}>Administrator</MenuItem>
                            <MenuItem value={1}>Contributor</MenuItem>
                            {/*<MenuItem value={0}>Viewer</MenuItem>*/}
                          </Select>
                        )}
                        name="role"
                        rules={{ required: 'Please select a valid role for this user' }}
                        control={control}
                        defaultValue={0}
                      />
                      <FormHelperText>
                        {/* TODO: Change this to be a description of the roles and read from FB */}
                        {errors.role ? errors.role.message : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="h6">Signing In</Typography>
                </Grid>
                <Grid container item direction="column" spacing={3}>

                  {/* Method */}
                  <Grid item>
                    {/* TODO read from slug */}
                    <LeadingTitleText heading="Sign In Method">
                      <Typography variant="body1">{getSigninMethodText()}</Typography>
                    </LeadingTitleText>
                  </Grid>

                  {/* Email */}
                  <Grid item>
                    <LeadingTitleText heading="Primary Email">
                      <Grid container alignItems="center">
                        <Grid item>
                          <Typography variant="body1">{currentUser.email}</Typography>
                        </Grid>
                        <Grid item>
                          <Button color="primary" onClick={toggleEmailChangeDialog}>Change</Button>
                        </Grid>
                      </Grid>
                      <Grid item>
                        {currentUser.pendingEmail ? (
                          <Typography color="textSecondary" variant="body2">
                            {currentUser.pendingEmail}
                            {' '}
                            (Pending Verification)
                          </Typography>
                        ) : null}
                      </Grid>
                    </LeadingTitleText>
                  </Grid>

                  {(signInMethod === EMAIL) && (
                    <Grid item>
                      <LeadingTitleText heading="Password">
                        <Grid container alignItems="center">
                          <Grid item>
                            <Typography variant="body1">
                              <span>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </LeadingTitleText>
                    </Grid>
                  )}

                  <Grid item>
                    <LeadingTitleText heading="Current access">
                      <Grid container alignItems="center">
                        <Grid item>
                          {(currentUser.disabled) && (
                            <Typography variant="body1">This account is currently disabled and it cannot be used to login to Brodi.</Typography>
                          )}
                          {(!currentUser.disabled) && (
                            <Typography variant="body1">This account is currently enabled and it can be used to login to Brodi.</Typography>
                          )}
                        </Grid>
                        <Grid item>
                          {(currentUser.disabled) && (
                            <Button onClick={toggleDisableDialog} color="primary">Enable Account</Button>
                          )}
                          {(!currentUser.disabled) && (
                            <Button onClick={toggleDisableDialog} color="secondary">Disable Account</Button>
                          )}
                        </Grid>
                      </Grid>
                    </LeadingTitleText>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container item alignItems="center">
                  <ProgressButton
                    cta="Save Changes"
                    disabled={!formState.dirty}
                    endIcon={<> </>}
                    loading={saving}
                  />
                </Grid>
              </Grid>
            </Grid>

          </Box>
        </ContainedLayout>
      </form>
      <DisableAccountDialog
        disableDialog={disableDialog}
        toggleDisableDialog={toggleDisableDialog}
        currentlyDisabled={currentUser.disabled}
        name={currentUser.name}
        email={currentUser.email}
        uid={userId}
      />

      {/* Dialog To Change Email */}
      <EmailChangeAdminDialog
        userId={userId}
        email={currentUser.email}
        emailDialogOpen={emailDialogOpen}
        toggleEmailChangeDialog={toggleEmailChangeDialog}
      />
    </>
  );
};

export default Settings;
