import Snackbar from '../models/snackbar';
import {
  SHOW_ERROR_SNACKBAR,
  SHOW_SNACKBAR,
  CLEAR_SNACKBAR,
} from '../actions/snackbar';

const defaultSnackbar = new Snackbar();

const initialState = {
  data: {
    ...defaultSnackbar,
  },
};

const snackbar = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ERROR_SNACKBAR:
      return {
        data: {
          error: true,
          message: action.message,
          display: true,
        },
      };
    case SHOW_SNACKBAR:
      return {
        data: {
          error: false,
          message: action.message,
          display: true,
        },
      };
    case CLEAR_SNACKBAR:
      return {
        data: {
          error: false,
          message: '',
          display: false,
        },
      };
    default:
      return state;
  }
};

export default snackbar;
