import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import AppHeaderWithActions from '../organisms/AppHeaderWithActions';

import { transposeHelpArray } from '../../helpers/transposers';
import { getApp } from '../../actions/apps';

import { BreakPoints } from '../../config/theme';

import About from '../molecules/AppPageSections/About';
import Contacts from '../molecules/AppPageSections/Contacts';
import Housekeeping from '../molecules/AppPageSections/Housekeeping';
import Links from '../molecules/AppPageSections/Links';
import Contract from '../molecules/AppPageSections/Contract';
import ResponsibleTeam from '../molecules/AppPageSections/ResponsibleTeam';
import { updateRecentApps } from '../../actions/users';
import { APP_DELETED_STATUS } from '../../config/constants';
import RelatedApps from '../molecules/AppPageSections/RelatedApps';
import ExternalContacts from '../molecules/AppPageSections/ExternalContacts';
import AppPageTour from '../Onboarding/AppPageTour';

const App = () => {
  const dispatch = useDispatch();

  const { appId } = useParams();

  const app = useSelector((store) => store.apps.cachedApps.data[appId]);
  const companyId = useSelector((store) => store.users.currentUser.data.companyId);
  const [manualError, setManualError] = useState(false);

  const [transposedContacts, setTransposedContacts] = useState({});

  useEffect(() => {
    setManualError(false);
    dispatch(getApp(appId)).catch(() => setManualError(true));
    dispatch(updateRecentApps(appId));
  }, [appId, companyId, dispatch]);

  useEffect(() => {
    if (app?.contacts) setTransposedContacts(transposeHelpArray(app.contacts));
  }, [app, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [appId]);

  if (manualError) {
    return (
      <div style={{ marginTop: '5vh' }}>
        <Typography variant="h5" align="center">
          Sorry, there was a problem loading that app.
        </Typography>
      </div>
    );
  }

  if (app && !manualError) {
    if (app.presence.status === APP_DELETED_STATUS) {
      return (
        <div style={{ marginTop: '5vh' }}>
          <Typography variant="h5" align="center">
            Sorry, that app was deleted.
          </Typography>
        </div>
      );
    }
    return (
      <Container>
        <ContentContainer>
          <AppHeaderWithActions
            category={app.category}
            icon={app.icon}
            url={app.url}
            availabilityStatus={app.availability.status}
            appId={appId}
            name={app.name}
            availability={app.availability}
            appFollowers={app.followers}
            presenceStatus={app.presence.status}
            firstParty={app.firstParty}
            isPinned={app.isPinned}
          />
          {/* App Detail Start  */}

          {/* App Detail Columns Start */}
          <MainColumnContainer>

            {/* Left Side Column Start */}
            <SingleColumnContainer>

              {/* About */}
              <AppSectionContainer id="jr-app-about">
                <About
                  appId={appId}
                  description={app.description}
                  customFields={app.customFields}
                  name={app.name}
                  availability={app.availability}
                  appFollowers={app.followers}
                  category={app.category}
                  url={app.url}
                  firstParty={app.firstParty}
                />
              </AppSectionContainer>

              {!app.firstParty ? (
                <>
                  {/* Contract */}
                  <AppSectionContainer id="jr-app-contract">
                    <Contract appId={appId} />
                  </AppSectionContainer>

                  {/* Links */}
                  <AppSectionContainer id="jr-app-links">
                    <Links
                      appId={appId}
                      data={app.links}
                    />
                  </AppSectionContainer>
                </>
              ) : (
                <>
                  {/* Links */}
                  <AppSectionContainer id="jr-app-links">
                    <Links
                      appId={appId}
                      data={app.links}
                    />
                  </AppSectionContainer>
                  {/* Contract */}
                  <AppSectionContainer id="jr-app-contract">
                    <Contract appId={appId} />
                  </AppSectionContainer>
                </>
              )}

              {/* Related Apps */}
              <AppSectionContainer>
                <RelatedApps
                  appId={appId}
                  category={app.category}
                />
              </AppSectionContainer>

              {/* Housekeeping */}
              <AppSectionContainer>
                <Housekeeping
                  created={app.created}
                  modernUpdateStamp={app.modernUpdateStamp}
                />
              </AppSectionContainer>
            </SingleColumnContainer>

            {/* Right Side Column Start */}
            <SingleColumnContainer>

              {/* Responsible Team  */}
              <AppSectionContainer id="jr-app-responsible-team">
                <ResponsibleTeam
                  data={app.owner}
                  appId={appId}
                />
              </AppSectionContainer>

              {/* Internal Contacts */}
              <AppSectionContainer id="jr-app-contacts">
                <Contacts
                  data={transposedContacts}
                  appId={appId}
                  contacts={app?.contacts}
                />
              </AppSectionContainer>

              {/* External Contacts */}
              <AppSectionContainer>
                <ExternalContacts
                  externalContacts={app.externalContacts}
                  appId={appId}
                />
              </AppSectionContainer>

            </SingleColumnContainer>
            {/* Right Side Column End */}

            <AppPageTour />

          </MainColumnContainer>
          {/* App Detail Columns End */}
        </ContentContainer>
      </Container>
    );
  }
  return <div />;
};

export default App;

const Container = styled.div`
  background-color: #fafafa;
`;

const ContentContainer = styled.div`
  @media ${BreakPoints.desktopS} {
    max-width: 1000px;
    margin: 0 auto;
  }
  @media ${BreakPoints.desktopM} {
    max-width: 1200px;
    margin: 0 auto;
  }
  @media ${BreakPoints.desktopL} {
    max-width: 1500px;
    margin: 0 auto;
  }
`;

const MainColumnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SingleColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
`;

const AppSectionContainer = styled.div`
  margin: 20px auto;
  width: 100%;
`;
