import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const SingleFormWrapper = ({ heading, subheading, children }) => (
  <Container>
    <Titles>
      <Typography
        variant="body1"
        style={{ color: '#4E27FF', fontWeight: 800 }}
      >
        {subheading || 'NEXT UP'}
      </Typography>
      <Typography
        variant="h4"
      >
        {heading}
      </Typography>
    </Titles>

    <FormContent>
      {children}
    </FormContent>
  </Container>
);
export default SingleFormWrapper;

const Container = styled.div`
  width: 100%;
`;

const Titles = styled.div`
  margin-bottom: 2rem;
`;

const FormContent = styled.div`
  
`;
