import React from 'react';
import styled from 'styled-components';
import { labelInitials } from '../../helpers/strings';

const AppIcon = ({
  name,
  icon,
  height = '50px',
  width = '50px',
  flat = false,
}) => (
  <IconContainer flat={flat}>
    {(icon ? (
      <Icon alt={`Logo for ${name}`} src={icon} height={height} width={width} />
    ) : (
      <LetterIcon height={height} width={width}>{labelInitials(name)}</LetterIcon>
    ))}
  </IconContainer>
);
export default AppIcon;

const IconContainer = styled.div`
  border-radius: 10px;
  width: 120px;
  text-align: center;
  padding: 1rem;
  ${({ flat }) => (!flat && 'background-color:#fff;')}
`;

const LetterIcon = styled.div`
  background-color: #EEE;
  align-items: center;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  color: #000;
  background-color: #EEE;
  margin-bottom: 5px;
`;

const Icon = styled.img`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  border-radius: 5px;
  object-fit: cover;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px;
`;
