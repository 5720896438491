import React from 'react';
import styled from 'styled-components';
import { BreakPoints } from '../../config/theme';

const ContainedLayout = ({ children, tinted }) => {
  if (tinted) {
    return (
      <Container tinted borderBottom>
        <ContentContainer>
          {children}
        </ContentContainer>
      </Container>
    );
  }
  return (
    <Container>
      <ContentContainer>
        {children}
      </ContentContainer>
    </Container>
  );
};

export default ContainedLayout;

const ContentContainer = styled.div`
  @media ${BreakPoints.desktopS} {
    max-width: 1000px;
    margin: 0 auto;
  }   
  @media ${BreakPoints.desktopM} {
    max-width: 1200px;
    margin: 0 auto;
  }   
  @media ${BreakPoints.desktopL} {
    max-width: 1400px;
    margin: 0 auto;
  } 
`;

const Container = styled.div`
  ${({ tinted }) => tinted && 'background-color: #FAFAFA;'}
  ${({ borderBottom }) => borderBottom && 'border-bottom: 1px solid #eee;'}
`;
