import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useParams, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ContainedLayout from '../templates/ContainedLayout';

import GetBasics from '../organisms/AddAppFormSteps/GetBasics';
import AppAdded from '../organisms/AddAppFormSteps/AppAdded';
import { getPrevFormStep } from '../../helpers/form';
import FormProgressIndicator from '../AddApp/components/FormProgressIndicator';
import FormExtraDetails from '../AddApp/components/FormExtraDetails';
import FormHelpContacts from '../AddApp/components/FormHelpContacts';

const Add = () => {
  const { formStep = 0 } = useParams();

  const { signatures } = useSelector((store) => store.apps.addApp.data);

  const [formFlowValid, setFormFlowValid] = useState(true);

  useEffect(() => {
    const prevStep = getPrevFormStep(formStep);
    if (formStep > 0 && formStep < 3) {
      if (!signatures[prevStep]) return setFormFlowValid(false);
    }
    return setFormFlowValid(true);
  }, [signatures, formStep]);

  // Order Matters
  const FORM_CONFIG = [
    {
      component: <GetBasics formStep={formStep} />,
      tabName: 'About this App',
    },
    {
      component: <FormExtraDetails formStep={formStep} />,
      tabName: 'Extra Details',
    },
    {
      component: <FormHelpContacts formStep={formStep} />,
      tabName: 'Help & Contacts',
    },
    {
      component: <AppAdded formStep={formStep} />,
      tabName: 'All Done!',
    },
  ];

  const renderSwitch = (param) => {
    if (FORM_CONFIG[param]) return FORM_CONFIG[param].component;
    return 'There was a problem. Sorry about that.';
  };

  return (
    <>
      <Box>
        <FormProgressIndicator formConfig={FORM_CONFIG} signatures={signatures} formStep={formStep} />
      </Box>
      <ContainedLayout>
        {formFlowValid && renderSwitch(formStep)}
        {!formFlowValid && <Redirect to="/add/0" />}
      </ContainedLayout>
    </>
  );
};

export default Add;
