import React from 'react';
import Appearance from './Tabs/Appearance';
import Integrations from './Tabs/Integrations';
import Structure from './Tabs/Structure';

// eslint-disable-next-line import/prefer-default-export
export const ManagementTabs = [
  {
    id: 'APPEARANCE',
    component: <Appearance />,
    title: 'Appearance',
    subtitle: 'Customize how Brodi looks and feels',
    tabName: 'Appearance',
  },
  {
    id: 'STRUCTURE',
    component: <Structure />,
    title: 'Structure & Teams',
    subtitle: 'Setup your organizational structure and edit teams',
    tabName: 'Structure & Teams',
  },
  {
    id: 'INTEGRATIONS',
    component: <Integrations />,
    title: 'Integrations',
    subtitle: 'Add and configure integrations',
    tabName: 'Integrations',
  },
];
