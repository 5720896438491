import { ICON_SIZE_LIMIT, ICON_TYPE_REGEX } from '../config/constants';

// eslint-disable-next-line import/prefer-default-export
export const checkIcon = (file) => {
  if (file.size > ICON_SIZE_LIMIT) {
    throw new Error('That image is too big. Choose an image smaller than 3MB.');
  }

  if (!ICON_TYPE_REGEX.test(file.type)) {
    throw new Error('Only GIF, JPG, JPEG, TIFF, and PNG files are allowed.');
  }
};
