import React, { useEffect, useState } from 'react';

import {
  Fade,
  Button, IconButton, TextField, Typography, FormControl, InputLabel, Select, MenuItem,
} from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { getNextFormStep } from '../../../helpers/form';
import { updateAddApp } from '../../../actions/apps';

import { urlRegex } from '../../../helpers/url';
import { BILLING_CYCLE_TYPES } from '../../../config/constants';
import SingleFormWrapper from './SingleFormWrapper';
import CURRENCY_CODES from '../../../config/currencies';
import FormProgressionButtons from '../../molecules/FormProgressionButtons';

const FormExtraDetails = ({ formStep }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const appInProgress = useSelector((store) => store.apps.addApp.data);
  const displayName = useSelector((store) => store.company.data.displayName);

  const [showContractForm, setShowContractForm] = useState(!appInProgress?.firstParty);

  const linksDefaultInput = { displayName: '', url: '' };
  const customFieldsDefault = { key: '', value: '' };

  const {
    register, handleSubmit, control, errors, reset,
  } = useForm({
    defaultValues: {},
  });

  const { fields, append, remove } = useFieldArray({
    control, name: 'links',
  });

  const { fields: customFieldFields, append: appendCustomField, remove: removeCustomField } = useFieldArray({
    control, name: 'customFields',
  });

  const linkSuggestions = useSelector((store) => store.client.data.linkAutoSuggestions);
  const linkSuggestionsWithHelper = [
    'Choose a Suggestion or Enter Your Own', // disabled
    ...linkSuggestions,
  ];

  const advanceForm = (d) => {
    const signature = { [formStep]: new Date() };
    dispatch(updateAddApp(d, signature));
    history.push(`/add/${getNextFormStep(formStep)}`);
  };

  useEffect(() => {
    if (appInProgress) reset(appInProgress);
  }, [appInProgress, reset]);

  return (
    <Fade in>
      <form onSubmit={handleSubmit(advanceForm)}>
        <SingleFormWrapper subheading="STEP TWO" heading="Extra Details">

          <RowsContainer>
            <FormSection>
              <FormRow>
                <Typography variant="h6">
                  Add Links (Optional)
                </Typography>
                <Typography variant="body1">
                  You can include links to websites, servers, and more
                </Typography>
              </FormRow>
              <FormRow>
                {fields.map((value, index) => (
                  <LinkContainer key={value.url}>
                    <LinkLhs>
                      <IconButton onClick={() => remove(index)} title="Remove Link">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </LinkLhs>

                    <LinkRhs>
                      <div style={{ width: '100%' }}>
                        <Controller
                          as={(
                            <Autocomplete
                              autoSelect
                              freeSolo
                              getOptionDisabled={(option) => option === 'Choose a Suggestion or Enter Your Own'}
                              disableClearable
                              options={linkSuggestionsWithHelper || []}
                              renderInput={(params) => (
                                <TextField
                                  error={!!errors.links?.[index]?.displayName}
                                  {...params}
                                  label="Display Name"
                                  helperText={errors.links?.[index]?.displayName && 'Please provide a display name or remove this link'}
                                  size="small"
                                  variant="outlined"
                                  fullWidth
                                />
                              )}
                            />
                          )}
                          onChange={([, d]) => d}
                          name={`links[${index}].displayName`}
                          control={control}
                          rules={{ required: true }}
                          defaultValue={value.displayName || ''}
                        />
                      </div>

                      <TextField
                        error={!!errors.links?.[index]?.url}
                        fullWidth
                        size="small"
                        label="URL"
                        defaultValue={value.url || ''}
                        inputRef={register({ required: true, pattern: urlRegex })}
                        helperText={errors.links?.[index] && 'Please provide a valid URL or remove this link'}
                        name={`links[${index}].url`}
                        variant="outlined"
                        autoComplete="off"
                      />
                    </LinkRhs>
                  </LinkContainer>
                ))}

                <FormRow>
                  <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    onClick={() => append(linksDefaultInput)}
                    startIcon={<AddIcon />}
                  >
                    Add a Link
                  </Button>
                </FormRow>

              </FormRow>
            </FormSection>

            <FormSection>
              <FormRow>
                <Typography variant="h6">
                  Custom Fields (Optional)
                </Typography>
                <Typography variant="body1">
                  Add and organize unique
                  {' '}
                  {displayName}
                  {' '}
                  data about this App
                </Typography>
              </FormRow>
              <FormRow>
                {customFieldFields.map((value, index) => (
                  <LinkContainer key={value.key}>
                    <LinkLhs>
                      <IconButton onClick={() => removeCustomField(index)} title="Remove Link">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </LinkLhs>

                    <LinkRhs>
                      <TextField
                        label="Custom Field Name"
                        helperText={errors.customFields?.[index]?.key && 'Provide the Field Name'}
                        error={!!errors.customFields?.[index]?.key}
                        fullWidth
                        size="small"
                        defaultValue={value.key || ''}
                        inputRef={register({ required: true })}
                        name={`customFields[${index}].key`}
                        variant="outlined"
                        autoComplete="off"
                      />

                      <TextField
                        error={!!errors.customFields?.[index]?.value}
                        fullWidth
                        size="small"
                        label="Value"
                        defaultValue={value.value || ''}
                        inputRef={register({ required: true })}
                        helperText={errors.customFields?.[index] && 'Provide a value for this Custom Field'}
                        name={`customFields[${index}].value`}
                        variant="outlined"
                        autoComplete="off"
                      />
                    </LinkRhs>
                  </LinkContainer>
                ))}

                <FormRow>
                  <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    onClick={() => appendCustomField(customFieldsDefault)}
                    startIcon={<AddIcon />}
                  >
                    Add a Custom Field
                  </Button>
                </FormRow>

              </FormRow>
            </FormSection>

            <FormSection background>
              <FormRow>
                <Typography variant="h6">
                  Contract Information (Optional)
                </Typography>
                <Typography variant="body1">
                  Add contract data to get cost insights and expiration reminders
                </Typography>
              </FormRow>

              {showContractForm && (
                <FormRow>
                  <>
                    <ContractRow row>
                      <TextField
                        variant="outlined"
                        label="Cost per Billing Cycle"
                        size="medium"
                        name="contracts[0].amount"
                        type="number"
                        error={Boolean(!!errors?.contracts?.length && errors.contracts[0].amount)}
                        inputRef={register({
                          required: 'Please provide a cost for this App',
                          min: 0,
                        })}
                      />

                      <FormControl variant="outlined" fullWidth error={Boolean(errors.cycleType)}>
                        <InputLabel id="cycle-outlined-label">Billing Cycle</InputLabel>
                        <Controller
                          as={(
                            <Select variant="outlined" fullWidth label="Billing Cycle">
                              <MenuItem disabled value="">Select a Billing Cycle</MenuItem>
                              {BILLING_CYCLE_TYPES.map((type) => (
                                <MenuItem key={type.key} value={type.key}>
                                  {type.display}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                          error={Boolean(!!errors?.contracts?.length && errors.contracts[0].cycleType)}
                          name="contracts[0].cycleType"
                          rules={{ required: 'Please select how often this app is billed' }}
                          control={control}
                        />
                      </FormControl>
                    </ContractRow>

                    <ContractRow mt>
                      <Controller
                        as={(
                          <Autocomplete
                            autoSelect
                            disableClearable
                            options={Object.keys(CURRENCY_CODES) || []}
                            getOptionLabel={(opt) => `${CURRENCY_CODES[opt]} (${opt})`}
                            noOptionsText="No Currency Found"
                            fullWidth
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                size="medium"
                                variant="outlined"
                                label="Billing Currency"
                                error={Boolean(!!errors?.contracts?.length && errors.contracts[0].currencyCode)}
                                helperText={errors.currencyCode && 'Please provide the billing currency'}
                              />
                            )}
                          />
                        )}
                        onChange={([, d]) => d}
                        name="contracts[0].currencyCode"
                        control={control}
                        rules={{ required: true }}
                      />
                    </ContractRow>

                    <ContractRow mt>
                      <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <Controller
                          as={(
                            <KeyboardDatePicker
                              disableToolbar
                              error={Boolean(!!errors?.contracts?.length && errors.contracts[0].renewalDate)}
                              autoOk
                              variant="inline"
                              format="MM/dd/yyyy"
                              maxDateMessage="Invalid date"
                              minDateMessage="Invalid date"
                              fullWidth
                              margin="normal"
                              inputVariant="outlined"
                              id="date-picker-inline"
                              label="Next Contract Renewal Date"
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          )}
                          initialFocusedDate={null}
                          defaultValue={null}
                          name="contracts[0].renewalDate"
                          control={control}
                        />
                      </MuiPickersUtilsProvider>
                    </ContractRow>

                    <ContractRow mt>
                      <TextField
                        variant="outlined"
                        label="Note"
                        size="medium"
                        name="contracts[0].notes"
                        rows={4}
                        fullWidth
                        multiline
                        error={Boolean(!!errors?.contracts?.length && errors.contracts[0].notes)}
                        inputRef={register()}
                        helperText={errors.notes && 'Something\'s not quite right here'}
                      />
                    </ContractRow>
                  </>
                </FormRow>
              )}

              <FormRow>
                <Button
                  variant="contained"
                  disableElevation
                  size="large"
                  onClick={() => setShowContractForm(!showContractForm)}
                  startIcon={showContractForm ? <DeleteIcon /> : <AddIcon />}
                >
                  {showContractForm ? 'Don\'t Add Contract Data' : 'Add Contract Data'}
                </Button>
              </FormRow>

            </FormSection>

            <FormRow>
              <FormProgressionButtons
                buttonLoading={false}
                formStep={formStep}
              />
            </FormRow>
          </RowsContainer>

        </SingleFormWrapper>
      </form>
    </Fade>
  );
};

export default FormExtraDetails;

const RowsContainer = styled.div`
`;

const FormRow = styled.div`
  margin-bottom: 2rem;
  > div {
    margin-bottom: 1rem;
  }
`;

const FormSection = styled.div`
  max-width: 1000px;
  margin-bottom: 3rem;
  
  :not(:last-child) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1.5rem;
  }
`;

const LinkContainer = styled.div`
  border: 1px solid #eee;
  border-radius: 5px;
  display: flex;
`;

const LinkLhs = styled.div`
  background-color: #eee;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkRhs = styled.div`
  padding: 2rem 1.5rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  > div {
    :not(:last-child) {
      margin-right: 2rem;
    }
  }
`;

const ContractRow = styled.div`
  margin: 15px 0;
  width: 100%;
  ${({ mt }) => mt && `
    margin-top: 2rem;
  `}
  ${({ row }) => row && `
    display: flex;
    align-items: center;
    > div {
      :not(:last-child) {
        margin-right: 1rem;
      }
    }
  `}
`;
