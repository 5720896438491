import React from 'react';
import ContentLoader from 'react-content-loader';

const FadingLoader = () => (
  <div>
    <FadingLoaderCard1 />
    <FadingLoaderCard1 />
    <FadingLoaderCard1 />
  </div>
);

const FadingLoaderCard1 = () => (
  <ContentLoader
    speed={1}
    width={450}
    height={110}
    viewBox="0 0 450 110"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="90" y="15" rx="3" ry="3" width="200" height="20" />
    <rect x="90" y="50" rx="3" ry="3" width="150" height="10" />
    <rect rx="10" ry="10" width="75" height="75" />
  </ContentLoader>
);

FadingLoader.metadata = {
  filename: 'FadingLoader',
};

export default FadingLoader;
