import React from 'react';

import {
  TextField,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

const GetStructureAutocomplete = ({
  control,
  defaultValue,
  errors,
  name,
  structureItem,
  fieldKey,
}) => {
  const { structureChildren } = useSelector((store) => store.company.data);

  const filter = createFilterOptions();

  const handleFilterOptions = (options, params) => {
    const filtered = filter(options, params);
    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        name: `Add "${params.inputValue}"`,
      });
    }
    return filtered;
  };

  const checkForErrors = () => {
    if (fieldKey) {
      if (errors && errors[fieldKey]) return !!errors[fieldKey][structureItem.id];
    }
    return Boolean(errors.owner && errors.owner[structureItem.id]);
  };

  return (
    <Controller
      as={(
        <Autocomplete
          selectOnFocus
          handleHomeEndKeys
          freeSolo
          fullWidth
          filterOptions={handleFilterOptions}
          options={structureChildren.filter(
            ({ status = 'ACTIVE', parentId }) => (status !== 'DELETED' && structureItem.id === parentId),
          ).sort((a, b) => -b.name.localeCompare(a.name))}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return structureChildren.find(({ id }) => id === option)?.name;
            }
            if (option.inputValue) return option.inputValue;
            return option.name;
          }}
          renderOption={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              error={checkForErrors()}
              helperText={errors && errors[fieldKey] && `Please provide a ${structureItem.name}`}
              label={structureItem.name}
              variant="outlined"
              fullWidth
            />
          )}
        />
      )}
      onChange={([, newValue]) => {
        if (newValue && newValue.inputValue) {
          return { name: newValue.inputValue, parentId: structureItem.id, id: null };
        }
        return newValue;
      }}
      name={name || `owner.${structureItem?.id}`}
      control={control}
      rules={{ required: true }}
      defaultValue={defaultValue}
    />

  );
};

export default GetStructureAutocomplete;
