export const labelInitials = (text) => {
  if (text) {
    const split = text.toUpperCase().split(' ');
    if (split.length > 1) {
      return split[0][0] + split[1][0];
    } if (split.length === 1) {
      return split[0][0];
    }
  }
  return '';
};

export const truncate = (str, n) => {
  if (str) return (str.length > n) ? `${str.substr(0, n - 1).trim()}...` : str;
  return '';
};

export const getFirstName = (name) => name?.split(' ')[0];

export const toTitleCase = (str) => str.replace(
  /\w\S*/g,
  (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
);

export const getTldFromEmail = (email) => email.toLowerCase().split('@')[1];

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
