import App from '../models/app';

import {
  GET_APP_SUCCESS,
  UPDATE_ADD_APP_SUCCESS,
  SUBMIT_ADD_APP_SUCCESS,
} from '../actions/apps';

const defaultApp = new App();

const initialState = {
  addApp: {
    data: {
      ...defaultApp,
      signatures: {},
    },
  },
  cachedApps: {
    data: {},
  },
};

const apps = (state = initialState, action) => {
  switch (action.type) {
    case GET_APP_SUCCESS:
      return {
        ...state,
        cachedApps: {
          data: {
            ...state.cachedApps.data,
            ...action.payload,
          },
        },
      };

    case UPDATE_ADD_APP_SUCCESS:
      return {
        ...state,
        addApp: {
          data: {
            ...state.addApp.data,
            ...action.payload,
            signatures: {
              ...state.addApp.data.signatures,
              ...action.signature,
            },
          },
        },
      };

    case SUBMIT_ADD_APP_SUCCESS:
      return {
        ...state,
        addApp: {
          data: {
            ...initialState.addApp.data,
          },
        },
      };

    default:
      return state;
  }
};

export default apps;
