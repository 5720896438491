export default class Company {
  constructor(
    algoliaId = '',
    displayName = '',
    labels = {},
    name = '',
    preferences = {},
    structure = {},
    compliance = [],
    tier = 0,
  ) {
    this.algoliaId = algoliaId;
    this.displayName = displayName;
    this.labels = labels;
    this.name = name;
    this.preferences = preferences;
    this.structure = structure;
    this.compliance = compliance;
    this.tier = tier;
  }
}
