import React, { useState } from 'react';
import styled from 'styled-components';
import FeedbackIcon from '@material-ui/icons/Feedback';
import FeedbackDialog from './dialog';

const FloatingFeedbackButton = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialog = () => setDialogOpen(!dialogOpen);

  return (
    <>
      <FloatingButton id='jr-global-feedback' onClick={handleDialog}>
        <FeedbackIcon htmlColor="white" />
      </FloatingButton>

      <FeedbackDialog
        dialogOpen={dialogOpen}
        handleDialog={handleDialog}
      />
    </>
  );
};
export default FloatingFeedbackButton;

const FloatingButton = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  right: 0;
  margin: 2rem;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50px;
  background-color: #3A4ADE;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.25);
  transition: box-shadow 0.25s ease-in-out;
  &:hover {
    cursor: pointer;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.40);
  };
`;
