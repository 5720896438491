import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyAQBW70Kn2nf6kX245xopd9tX2MRNwQY7g',
  authDomain: 'brodi-io.firebaseapp.com',
  projectId: 'brodi-io',
  storageBucket: 'brodi-io.appspot.com',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
