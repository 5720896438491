export const SHOW_ERROR_SNACKBAR = 'SHOW_ERROR_SNACKBAR';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const CLEAR_SNACKBAR = 'CLEAR_SNACKBAR';

export const showErrorSnackbar = (message) => (dispatch) => {
  dispatch({ type: SHOW_ERROR_SNACKBAR, message });
};

export const showSnackbar = (message) => (dispatch) => {
  dispatch({ type: SHOW_SNACKBAR, message });
};

export const clearSnackbar = () => (dispatch) => {
  dispatch({ type: CLEAR_SNACKBAR });
};
