import React, { useState, useEffect } from 'react';
import {
  TextField, Grid, Typography, Paper, Box, Button, LinearProgress,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import firebase from '../../config/firebase';
import CenteredLayout from '../templates/CenteredLayout';
import Error from './Error';
import UnauthenticatedLayout from '../templates/UnauthenticatedLayout';
import { PASSWORD_REGEX, DOMAIN } from '../../config/constants';
import { verifyEmailChange } from '../../actions/users';
import logo from '../../images/logo.svg';
import Loading from '../../images/Loading.svg';

const MALFORMED = 'MALFORMED';
const INVALID = 'INVALID';
const GENERAL = 'GENERAL';

const AuthAction = () => {
  const [mode, setMode] = useState(null);
  const [oobCode, setOobCode] = useState(null);
  const [continueUrl, setContinueUrl] = useState(null);
  const [tenantId, setTenantId] = useState(null);
  const [resetPasswordEmail, setResetPasswordEmail] = useState(null);
  const [emailChanged, setEmailChanged] = useState(false);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);
  const [verifying, setVerifying] = useState(false);

  const {
    errors,
    formState,
    handleSubmit,
    register,
    watch,
  } = useForm();

  const incompleteFirebaseParams = () => {
    if (oobCode === null || tenantId === null) return true;
    return false;
  };

  const incompleteCustomParams = () => {
    if (oobCode === null) return true;
    return false;
  };

  const loading = () => (
    <CenteredLayout>
      <img src={Loading} alt="loading" />
    </CenteredLayout>
  );

  const submitResetPassword = (data) => {
    setSaving(true);
    setError(null);
    firebase.auth().confirmPasswordReset(oobCode, data.newPassword)
      .then(() => {
        setSaving(false);
        window.location = continueUrl;
      })
      .catch(() => {
        setSaving(false);
        setError(GENERAL);
      });
  };

  const renderResetPassword = () => (
    <UnauthenticatedLayout>
      <form onSubmit={handleSubmit(submitResetPassword)}>
        <Paper>
          {
            (saving) && (
              <LinearProgress />
            )
          }
          <Box p={8}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify='center'
              spacing={3}
            >
              {/* Logo */}
              <Grid item xs={12} md={6}>
                <img src={logo} alt="logo" />
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="h4" align="center">Set a New Password</Typography>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="h6" align="center">{resetPasswordEmail}</Typography>
              </Grid>

              <Box my={3}>
                <Grid container item direction="row" justify="center" spacing={5}>
                  <Grid item xs={12}>
                    <TextField
                      label="New Password"
                      name="newPassword_1"
                      type="password"
                      variant="outlined"
                      autoComplete="new-password"
                      error={Boolean(errors.newPassword_1)}
                      helperText="At least eight characters and must be a mix of uppercase, lowercase, symbols and numbers."
                      inputRef={register({ required: true, pattern: new RegExp(PASSWORD_REGEX) })}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="New Password Again"
                      name="newPassword"
                      type="password"
                      variant="outlined"
                      error={Boolean(errors.newPassword)}
                      helperText={errors.newPassword ? "Those passwords didn't match" : ''}
                      inputRef={register({
                        required: true,
                        validate: (value) => value === watch('newPassword_1'),
                      })}
                      fullWidth
                    />
                  </Grid>
                  <input id="emailfield" type="text" value={resetPasswordEmail} autoComplete="username" style={{ display: 'none' }} />
                  <Button
                    variant="contained"
                    disableElevation
                    size="large"
                    disabled={!formState.dirty || saving}
                    type="submit"
                    color="primary"
                  >
                    Change Password
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </Paper>
      </form>
    </UnauthenticatedLayout>
  );

  const verifyEmailChangeCode = async () => {
    if (verifying) return;
    setVerifying(true);
    try {
      await verifyEmailChange(oobCode);
      setEmailChanged(true);
    } catch (err) {
      setError(INVALID);
    }
  };

  const verifyPasswordResetCode = async () => {
    if (verifying) return;
    setVerifying(true);
    firebase.auth().tenantId = tenantId;
    try {
      const email = await firebase.auth().verifyPasswordResetCode(oobCode);
      setResetPasswordEmail(email);
    } catch (err) {
      setError(INVALID);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setMode(urlParams.get('mode'));
    setOobCode(urlParams.get('oobCode'));
    setContinueUrl(urlParams.get('continueUrl') || DOMAIN);
    setTenantId(urlParams.get('tenantId'));
  }, []);

  if (error) {
    let message;
    switch (error) {
      case MALFORMED:
        message = '(The URL was malformed.)';
        break;
      case INVALID:
        message = '(The url was invalid/has expired.)';
        break;
      case GENERAL:
        message = '';
        break;
      default:
        break;
    }
    return (<Error type={400} message={message} />);
  }

  switch (mode) {
    case 'resetPassword':
      if (incompleteFirebaseParams()) {
        setError(MALFORMED);
        break;
      }
      if (resetPasswordEmail !== null) return renderResetPassword();
      verifyPasswordResetCode();
      break;
    case 'verifyEmail':
      if (incompleteCustomParams()) {
        setError(MALFORMED);
        break;
      }
      if (emailChanged) window.location = continueUrl;
      verifyEmailChangeCode();
      break;
    default:
      break;
  }

  return loading();
};

export default AuthAction;
