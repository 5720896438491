import { Button, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import ReactJoyride from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { updateOnboarding } from '../../actions/users';

const steps = [
  {
    target: '#jr-global-logo',
    title: 'Looks Like You\'re New Here!',
    disableBeacon: true,
    content: 'Take a quick tour of this App page to get the most out of Brodi.',
  },
  {
    target: '#jr-global-search',
    title: 'Quick Search',
    content: 'It\'s small, but mighty. Search entire App pages and jump straight to what you need from right here.',
  },
  {
    target: '#jr-app-favorite',
    title: 'Stay Informed',
    content: 'You can add Apps to your favorites and get notified about updates - like deprecation and outage alerts.',
  },
  {
    target: '#jr-app-launcher',
    title: 'One Click to Open',
    content: 'Jump directly to this App in a new window with one click.',
  },
  {
    target: '#jr-app-about',
    title: 'The Basics',
    content: 'Answer the most frequently asked questions at a glance. Add Custom Fields for this App to store unique company information. You can also report and notify people following this App about issues or outages.',
  },
  {
    target: '#jr-app-responsible-team',
    title: 'The Main Contact for Help',
    content: 'The first port of call to quickly find out who can provide support for this App.',
  },
  {
    target: '#jr-app-contract',
    title: 'Keep Track of Cost',
    content: 'Keep track of import contract and cost information. Plus, we\'ll use this information to help you analyze the total cost of your stack.',
  },
  {
    target: '#jr-app-links',
    title: 'Quick Links',
    content: 'Add links to important places related to this App - like documentation, or direct links to places inside of this App.',
  },
  {
    target: '#jr-app-contacts',
    title: 'People Who Can Help',
    content: 'You can add team members to this App page who will be shown as people who can help.',
  },
  {
    target: '#jr-global-feedback',
    title: 'All Done!',
    content: 'That was just a quick tour of an App page. You can use this button anywhere in Brodi to send us feedback, or a feature request.',
  },
];

const Tooltip = ({
  continuous,
  index,
  step,
  isLastStep,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  completeCallback,
}) => (
  <TooltipBody {...tooltipProps}>
    {step.title && (
      <TooltipTitle>
        <Typography variant="h6" style={{ fontWeight: 700 }} gutterBottom>
          {step.title}
        </Typography>
      </TooltipTitle>
    )}
    <TooltipContent>
      <Typography variant="body1">
        {step.content}
      </Typography>
    </TooltipContent>
    <TooltipFooter>
      <div>
        {index > 0
        && (
        <Typography variant="body2" color="textSecondary">
          Tip
          {' '}
          {index + 1}
          {' '}
          of
          {' '}
          {steps.length}
        </Typography>
        )}
        {index === 0
        && (
          <Button color="default" {...closeProps}>No Thanks</Button>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {index > 0 && (
          <Button {...backProps}>Back</Button>
        )}
        {continuous && (
          <Button {...primaryProps} color="primary">
            {isLastStep ? 'Finish' : 'Next'}
          </Button>
        )}
        {!continuous && (
          <Button {...closeProps}>Close</Button>
        )}
      </div>
    </TooltipFooter>
  </TooltipBody>
);

const AppPageTour = () => {
  const {
    onboarding = {
      tourAppPage: false,
    },
  } = useSelector((store) => store.users.currentUser.data);

  const dispatch = useDispatch();

  const completeAppPageTourCallback = ({ status, lifecycle, action }) => {
    if (status === 'finished' && lifecycle === 'complete') {
      dispatch(updateOnboarding({ tourAppPage: false }));
    } else if (action === 'close' && lifecycle === 'complete') {
      dispatch(updateOnboarding({ tourAppPage: false }));
    }
  };

  return (
    <ReactJoyride
      steps={steps}
      run={onboarding.tourAppPage}
      showProgress
      callback={completeAppPageTourCallback}
      scrollOffset={150}
      continuous
      tooltipComponent={Tooltip}
      styles={{
        options: {
          arrowColor: '#fff',
          backgroundColor: '#fff',
          overlayColor: 'rgba(0, 0, 0, 0.4)',
          primaryColor: '#e91e63',
          textColor: '#000',
          zIndex: 1000,
        },
      }}
    />
  );
};

export default AppPageTour;

const TooltipBody = styled.div`
  background-color: white;
  border-radius: 10px;
  min-width: 300px;
  max-width: 400px;
  min-height: 100px;
  padding: 1rem;
  z-index: 5000;
`;

const TooltipTitle = styled.div`
  padding: 1rem 0 0 1rem;
`;
const TooltipContent = styled.div`
  padding: 0 1rem;
`;
const TooltipFooter = styled.div`
  margin-top: 1rem;
  padding-left: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
