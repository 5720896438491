import React, { useState } from 'react';
import {
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  LinearProgress,
  Typography,
  Box,
} from '@material-ui/core';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../actions/snackbar';
import { editSlackToken } from '../../actions/admin';

const SlackTokenDeleteAdminDialog = ({
  slackTokenDeleteDialogOpen,
  toggleSlackTokenDeleteDialog,
}) => {
  const dispatch = useDispatch();
  const [manualError, setManualError] = useState(false);
  const [saving, setSaving] = useState(false);
  const {
    handleSubmit,
    reset,
  } = useForm();

  const handleDeleteRequest = () => {
    setSaving(true);
    setManualError(false);
    dispatch(editSlackToken('', false))
      .then(() => {
        setSaving(false);
        dispatch(showSnackbar('Slack token deleted.'));
        toggleSlackTokenDeleteDialog();
      })
      .catch(() => {
        setSaving(false);
        setManualError(true);
      });
  };

  const handleClose = () => {
    toggleSlackTokenDeleteDialog();
    reset();
    setManualError(false);
  };

  return (
    <Dialog
      open={slackTokenDeleteDialogOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(handleDeleteRequest)}>
        {
          (saving) && (
            <LinearProgress />
          )
        }
        <DialogTitle id="form-dialog-title">Revoke Slack token?</DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="body1">
                The Slack integration will be removed from Brodi. You can connect Slack again later.
              </Typography>
            </Grid>
          </Grid>
          {manualError && (
            <Box my={2}>
              <Typography color="error" variant="body2">
                Sorry, there was a problem revoking the Slack token.
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={saving} type="submit" color="primary">
            Delete Token
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SlackTokenDeleteAdminDialog;