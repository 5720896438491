import React from 'react';
import {
  Typography,
} from '@material-ui/core';

import styled from 'styled-components';

export default ({ heading, children }) => (
  <Container>
    <Title>
      <Typography
        variant="body2"
        gutterBottom
        style={{ fontWeight: 700 }}
      >
        {heading}
      </Typography>
    </Title>
    <Children>
      {children}
    </Children>
  </Container>
);

const Container = styled.div``;
const Title = styled.div``;
const Children = styled.div``;
