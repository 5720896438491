import Auth from '../models/auth';
import {
  AUTH_SUCCESS,
  VERIFY_AUTH_SUCCESS,
  LOGOUT_SUCCESS,
} from '../actions/auth';

const defaultAuth = new Auth();

const initialState = {
  data: {
    ...defaultAuth,
  },
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_AUTH_SUCCESS:
      return {
        data: {
          isAuthenticated: action.isAuthenticated,
        },
      };

    case AUTH_SUCCESS:
      return {
        data: {
          isAuthenticated: true,
        },
      };

    case LOGOUT_SUCCESS:
      return {
        data: {
          isAuthenticated: false,
        },
      };

    default:
      return state;
  }
};

export default auth;
