import React from 'react';
import { Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import { APP_AVAILABLE_STATUS, APP_PARTIALLY_UNAVAILABLE_STATUS, APP_UNAVAILABLE_STATUS } from '../../config/constants';

const getColor = (status) => {
  if (status === APP_AVAILABLE_STATUS) {
    return '#2ECC40';
  } if (status === APP_PARTIALLY_UNAVAILABLE_STATUS) {
    return '#FF851B';
  } if (status === APP_UNAVAILABLE_STATUS) {
    return '#FF4136';
  }
  return '#AAAAAA';
};

const AppAvailabilityStatusIcon = ({ status }) => {
  const convertStatusToText = () => {
    if (status === APP_AVAILABLE_STATUS) {
      return 'Status: Available';
    } if (status === APP_PARTIALLY_UNAVAILABLE_STATUS) {
      return 'Status: Partial Outage';
    }
    return 'Status: Complete Outage';
  };

  return (
    <Tooltip title={convertStatusToText()}>
      <Dot style={{ backgroundColor: getColor(status) }} />
    </Tooltip>
  );
};

const Dot = styled.span`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
`;

export default AppAvailabilityStatusIcon;
