import React, { useState } from 'react';
import {
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  LinearProgress,
} from '@material-ui/core';

const GenericConfirmationModal = ({
  modalIsOpen = false,
  cancelCallBack = () => {},
  confirmCallBack = async () => {},
  title,
  body,
  confirmCta = 'OK',
  cancelCta = 'Cancel',
}) => {
  const [processing, setProcessing] = useState(false);

  const handleConfirm = async () => {
    setProcessing();
    await confirmCallBack();
    setProcessing(false);
  };
  const handleCancel = () => {
    cancelCallBack();
  };

  return (
    <Dialog
      open={modalIsOpen}
      onClose={handleCancel}
      fullWidth
    >
      {processing && <LinearProgress />}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions>
        <Button disabled={processing} onClick={handleCancel}>
          {cancelCta}
        </Button>
        <Button disabled={processing} type="submit" color="primary" onClick={handleConfirm}>
          {confirmCta}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenericConfirmationModal;
