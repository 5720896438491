import React, { useState } from 'react';
import {
  Button,
  DialogContent,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  LinearProgress,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { resetPasswordRequest } from '../../actions/users';
import { showSnackbar } from '../../actions/snackbar';

const PasswordResetDialog = ({
  resetDialogOpen, toggleResetDialogDisplay, email,
}) => {
  const { register, handleSubmit } = useForm();
  const [manualError, setManualError] = useState(false);
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();

  const handleResetSubmit = (data) => {
    setSaving(true);
    setManualError(false);
    const newEmail = data.email.toLowerCase();
    resetPasswordRequest(newEmail)
      .then(() => {
        setSaving(false);
        dispatch(showSnackbar(`A password reset link was sent to ${newEmail}`));
        toggleResetDialogDisplay();
      })
      .catch(() => {
        setSaving(false);
        setManualError(true);
      });
  };

  const handleClose = () => {
    setManualError(false);
    toggleResetDialogDisplay();
  };

  return (
    <Dialog
      open={resetDialogOpen}
      onClose={toggleResetDialogDisplay}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(handleResetSubmit)}>
        {
          (saving) && (
            <LinearProgress />
          )
        }
        <DialogTitle id="form-dialog-title">Reset Your Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can reset your password if you have forgotten it.
            {' '}
            Enter your work email address to receive a password reset email.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Work Email"
            name="email"
            type="email"
            defaultValue={email || ''}
            inputRef={register}
            fullWidth
          />
          {manualError ? (
            // TODO: STYLING: This needs better styling
            <DialogContentText color="error">
              There was a problem resetting your password.
            </DialogContentText>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={saving} type="submit" color="primary">
            Reset
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PasswordResetDialog;
