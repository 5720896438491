/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import firebase from '../config/firebase';
import { APP_ENGINE_DOMAIN, GET } from '../config/constants';

export const brodiAuthedRequest = async (method = GET, url, data = {}) => {
  const token = await firebase.auth().currentUser.getIdToken();
  const headers = {
    authorization: `Bearer ${token}`,
  };
  const res = await axios({
    method,
    url: `${APP_ENGINE_DOMAIN}${url}`,
    headers,
    data,
  });
  return res.data;
};

export const brodiUnauthedRequest = async (method = GET, url, data = {}) => {
  const res = await axios({
    method,
    url: `${APP_ENGINE_DOMAIN}${url}`,
    headers: {},
    data,
  });
  return res.data;
};
