import React, { useState } from 'react';

import { Typography, Button } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import CollapsableItemPanel from '../../organisms/CollapsableItemPanel';
import SimpleAvatar from '../../atoms/SimpleAvatar';
import EditExternalContacts from '../AppPageEditDialogs/EditExternalContacts';
import { getApp } from '../../../actions/apps';

const ExternalContacts = ({
  externalContacts, appId,
}) => {
  const dispatch = useDispatch();
  const [openExternalContacts, setOpenExternalContacts] = useState(false);
  const handleExternalContacts = () => {
    dispatch(getApp(appId, true)).then(() => {
      setOpenExternalContacts(!openExternalContacts);
    });
  };

  const { name } = useSelector((store) => store.apps.cachedApps.data[appId]);

  const sourceText = () => ({
    heading: `External Contacts`,
    editHeading: `Edit Contacts at ${name}`,
    tooltip: `Edit ${name} Contacts`,
  });

  const menuItems = [
    {
      label: sourceText().tooltip,
      action: handleExternalContacts,
    },
  ];

  return (
    <CollapsableItemPanel
      heading={sourceText().heading}
      menuItems={menuItems}
      key={sourceText().heading}
    >
      {!externalContacts && (
      <EmptyStateContainer>
        <Typography align="center" variant="body1" color="textSecondary">
          {`No Contacts at ${name} Yet`}
        </Typography>
        <Button size="small" onClick={handleExternalContacts} color="primary">Add an External Contact</Button>
      </EmptyStateContainer>
      )}

      {externalContacts && (
        <Container>
          {externalContacts.map((contact) => (
            <ContactContainer key={contact.info}>
              <SimpleAvatar />
              <ContactTypeText>
                <Typography variant="body2" style={{ fontWeight: 600 }}>
                  {contact.name || 'No Name'}
                  {' '}
                  <span style={{ fontWeight: 400 }}>{contact.info ? `(${contact.info})` : ''}</span>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {contact.type}
                </Typography>
              </ContactTypeText>
            </ContactContainer>
          ))}

        </Container>
      )}

      {/* /!* Dialog *!/ */}
      <EditExternalContacts
        handleExternalContacts={handleExternalContacts}
        openExternalContacts={openExternalContacts}
        appId={appId}
        externalContacts={externalContacts}
      />

    </CollapsableItemPanel>
  );
};
export default ExternalContacts;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    flex-basis: 45%;
    :not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

const ContactContainer = styled.div`
  margin-bottom: 1.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  > div {
    :not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

const ContactTypeText = styled.div``;

const EmptyStateContainer = styled.div`
  text-align: center;
  margin: 2rem auto;
`;
