import React, { useRef, useEffect, useState } from 'react';
import {
  Switch, FormControlLabel, Typography,
} from '@material-ui/core';
import styled from 'styled-components';

import algoliasearch from 'algoliasearch/lite';
import {
  connectSearchBox,
  connectInfiniteHits,
  connectRefinementList,
  connectStats,
  InstantSearch,
  Configure,
  connectStateResults,
} from 'react-instantsearch-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'qs';

import { applicationId } from '../../config/algolia';
import { ALGOLIA_COMPANY_INDEX_PREFIX, ALGOLIA_APPS_SUFFIX } from '../../config/constants';

import CategorySearchRefinementSelect from '../molecules/CategorySearchRefinementSelect';
import TeamSearchRefinement from '../molecules/OwnerSearchRefinement';
import ContainedLayout from '../templates/ContainedLayout';
import HitsSearch from '../molecules/HitsSearch';
import SearchBox from '../molecules/SearchBox';
import SearchStats from '../molecules/SearchStats';
import StateResults from '../molecules/StateResults';

const DEBOUNCE_TIME = 400;

const createURL = (state) => `?${qs.stringify(state)}`;

const searchStateToUrl = (location, searchState) => (searchState ? `${location.pathname}${createURL(searchState)}` : '');

const urlToSearchState = (location) => qs.parse(location.search.slice(1));

const CustomSearchBox = connectSearchBox(SearchBox);
const CustomHits = connectInfiniteHits(HitsSearch);
const CustomStats = connectStats(SearchStats);
const TeamRefinementList = connectRefinementList(TeamSearchRefinement);
const CategoryRefinementList = connectRefinementList(CategorySearchRefinementSelect);
const CustomStateResults = connectStateResults(StateResults);

const Search = () => {
  const cid = useSelector((store) => store.users.currentUser.data.companyId);
  const { algoliaId, displayName } = useSelector((store) => store.company.data);
  const constructedIndexId = ALGOLIA_COMPANY_INDEX_PREFIX + cid + ALGOLIA_APPS_SUFFIX;

  const searchClient = algoliasearch(applicationId, algoliaId);

  const location = useLocation();
  const history = useHistory();
  const [searchState, setSearchState] = useState(
    urlToSearchState(location),
  );
  const [detailedView, setDetailedView] = useState(false);
  const handleToggleDetailSwitch = (event) => setDetailedView(event.target.checked);

  const setStateId = useRef();

  useEffect(() => {
    const nextSearchState = urlToSearchState(location);

    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState);
    }
    // eslint-disable-next-line
  }, [location]);

  const onSearchStateChange = (nextSearchState) => {
    clearTimeout(setStateId.current);

    setStateId.current = setTimeout(() => {
      history.push(
        searchStateToUrl(location, nextSearchState),
        nextSearchState,
      );
    }, DEBOUNCE_TIME);

    setSearchState(nextSearchState);
  };

  return (
    <InstantSearch
      indexName={constructedIndexId}
      searchClient={searchClient}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      <Configure
        filters="NOT presenceStatus:DELETED"
        distinct
      />

      <ContainedLayout>
        <RefineRow>
          <SearchBar>
            <CustomSearchBox currentQuery={searchState.query} />
          </SearchBar>
          <Filters>
            <FilterContainer>
              <Typography gutterBottom variant="body1" style={{ fontWeight: 600 }}>
                Filter Categories
              </Typography>
              <CategoryRefinementList
                attribute="category"
                searchState={searchState.refinementList?.category || []}
              />
            </FilterContainer>

            <FilterContainer>
              <Typography gutterBottom variant="body1" style={{ fontWeight: 600 }}>
                Filter Teams
              </Typography>
              <TeamRefinementList
                attribute="owner.name"
                searchState={searchState.refinementList?.owner || []}
              />
            </FilterContainer>
          </Filters>
        </RefineRow>
        <HeadingSwitchContainer>
          <CustomStats surroundingText={`Apps at ${displayName}`} />
          <SwitchContainer>
            <FormControlLabel
              control={<Switch checked={detailedView} onChange={handleToggleDetailSwitch} name="checkedA" />}
              label="Show Details View"
            />
          </SwitchContainer>
        </HeadingSwitchContainer>
        <ResultsContainer>
          <CustomHits detailedView={detailedView} />
          <CustomStateResults />
        </ResultsContainer>
      </ContainedLayout>
    </InstantSearch>
  );
};

export default Search;

const Filters = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: flex-end;
  align-items: flex-end;
  > div {
    flex-basis: 50%;
    :not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

const SearchBar = styled.div`
  flex-basis: 45%;
`;

const FilterContainer = styled.div``;

const RefineRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 3rem;
`;

const HeadingSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SwitchContainer = styled.div``;

const ResultsContainer = styled.div`
  margin: 20px 0;
`;
