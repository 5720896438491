import groupBy from 'lodash.groupby';

// eslint-disable-next-line import/prefer-default-export
export const transposeHelpArray = (arr) => {
  const full = {};
  const groupedBySources = groupBy(arr, 'source');
  // eslint-disable-next-line array-callback-return
  Object.keys(groupedBySources).map((source) => {
    full[source] = groupBy(groupedBySources[source], 'group');
  });
  return full;
};
