import React from 'react';
import {
  Button,
} from '@material-ui/core';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import { connectAutoComplete, InstantSearch, Configure } from 'react-instantsearch-dom';

import { useSelector } from 'react-redux';
import { BreakPoints } from '../../config/theme';
import LogoSvg from '../../images/logo.svg';
import { applicationId } from '../../config/algolia';

import NavBarAutoComplete from '../molecules/NavBarAutoComplete';
import NavBarAccountMenu from '../molecules/NavBarAccountMenu';
import { ALGOLIA_COMPANY_INDEX_PREFIX, ALGOLIA_APPS_SUFFIX } from '../../config/constants';
import TrialChip from '../FreeTrial';

const TopNavBar = () => {
  const { algoliaId, currentPlan } = useSelector((store) => store.company.data);
  const cid = useSelector((store) => store.users.currentUser.data.companyId);
  const constructedIndexId = ALGOLIA_COMPANY_INDEX_PREFIX + cid + ALGOLIA_APPS_SUFFIX;
  const searchClient = algoliasearch(
    applicationId,
    algoliaId,
  );

  const CustomAutocomplete = connectAutoComplete(NavBarAutoComplete);

  return (
    <>
      <Container sticky open>
        <ContentContainer>
          <LogoContainer id="jr-global-logo">
            <Link to="/">
              <img src={LogoSvg} style={{ height: '40px' }} alt="logo" />
            </Link>
          </LogoContainer>
          <SearchBarContainer id="jr-global-search">
            <InstantSearch indexName={constructedIndexId} searchClient={searchClient}>
              <Configure
                filters="NOT presenceStatus:DELETED"
                distinct
              />
              <CustomAutocomplete />
            </InstantSearch>
          </SearchBarContainer>
          <ButtonsContainer>
            <div>
              <Button component={Link} to="/add">ADD</Button>
            </div>
            <div>
              <Button component={Link} to="/insights">INSIGHTS</Button>
            </div>
            {currentPlan.name === 'FREE_TRIAL' && (
              <div>
                <TrialChip plan={currentPlan} />
              </div>
            )}
            <NavBarAccountMenu />
          </ButtonsContainer>
        </ContentContainer>
      </Container>
    </>
  );
};

export default TopNavBar;

const Container = styled.div`
  height: 75px;
  ${({ sticky }) => sticky && `
    top: 0;
    z-index: 10;
    position: sticky;
  `}
  backdrop-filter: blur(10px);
  background-color: transparent;
  border-bottom: 1px solid #eee;
`;

const LogoContainer = styled.div`
  cursor: pointer;
`;

const SearchBarContainer = styled.div`
  flex-grow: 1;
  text-align: center;
  max-width: 512px;
  width: 512px;
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  height: 100%;
  display: flex;
  @media ${BreakPoints.mobileS} {
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
  }
  @media ${BreakPoints.desktopS} {
    align-items: center;
    justify-content: space-between;
    max-width: 1400px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-right: 1rem;
  }
  @media ${BreakPoints.mobileS} {
    display: none;
  }
  @media ${BreakPoints.desktopS} {
    display: flex;
  }
`;
