import React, { useState } from 'react';
import {
  Typography, TextField, IconButton, Button,
} from '@material-ui/core';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import { useSelector, useDispatch } from 'react-redux';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/Edit';
import ContainedLayout from '../../../templates/ContainedLayout';
import {
  removeStructureChild,
  addStructureChild,
  getCompany,
  editStructureChild,
  removeStructureParent,
  addStructureParent,
  editStructureParent,
} from '../../../../actions/company';
import GenericConfirmationModal from '../../../organisms/GenericConfirmationModal';

const Company = () => {
  const dispatch = useDispatch();
  const { structure, structureChildren } = useSelector((state) => state.company.data);

  // Edit Children
  const [addParent, setAddParent] = useState(false);
  const [addChild, setAddChild] = useState({});
  const [editChild, setEditChild] = useState({});
  const [editParent, setEditParent] = useState({});
  const [newParentFields, setNewParentFields] = useState();
  const [editParentField, setEditParentField] = useState({});

  const [modals, setModals] = useState({
    removeChild: {
      open: false,
      data: {},
      actions: {},
    },
    removeParent: {
      open: false,
      data: {},
      actions: {},
    },
  });

  const companyId = useSelector((store) => store.users.currentUser.data.companyId);

  const { handleSubmit, register } = useForm();
  const { handleSubmit: handleChildEdit, register: registerChildEdit } = useForm();

  const handleToggleAddItem = (parentId) => setAddChild({
    ...addChild,
    [parentId]: !addChild[parentId],
  });

  const handleToggleEditChild = async (c) => setEditChild({
    ...editChild,
    [c.id]: !editChild[c.id],
  });

  const handleToggleEditParent = async (c) => setEditParent({
    [c.id]: !editParent[c.id],
  });

  const clearModals = () => {
    setModals({
      removeChild: { ...modals.removeChild, open: false },
      removeParent: { ...modals.removeChild, open: false },
    });
  };

  const handleAddChild = async ({ name, parentId }) => {
    await addStructureChild(companyId, { name, parentId });
    handleToggleAddItem(parentId);
    dispatch(getCompany());
  };

  const handleAddParent = async (e) => {
    e.preventDefault();
    const name = newParentFields.replace(' ', '');
    await addStructureParent(companyId, { name });
    setAddParent(false);
    dispatch(getCompany());
  };

  const handleEditChild = async ({ name, id }) => {
    dispatch(editStructureChild(id, { name }));
    handleToggleEditChild({ id });
  };

  const handleRemoveChild = async (c) => {
    setModals({
      ...modals,
      removeChild: {
        open: true,
        data: {
          title: `Remove "${c.name}"?`,
          confirmCta: `Remove "${c.name}"`,
          body: `If you remove the team "${c.name}" it will no longer appear in search suggestions. Apps that already part of "${c.name}" will still belong to the team until they are updated.`,
        },
        actions: {
          confirm: () => {
            dispatch(removeStructureChild(c.id));
            clearModals();
          },
          cancel: clearModals,
        },
      },
    });
  };

  const handleRemoveParent = async (p) => {
    setModals({
      ...modals,
      removeParent: {
        open: true,
        data: {
          title: `Remove "${p.name}" and all of its members?`,
          confirmCta: `Remove "${p.name}"`,
          body: `If you remove the structure "${p.name}" it will no longer appear in search suggestions. Apps that already part of "${p.name}" will still belong to the structure until they are updated.`,
        },
        actions: {
          confirm: () => {
            dispatch(removeStructureParent(p.id));
            clearModals();
          },
          cancel: clearModals,
        },
      },
    });
  };

  const handleEditParent = async () => {
    const others = structure.filter(({ id }) => id !== editParentField.id);
    const original = structure.find(({ id }) => id === editParentField.id);
    original.name = editParentField.name;

    const newStructure = [
      ...others,
      original,
    ];

    dispatch(editStructureParent(newStructure));
    setEditParent({});
    setEditParentField({});
  };

  return (
    <ContainedLayout>
      <MultiFormsContainer>
        <FormContainer>
          <FormContent>
            <StructureActionRow>
              {!addParent && (
              <Button
                onClick={() => setAddParent(true)}
                title="Add a New Structure"
                startIcon={<AddCircleOutlineIcon />}
              >
                Add a New Structure
              </Button>
              )}
            </StructureActionRow>

            <StructureContainer>
              {structure
                .filter(({ status }) => status !== 'DELETED')
                .sort((a, b) => a.position - b.position)
                .map((item) => {
                  const children = structureChildren.filter(({ parentId, status }) => (
                    parentId === item.id && status !== 'DELETED'
                  ));
                  return (
                    <StructureLevel>
                      <StructureLevelHeader>
                        <div>
                          {!editParent[item.id] && (
                          <>
                            <Typography variant="h6" style={{ fontWeight: 600 }}>
                              {item.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {`${children.length} ${children.length === 1 ? 'Team' : 'Teams'}`}
                            </Typography>
                          </>
                          )}
                          {editParent[item.id] && (
                          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '1rem 0' }}>
                            <div style={{ marginRight: '.25rem' }}>
                              <TextField
                                fullWidth
                                name="name"
                                variant="outlined"
                                size="small"
                                label="Structure Name"
                                defaultValue={item.name}
                                onChange={(e) => setEditParentField(
                                  { name: e.target.value, id: item.id },
                                )}
                                inputProps={{
                                  style: { backgroundColor: 'white' },
                                }}
                                helperText="Eg. 'Department' or 'Function'"
                              />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                              <Button onClick={() => handleEditParent()} type="submit" disabled={!editParentField.name}>
                                Save
                              </Button>
                              <Button onClick={() => handleToggleEditParent(item)} type="submit">
                                Cancel
                              </Button>
                            </div>
                          </div>
                          )}
                        </div>
                        {!editParent[item.id] && (
                        <div>
                          <IconButton
                            onClick={() => handleToggleEditParent(item)}
                            title={`Rename ${item.name}`}
                            style={{ color: 'black' }}
                          >
                            <EditIcon />
                          </IconButton>

                          <IconButton
                            onClick={() => handleToggleAddItem(item.id)}
                            title={`Add a New Item to ${item.name}`}
                            style={{ color: 'black' }}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleRemoveParent(item)}
                            title={`Delete the ${item.name} Structure`}
                            style={{ color: 'black' }}
                          >
                            <DeleteOutlinedIcon />
                          </IconButton>
                        </div>
                        )}

                      </StructureLevelHeader>
                      {children.map((c) => (
                        <StructureItemContainer>
                          {!editChild[c.id] && (
                          <>
                            <StructureItem>
                              {c.name}
                            </StructureItem>
                            <StructureItemActions>
                              <ItemAction onClick={() => handleToggleEditChild(c)}>
                                Edit
                              </ItemAction>
                              <ItemAction onClick={() => handleRemoveChild(c)}>
                                Remove
                              </ItemAction>
                            </StructureItemActions>
                          </>
                          )}
                          {editChild[c.id] && (
                          <form onSubmit={handleChildEdit(handleEditChild)}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <div>
                                <TextField
                                  fullWidth
                                  inputRef={registerChildEdit({ required: true })}
                                  name="name"
                                  variant="outlined"
                                  size="small"
                                  defaultValue={c.name}
                                  label={`${item.name} Name`}
                                  style={{
                                    backgroundColor: 'white',
                                  }}
                                />
                              </div>
                              <div style={{ display: 'flex' }}>
                                <Button onClick={() => handleToggleEditChild(c)}>
                                  Cancel
                                </Button>
                                <Button onClick={handleChildEdit(handleEditChild)}>
                                  Save
                                </Button>
                              </div>
                            </div>
                            <input type="hidden" value={c.id} name="id" ref={registerChildEdit()} />
                          </form>
                          )}
                        </StructureItemContainer>
                      ))}
                      {addChild[item.id] && (
                      <form onSubmit={handleSubmit(handleAddChild)}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '1rem 0' }}>
                          <div>
                            <TextField
                              fullWidth
                              inputRef={register({ required: true })}
                              name="name"
                              variant="outlined"
                              size="small"
                              label={`${item.name} Name`}
                              style={{
                                backgroundColor: 'white',
                              }}
                            />
                          </div>
                          <div>
                            <Button onClick={handleSubmit(handleAddChild)}>
                              Add
                            </Button>
                          </div>
                        </div>
                        <input type="hidden" value={item.id} name="parentId" ref={register()} />
                      </form>
                      )}
                    </StructureLevel>
                  );
                })}

              {/* Add New Parent */}
              {addParent && (
              <StructureLevel>
                <StructureLevelHeader>
                  <form onSubmit={(e) => handleAddParent(e)}>
                    <Typography variant="h6" style={{ fontWeight: 600 }}>
                      New Structure
                    </Typography>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '1rem 0' }}>
                      <div style={{ marginRight: '.25rem' }}>
                        <TextField
                          fullWidth
                          name="name"
                          variant="outlined"
                          size="small"
                          label="Structure Name"
                          onChange={(e) => setNewParentFields(e.target.value)}
                          inputProps={{
                            style: { backgroundColor: 'white' },
                          }}
                          helperText="Eg. 'Department' or 'Function'"
                        />
                      </div>
                      <div>
                        <Button onClick={(e) => handleAddParent(e)} type="submit">
                          Add
                        </Button>
                      </div>
                    </div>
                    <div>
                      <Button onClick={() => setAddParent(false)}>
                        Cancel
                      </Button>
                    </div>
                  </form>
                </StructureLevelHeader>
              </StructureLevel>
              )}
            </StructureContainer>
          </FormContent>
        </FormContainer>

        <GenericConfirmationModal
          modalIsOpen={modals.removeChild.open}
          title={modals.removeChild.data?.title}
          body={modals.removeChild.data?.body}
          confirmCta={modals.removeChild.data?.confirmCta}
          cancelCallBack={modals.removeChild.actions?.cancel}
          confirmCallBack={modals.removeChild.actions?.confirm}
        />

        <GenericConfirmationModal
          modalIsOpen={modals.removeParent.open}
          title={modals.removeParent.data?.title}
          body={modals.removeParent.data?.body}
          confirmCta={modals.removeParent.data?.confirmCta}
          cancelCallBack={modals.removeParent.actions?.cancel}
          confirmCallBack={modals.removeParent.actions?.confirm}
        />
      </MultiFormsContainer>
    </ContainedLayout>
  );
};

export default Company;

const StructureContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 1rem 0;
`;

const StructureActionRow = styled.div`
  
`;

const StructureLevel = styled.div`
  background-color: #fafafa;
  border-radius: 5px;
  padding: 1rem 2rem;
  min-width: 400px;
  flex-basis: 30%;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

const StructureLevelHeader = styled.div`
  margin: 1rem 0 3rem 0;
  display: flex;
  justify-content: space-between;
`;

const StructureItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  :not(:last-child) { 
    border-bottom: 1px solid #eee;
  }
`;

const StructureItem = styled.div``;

const StructureItemActions = styled.div`
  display: flex;
`;

const ItemAction = styled.div`
  margin: 0 .5rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const MultiFormsContainer = styled.div`
  padding: 1rem;
`;

const FormContainer = styled.div`
  margin-bottom: 2rem;
`;

const FormContent = styled.div``;
