import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import CollapsableItemPanel from '../../organisms/CollapsableItemPanel';
import { getSameCategoryApps } from '../../../actions/apps';
import AppIcon from '../../atoms/AppIcon';

const RelatedApps = ({ category, appId }) => {
  const history = useHistory();

  const { displayName } = useSelector((store) => store.company.data);

  const [appList, setAppList] = useState([]);

  useEffect(() => {
    getSameCategoryApps(category, appId)
      .then((res) => {
        setAppList(res);
      })
      .catch(() => console.log('Error getting similar apps'));
  }, [category, appId]);

  if (!appList.length) return null;

  return (
    <CollapsableItemPanel heading={`Similar Apps at ${displayName}`}>
      <ListContainer>
        {appList.map((app) => (
          <React.Fragment key={app.id}>
            <AppIcon
              appId={app.id}
              onClickEvent={() => history.push(`/app/${app.id}/${app.name}`)}
              flat
            />
          </React.Fragment>
        ))}
      </ListContainer>
    </CollapsableItemPanel>
  );
};

export default RelatedApps;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;
