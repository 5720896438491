import React, { useState } from 'react';
import {
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  LinearProgress,
  Typography,
  Box,
  DialogContentText,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { enableUserAdmin, disableUserAdmin } from '../../actions/admin';
import { showSnackbar } from '../../actions/snackbar';

const DisableAccountDialog = ({
  disableDialog, toggleDisableDialog, currentlyDisabled, name, email, uid,
}) => {
  const [manualError, setManualError] = useState(false);
  const [saving, setSaving] = useState(false);
  const { handleSubmit } = useForm();
  const dispatch = useDispatch();

  const getDialogText = () => {
    if (currentlyDisabled) {
      return {
        heading: 'Enable Account?',
        text: `After enabling this account, ${name} (${email}) will immediately have access this Brodi space.`,
        button: 'Enable Account',
        success: 'Account Enabled',
      };
    }
    return {
      heading: 'Disable Account?',
      text: `After disabling this account, ${name} (${email}) will immediately lose all access to this Brodi space.`,
      button: 'Disable Account',
      success: 'Account Disabled',
    };
  };

  const handleDisableChange = () => {
    setSaving(true);
    setManualError(false);
    if (currentlyDisabled) {
      dispatch(enableUserAdmin(uid))
        .then(() => {
          setSaving(false);
          showSnackbar(getDialogText().success);
          toggleDisableDialog();
        })
        .catch(() => {
          setSaving(false);
          setManualError(true);
        });
    } else {
      dispatch(disableUserAdmin(uid))
        .then(() => {
          setSaving(false);
          showSnackbar(getDialogText().success);
          toggleDisableDialog();
        })
        .catch(() => {
          setSaving(false);
          setManualError(true);
        });
    }
  };

  const handleClose = () => {
    setManualError(false);
    toggleDisableDialog();
  };

  return (
    <Dialog
      open={disableDialog}
      onClose={toggleDisableDialog}
      aria-labelledby="form-dialog-title"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(handleDisableChange)}>
        {
          (saving) && (
            <LinearProgress />
          )
        }
        <DialogTitle id="form-dialog-title">{getDialogText().heading}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {getDialogText().text}
            {manualError && (
              <Box my={2}>
                <Typography color="error" variant="body2">Sorry, there was a problem doing that.</Typography>
              </Box>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={saving} type="submit" color="primary">
            {getDialogText().button}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DisableAccountDialog;
