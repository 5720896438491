import React, { useState } from 'react';
import {
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  LinearProgress,
  Typography,
  Box,
} from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { showSnackbar } from '../../actions/snackbar';
import { toggleArchiveApp } from '../../actions/apps';

const ConfirmAppArchive = ({
  appId,
  confirmAppArchiveDialogOpen,
  setConfirmAppArchiveDialogOpen,
  name,
}) => {
  const [manualError, setManualError] = useState(false);
  const [saving, setSaving] = useState(false);
  const {
    handleSubmit,
  } = useForm();
  const dispatch = useDispatch();

  const handleArchive = () => {
    setSaving(true);
    setManualError(false);
    dispatch(toggleArchiveApp(appId, false))
      .then(() => {
        setSaving(false);
        dispatch(showSnackbar(`Archived ${name}`));
        setConfirmAppArchiveDialogOpen();
      })
      .catch(() => {
        setSaving(false);
        setManualError(true);
      });
  };

  const handleClose = () => {
    setConfirmAppArchiveDialogOpen();
    setManualError(false);
  };

  return (
    <Dialog
      open={confirmAppArchiveDialogOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(handleArchive)}>
        {
          (saving) && (
            <LinearProgress />
          )
        }
        <DialogTitle id="form-dialog-title">
          Archive
          {' '}
          {name}
          ?
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            When you archive
            {' '}
            {name}
            , its data will still be available, but it will be deprioritizied in search results.
          </Typography>
          {manualError && (
            <Box my={2}>
              <Typography color="error" variant="body2">
                Sorry, there was a problem archiving
                {' '}
                {name}
                .
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={saving} onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={saving} type="submit" color="primary">
            Archive
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConfirmAppArchive;
