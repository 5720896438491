// BRODI
export const DOMAIN = 'https://app.brodi.io';
export const APP_ENGINE_DOMAIN = 'https://api.brodi.io';

// HTTP REQUESTS
export const GET = 'GET';
export const PUT = 'PUT';
export const PATCH = 'PATCH';
export const POST = 'POST';
export const DELETE = 'DELETE';

// FIREBASE
export const FB_COMPANIES_COLLECTION = 'companies';
export const FB_APP_ICONS_STORAGE = 'app-icons';
export const FB_USER_AVATARS_STORAGE = 'avatars';

// AVATAR LOOKUP TYPES
export const PERSON = 'PERSON';

// APP AVAILABILITY STATUSES
export const APP_AVAILABLE_STATUS = 'AVAILABLE';
export const APP_PARTIALLY_UNAVAILABLE_STATUS = 'PARTIALLY_UNAVAILABLE';
export const APP_UNAVAILABLE_STATUS = 'UNAVAILABLE';

export const APP_AVAILABILITY_STATUS_MAPS = {
  [APP_AVAILABLE_STATUS]: {
    title: 'Available',
    summary: 'This app is available',
  },
  [APP_PARTIALLY_UNAVAILABLE_STATUS]: {
    title: 'Partially Unavailable',
    summary: 'This app is available',
  },
  [APP_UNAVAILABLE_STATUS]: {
    title: 'Completely Unavailable',
    summary: 'This app is available',
  },
};

// APP PRESENCE
export const APP_ACTIVE_STATUS = 'ACTIVE';
export const APP_ARCHIVED_STATUS = 'ARCHIVED';
export const APP_DELETED_STATUS = 'DELETED';

// CONTACT SOURCES
export const INTERNAL_SUPPORT = 'INTERNAL';

// ALGOLIA
export const ALGOLIA_COMPANY_INDEX_PREFIX = 'prod_C_';
export const ALGOLIA_APPS_SUFFIX = '_apps';
export const ALGOLIA_USERS_SUFFIX = '_users';

// ICON LIMITS
export const ICON_SIZE_LIMIT = 3000000;
export const ICON_TYPE_REGEX = /[/.](gif|jpg|jpeg|tiff|png)$/i;

// BILLING CYCLES
export const BILLING_CYCLE_TYPES = [{ display: 'Monthly', key: 'MONTHLY' }, { display: 'Annually', key: 'ANNUALLY' }];

// AUTH TYPES
export const SAML = 'SAML';
export const EMAIL = 'EMAIL';

// PASSWORD REGEX
// 1 Upper, 1 Lower, 1 Number, 1 Symbol, Min 8
export const PASSWORD_REGEX = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!@#$%^&*])(?=.{8,})';
