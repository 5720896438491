import React, { useState } from 'react';
import {
  Typography, Menu, MenuItem, IconButton,
} from '@material-ui/core';
import styled from 'styled-components';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const CollapsibleItemPanel = ({
  heading,
  menuItems,
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState();
  const handleExtendedMenuClose = () => setAnchorEl();
  const handleClick = (event) => setAnchorEl(event.currentTarget);

  return (
    <FloatingAppSectionContainer>
      <HeadingRow>

        <TitleContainer>
          <TitleText>
            <Typography variant="h5" style={{ fontWeight: 700 }}>{heading}</Typography>
          </TitleText>
          <TitleUnderscore />
        </TitleContainer>

        {menuItems?.length && (
          <MoreButtons>
            <IconButton aria-haspopup="true" onClick={handleClick}>
              <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleExtendedMenuClose}
            >
              {(menuItems.map((item) => (
                <MenuItem
                  onClick={() => {
                    if (item.action) item.action();
                    handleExtendedMenuClose();
                  }}
                  key={item.label}
                >
                  {item.label || 'Edit Section'}
                </MenuItem>
              )))}
            </Menu>
          </MoreButtons>
        )}
      </HeadingRow>

      <ContentContainer>
        {children}
      </ContentContainer>

    </FloatingAppSectionContainer>
  );
};

export default CollapsibleItemPanel;

const TitleContainer = styled.div`
  padding-top: 2.5rem;
  padding-left: 2.5rem;
  padding-bottom: 0;
  margin:0;
`;

const HeadingRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const ContentContainer = styled.div`
  padding: 2.5rem 2.5rem 2.5rem 2.5rem;
`;

const TitleText = styled.div`
  padding-bottom: 10px;
`;

const TitleUnderscore = styled.div`
  background-color: #4E67EB;
  border-radius: 50px;
  width: 20px;
  height: 4px;
`;

const MoreButtons = styled.div`
  padding-top: 1.5rem;
  padding-right: 1rem;
`;

const FloatingAppSectionContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.13);
  border-radius: 4px;
  width: 100%;
  opacity: 1;
  z-index: 50;
`;
