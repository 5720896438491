import React from 'react';

import {
  Grid
} from '@material-ui/core';
import LogoSvg from '../../../images/logo.svg';

const Processing = () => (
  <Grid
    container
    direction="column"
    alignItems="center"
    justify="center"
    spacing={2}
  >
    {/* Logo */}
    <Grid item>
      <img src={LogoSvg} alt="logo" />
    </Grid>

  </Grid>
);

export default Processing;
