import React from 'react';

import {
  TextField,
} from '@material-ui/core';

const SearchBox = ({
  refine,
  label,
  placeholder,
  currentQuery,
}) => (
  <form action="" role="search" onSubmit={(e) => e.preventDefault()}>
    <TextField
      variant="filled"
      label={label || 'Search for Apps by anything...'}
      size="medium"
      type="search"
      placeholder={placeholder || null}
      fullWidth
      autoFocus
      onChange={(event) => refine(event.currentTarget.value)}
      defaultValue={currentQuery}
    />
  </form>
);

export default SearchBox;
