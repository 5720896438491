import React from 'react';

import {
  Snackbar, IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';

import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';
import { clearSnackbar } from '../../actions/snackbar';
import TopNavBar from '../organisms/TopNavBar';
import FloatingFeedbackButton from '../FloatingFeedback/index';
import InitialOnboarding from '../Onboarding';

const CoreLayout = ({ children }) => {
  const dispatch = useDispatch();
  const snackbar = useSelector((store) => store.snackbar.data);

  const handleClose = () => dispatch(clearSnackbar());

  return (
    <Container>
      <InitialOnboarding />
      <TopNavBar />
      <FloatingFeedbackButton />
      <ContentContainer>
        {snackbar.error ? (
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={snackbar.display}
            autoHideDuration={6000}
            onClose={handleClose}
          >
            <MuiAlert onClose={handleClose} severity="error" variant="filled">{snackbar.message}</MuiAlert>
          </Snackbar>
        ) : (
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={snackbar.display}
            autoHideDuration={6000}
            onClose={handleClose}
            message={snackbar.message}
            action={(
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          />
        )}
        {children}
      </ContentContainer>
    </Container>
  );
};

export default CoreLayout;

const ContentContainer = styled.div``;

const Container = styled.div``;
