import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { labelInitials, truncate } from '../../helpers/strings';
import { getApp } from '../../actions/apps';

const AppIcon = ({
  appId,
  payload = {}, // If passed, no API call
  flat = false,
  onClickEvent = () => {},
}) => {
  const dispatch = useDispatch();
  const { icon, name } = useSelector((store) => store.apps.cachedApps.data[appId]) || payload;

  useEffect(() => {
    if (Object.keys(payload).length) return;
    dispatch(getApp(appId));
  }, [appId, payload, dispatch]);

  return (
    <IconContainer onClick={() => onClickEvent()} flat={flat}>
      {(icon ? (
        <Icon alt={`Logo for ${name}`} src={icon} />
      ) : (
        <LetterIcon>{labelInitials(name)}</LetterIcon>
      ))}
      <Typography component="div" title={name} variant="body2" style={{ fontWeight: 600 }}>
        {truncate(name, 13)}
      </Typography>
    </IconContainer>
  );
};
export default AppIcon;

const IconContainer = styled.div`
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  width: 120px;
  text-align: center;
  align-items: center;
  padding: 1rem;
  box-shadow: 0 10px 20px 0 rgba(0,0,0,0.17);
  background-color: #fff;
  ${(({ flat }) => flat && `
    box-shadow: none;
  `)}
  &:hover {
    cursor: pointer;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.25);
    ${(({ flat }) => flat && `
      box-shadow: none;
      background-color: #fafafa;
    `)}
  };
`;

const LetterIcon = styled.div`
  background-color: #EEE;
  align-items: center;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  height: 50px;
  width: 50px;
  color: #000;
  background-color: #EEE;
  margin-bottom: 5px;
`;

const Icon = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 5px;
  object-fit: cover;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 5px;
`;
