import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  DialogActions,
  Button,
  DialogContentText,
  LinearProgress,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { editApp } from '../../../actions/apps';
import { urlRegex, addhttp } from '../../../helpers/url';
import { resolveCategoryId } from '../../../helpers/resolvers';
import { showSnackbar } from '../../../actions/snackbar';

const EditBasicInfo = ({
  appId,
  customFields,
  openBasicInfo,
  handleBasicInfoDialog,
  appCategories,
  category,
  url,
  description,
  name,
  firstParty,
}) => {
  const dispatch = useDispatch();
  const [manualError, setManualError] = useState(false);
  const [saving, setSaving] = useState(false);

  const { displayName } = useSelector((state) => state.company.data);

  const {
    control,
    errors,
    formState,
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm({
    defaultValues: { customFields },
  });

  const { fields, append, remove } = useFieldArray({
    control, name: 'customFields',
  });

  const defaultLabel = { key: '', value: '' };

  const nameWatcher = watch('name');

  const handleBasicFormCancel = () => {
    setManualError(false);
    handleBasicInfoDialog();
    reset();
  };

  const updateBasics = (formData) => {
    const tmp = formData;

    if (tmp.url) tmp.url = addhttp(tmp.url);
    if (!tmp.customFields) tmp.customFields = [];
    setSaving(true);
    setManualError(false);
    dispatch(editApp(tmp, appId))
      .then(() => {
        dispatch(showSnackbar('Changes Saved'));
        setSaving(false);
        handleBasicInfoDialog();
      })
      .catch(() => {
        setSaving(false);
        setManualError(true);
      });
  };

  return (
    <Dialog
      open={openBasicInfo}
      onClose={handleBasicInfoDialog}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      scroll="paper"
    >
      {
          (saving) && (
            <LinearProgress />
          )
        }
      <DialogContent dividers>
        <form onSubmit={handleSubmit(updateBasics)}>
          <ModalContainer>
            <ModalSectionContainer>
              <ModalSectionTitle variant="h6">
                Edit About
              </ModalSectionTitle>
              <ModalSectionContent>
                <ModalSectionContentRow>
                  <TextField
                    variant="outlined"
                    label="Name"
                    size="medium"
                    name="name"
                    error={Boolean(errors.name)}
                    defaultValue={name}
                    inputRef={register({ required: 'Please provide a name for this App' })}
                    helperText={
                  errors.name && errors.name.message
                }
                    fullWidth
                  />
                </ModalSectionContentRow>

                <ModalSectionContentRow>
                  <FormControl variant="outlined" fullWidth error={Boolean(errors.category)}>
                    <InputLabel id="category-outlined-label">Category</InputLabel>
                    <Controller
                      as={(
                        <Select
                          labelId="category-outlined-label"
                          id="category-outlined"
                          label="Category"
                        >
                          <MenuItem disabled value="">Select a Category</MenuItem>
                          {Object.keys(appCategories).sort().map((item) => (
                            <MenuItem
                              key={item}
                              value={item}
                            >
                              {resolveCategoryId(item, appCategories)}
                            </MenuItem>
                          ))}
                        </Select>
                  )}
                      name="category"
                      rules={{ required: 'Please select the category that best describes this App' }}
                      control={control}
                      defaultValue={category}
                    />
                    <FormHelperText>
                      {errors.category && errors.category.message}
                    </FormHelperText>
                  </FormControl>
                </ModalSectionContentRow>
                <ModalSectionContentRow>

                  <TextField
                    variant="outlined"
                    label="App URL"
                    size="medium"
                    name="url"
                    error={Boolean(errors.url)}
                    defaultValue={url}
                    inputRef={register({ pattern: urlRegex })}
                    helperText={
                  errors.url ? 'Please provide a valid URL for this App' : `The URL people will use to access "${nameWatcher || name}"`
                }
                    fullWidth
                  />
                </ModalSectionContentRow>
                <ModalSectionContentRow>
                  <TextField
                    variant="outlined"
                    label="Description"
                    size="medium"
                    multiline
                    rows="8"
                    name="description"
                    error={Boolean(errors.description)}
                    defaultValue={description}
                    inputRef={register({ required: 'Please provide a valid description for this App' })}
                    helperText={
                errors.description ? errors.description.message : 'Make it clear what this App does (this information will be searchable)'
              }
                    fullWidth
                  />
                </ModalSectionContentRow>

                <ModalSectionContentRow>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      control={(
                        <Controller
                          as={<Checkbox />}
                          control={control}
                          name="firstParty"
                          defaultValue={firstParty || false}
                        />
                      )}
                      label={`This app was created by ${displayName}`}
                    />
                  </FormControl>

                </ModalSectionContentRow>
              </ModalSectionContent>
            </ModalSectionContainer>

            <ModalSectionContainer>
              <Typography variant="h6">
                Custom Fields
              </Typography>
              <Typography variant="body2">
                Create searchable fields unique to
                {' '}
                {displayName}
                .
              </Typography>

              <ModalSectionContent>
                <ModalSectionContentRow>
                  {fields.map((label, index) => (
                    <CustomFieldsRow>
                      <CustomFieldName>
                        <TextField
                          error={!!errors.customFields?.[index]?.key}
                          variant="outlined"
                          size="small"
                          inputRef={register({ required: true })}
                          helperText={
                              errors.customFields?.[index]
                              && 'A field name is required'
                            }
                          name={`customFields[${index}].key`}
                          autoComplete="off"
                          defaultValue={label.key}
                          label="Field Name"
                          fullWidth
                        />
                      </CustomFieldName>
                      <CustomFieldValue>
                        <TextField
                          error={!!errors.customFields?.[index]?.value}
                          variant="outlined"
                          size="small"
                          inputRef={register()}
                          helperText={
                              errors.customFields?.[index]
                              && 'A value is required'
                            }
                          name={`customFields[${index}].value`}
                          autoComplete="off"
                          defaultValue={label.value}
                          label="Field Value"
                          fullWidth
                        />
                      </CustomFieldValue>
                      <CustomFieldRemove>
                        <IconButton onClick={() => remove(index)}>
                          <ClearIcon />
                        </IconButton>
                      </CustomFieldRemove>
                    </CustomFieldsRow>
                  ))}

                  {(!fields.length && (
                    <Typography variant="body2" color="textSecondary">
                      {name || nameWatcher}
                      {' '}
                      has no Custom Fields yet
                    </Typography>
                  ))}
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => append(defaultLabel)}
                    style={{ marginTop: '20px' }}
                  >
                    Add New Custom Field
                  </Button>
                </ModalSectionContentRow>
              </ModalSectionContent>

            </ModalSectionContainer>

            {manualError ? (
            // TODO: STYLING: This needs better styling
              <DialogContentText color="error">
                There was a problem changing the app&apos;s info.
              </DialogContentText>
            ) : null}
          </ModalContainer>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleBasicFormCancel} disabled={saving}>
          <span>Cancel</span>
        </Button>
        <Button color="primary" type="submit" onClick={handleSubmit(updateBasics)} disabled={!formState.dirty || saving}>
          <span>Save Changes</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditBasicInfo;

const ModalContainer = styled.div``;
const ModalSectionContainer = styled.div`
  margin-bottom: 40px;
`;
const ModalSectionTitle = styled(Typography)`
  margin-bottom: 10px;
`;
const ModalSectionContent = styled.div``;

const ModalSectionContentRow = styled.div`
  margin: 15px 0;
`;

const CustomFieldsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const CustomFieldName = styled.div`
  margin-right: .5rem;
`;
const CustomFieldValue = styled.div`
`;

const CustomFieldRemove = styled.div`
`;
