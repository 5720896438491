import React, { useState } from 'react';
import {
  Button,
  makeStyles,
  DialogContent,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  Checkbox,
  LinearProgress,
  Typography,
} from '@material-ui/core';

import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toggleFollowApp } from '../../actions/users';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
}));

export default ({
  followAppDialogOpen, setFollowAppDialogOpen, appId, name,
}) => {
  const {
    handleSubmit, control, formState,
  } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [manualError, setManualError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleClose = () => {
    setManualError(false);
    setFollowAppDialogOpen(!followAppDialogOpen);
  };

  const handleFollowSubmit = async (data) => {
    if (!data.status && !data.edit) {
      setManualError(true);
      setErrorMessage('Please select a checkbox');
      return;
    }
    try {
      setManualError(false);
      setErrorMessage(null);
      await dispatch(toggleFollowApp(false, appId, data));
      handleClose();
    } catch (err) {
      setManualError(true);
    }
  };

  return (
    <Dialog
      open={followAppDialogOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      <form onSubmit={handleSubmit(handleFollowSubmit)}>
        {
          (formState.isSubmitting) && (
            <LinearProgress />
          )
        }

        <DialogTitle>
          <Typography variant="h6">
            {`Add ${name} to your Favorites`}
          </Typography>
          <Typography variant="body1">
            {`Select your notification preferences for ${name}`}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <Typography variant="body1" style={{ fontWeight: 600 }}>
            Notify me when:
          </Typography>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormControlLabel
              control={(
                <Controller
                  as={<Checkbox />}
                  control={control}
                  name="status"
                  defaultValue
                />
                  )}
              name="status"
              label="There is a problem with this app"
            />
            <FormControlLabel
              control={(
                <Controller
                  as={<Checkbox />}
                  control={control}
                  name="edit"
                  defaultValue
                />
                  )}
              name="edit"
              label="The content on this page changes"
            />
          </FormControl>

          <Typography variant="body2">
            You can change how you receive these notifications in Settings.
          </Typography>
          {manualError ? (
            // TODO: STYLING: This needs better styling
            <DialogContentText color="error">
              {errorMessage || 'There was a problem saving your preferences.'}
            </DialogContentText>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button disabled={formState.isSubmitting} onClick={handleClose} color="default">Cancel</Button>
          <Button disabled={formState.isSubmitting} type="submit" color="primary">Add to Favorites</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
