import {
  PATCH, PUT, DELETE, POST, GET,
} from '../config/constants';
import { brodiAuthedRequest } from '../helpers/backend';

export const GET_COMPANY_INFO_SUCCESS = 'GET_COMPANY_INFO_SUCCESS';

export const getCompany = () => async (dispatch, getState) => {
  const { companyId } = getState().users.currentUser.data;
  const payload = await brodiAuthedRequest(GET, `/company/${companyId}`);
  dispatch({ type: GET_COMPANY_INFO_SUCCESS, payload });
};

export const sendClientFeedback = async (payload) => {
  const { id } = await brodiAuthedRequest(
    POST,
    '/general/feedback',
    payload,
  );
  return id;
};

export const updateCompanyDetails = (preferences) => async (
  dispatch,
  getState,
) => {
  const { companyId } = getState().users.currentUser.data;
  await brodiAuthedRequest(PATCH, `/company/${companyId}`, { preferences });
  await dispatch(getCompany());
};

export const addStructureChild = async (companyId, { name, parentId }) => {
  const { id } = await brodiAuthedRequest(
    PUT,
    `/company/${companyId}/child-structure`,
    { name, parentId },
  );
  return id;
};

export const addStructureParent = async (companyId, { name }) => {
  const { id } = await brodiAuthedRequest(
    PUT,
    `/company/${companyId}/structure`,
    { name },
  );
  return id;
};

export const removeStructureChild = (id) => async (
  dispatch,
  getState,
) => {
  const { companyId } = getState().users.currentUser.data;
  await brodiAuthedRequest(DELETE, `/company/${companyId}/child-structure/${id}`);
  await dispatch(getCompany());
};

export const removeStructureParent = (id) => async (
  dispatch,
  getState,
) => {
  const { companyId } = getState().users.currentUser.data;
  await brodiAuthedRequest(DELETE, `/company/${companyId}/structure/${id}`);
  await dispatch(getCompany());
};

export const editStructureChild = (id, { name }) => async (
  dispatch,
  getState,
) => {
  const { companyId } = getState().users.currentUser.data;
  await brodiAuthedRequest(PATCH, `/company/${companyId}/child-structure/${id}`, { name });
  await dispatch(getCompany());
};

export const editStructureParent = (structure) => async (
  dispatch,
  getState,
) => {
  const { companyId } = getState().users.currentUser.data;
  await brodiAuthedRequest(PATCH, `/company/${companyId}/structure`, { structure });
  await dispatch(getCompany());
};
