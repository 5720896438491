import React from 'react';

import {
  Typography, Card, CardActionArea, Fade, Tooltip,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { truncate, labelInitials } from '../../helpers/strings';
import { resolveCategoryId } from '../../helpers/resolvers';

const AppCard = ({
  name, id, icon, category,
}) => {
  const appCategories = useSelector((store) => store.client.data.categories);

  if (id) {
    return (
      <>
        <Fade in={Boolean(id)} timeout={500}>
          <Card elevation={1} style={{ minWidth: '240px', maxWidth: '240px' }}>
            <CardActionArea component={Link} to={`/app/${id}/${name}`}>
              <CardContentWrapper>
                {(icon ? (
                  <Icon alt="logo" src={icon} />
                ) : (
                  <LetterIcon colorScheme={{ backgroundColor: '#EEE', color: '#000' }}>
                    {labelInitials(name)}
                  </LetterIcon>
                ))}

                {name.length > 13 ? (
                  <Tooltip title={name}>
                    <Typography align="center" variant="h5">
                      {(name) && truncate(name, 13)}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography align="center" variant="h5">
                    {(name) && truncate(name, 13)}
                  </Typography>
                )}

                <Fade in={!!appCategories}>

                  {resolveCategoryId(category, appCategories).length > 20 ? (
                    <Tooltip title={resolveCategoryId(category, appCategories)}>
                      <Typography align="center" variant="body2" color="textSecondary">
                        {(category) && truncate(resolveCategoryId(category, appCategories), 20)}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography align="center" variant="body2" color="textSecondary">
                      {(category) && truncate(resolveCategoryId(category, appCategories), 20)}
                    </Typography>
                  )}
                </Fade>

              </CardContentWrapper>
            </CardActionArea>
          </Card>
        </Fade>
      </>
    );
  }
  return null;
};

export default AppCard;

const CardContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.0rem;
`;

const Icon = styled.img`
  margin-bottom: 20px;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  object-fit: cover;
`;

const LetterIcon = styled.div`
  background-color: #EEE;
  align-items: center;
  border-radius: 5px;
  display: flex;
  font-size: 20px;
  font-weight: 800;
  height: 50px;
  justify-content: center;
  margin-bottom: 20px;
  width: 50px;
  ${(props) => `
  background-color: ${props.colorScheme.backgroundColor};
  color: ${props.colorScheme.fontColor};
  `});
`;
