import { GET } from '../config/constants';
import { brodiAuthedRequest } from '../helpers/backend';

export const getCountAppsByStructure = () => async (
  dispatch,
  getState,
) => {
  const { companyId } = getState().users.currentUser.data;
  return brodiAuthedRequest(GET, `/analytics/${companyId}/count/apps-by-structure`)
};

export const getAppsByCategory = () => async (
  dispatch,
  getState,
) => {
  const { companyId } = getState().users.currentUser.data;
  return brodiAuthedRequest(GET, `/analytics/${companyId}/count/apps-by-category`);
};


export const getCostAppsByStructure = (currencyCode = 'USD') => async (
  dispatch,
  getState,
) => {
  const { companyId } = getState().users.currentUser.data;
  return brodiAuthedRequest(GET, `/analytics/${companyId}/cost/apps-by-structure/${currencyCode}`);
};


export const getCountApps = () => async (
  dispatch,
  getState,
) => {
  const { companyId } = getState().users.currentUser.data;
  return brodiAuthedRequest(GET, `/analytics/${companyId}/count/apps`);
};

export const getCostApps = (currencyCode = 'USD') => async (
  dispatch,
  getState,
) => {
  const { companyId } = getState().users.currentUser.data;
  return brodiAuthedRequest(GET, `/analytics/${companyId}/cost/total/${currencyCode}`);
};

export const getExpiringContracts = (date = '2030-12-31T00:00:00Z') => async (
  dispatch,
  getState,
) => {
  const { companyId } = getState().users.currentUser.data;
  return brodiAuthedRequest(GET, `/analytics/${companyId}/insight/renewals/${date}`);
};
