import React from 'react';
import { useSelector } from 'react-redux';
import InitialSigninFromFreeTrial from './InitialSigninFromFreeTrial';
import InitialSigninFromAdminInvite from './InitialSigninFromAdminInvite';

// const FREE_TRIAL_SEQUENCE = 'fromFreeTrial';
// const ADMIN_INVITE_SEQUENCE = 'fromAdminInvite';

const InitialOnboarding = () => {
  const { onboarding = {} } = useSelector((store) => store.users.currentUser.data);

  return (
    <>
      <InitialSigninFromFreeTrial
        open={!!onboarding.fromFreeTrial}
        plan={{}}
      />

      <InitialSigninFromAdminInvite
        open={!!onboarding.fromAdminInvite}
      />
    </>
  );
};
export default InitialOnboarding;
